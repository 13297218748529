:root {
  --bs-blue: #a4ff66;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #f7b500;
  --bs-green: #28a745;
  --bs-teal: #20c997;
  --bs-cyan: #17a2b8;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #0f1a1c;
  --bs-primary: #a4ff66;
  --bs-secondary: #6c757d;
  --bs-success: #28a745;
  --bs-info: #17a2b8;
  --bs-warning: #f7b500;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #0f1a1c;
  --bs-dark-1: #303032;
  --bs-dark-2: #383838;
  --bs-blue-dark: #141722;
  /* --bs-font-sans-serif: "Lato", sans-serif; */
  /* --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; */
  --bs-gradient: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
}

*,
:after,
:before {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  /* font-family: "Lato", sans-serif; */
  /* font-family: var(--bs-font-sans-serif); */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #f8f9fa;
  background-color: #141414;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: #0f1a1c;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.5rem;
  }
}

.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 2rem;
  }
}

.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }
}

.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}

.h5,
h5 {
  font-size: 1.25rem;
}

.h6,
h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-bs-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

.small,
small {
  font-size: 0.875rem;
}

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #a4ff66;
}

a:hover {
  color: #a4ff66;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code,
kbd,
pre,
samp {
  /* font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; */
  /* font-family: var(--bs-font-monospace); */
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875rem;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875rem;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #0f1a1c;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #adb5bd;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  /* font-family: inherit; */
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.blockquote-footer:before {
  content: "\2014\00A0";
}

.img-fluid,
.img-thumbnail {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #141414;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875rem;
  color: #6c757d;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 0.75rem;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: 0.75rem;
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #f8f9fa;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #f8f9fa;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #f8f9fa;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #f8f9fa;
  vertical-align: top;
  border-color: #0f1a1c;
}

.table > :not(caption) > * > * {
  padding: 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d8f1ff;
  --bs-table-striped-bg: #cde5f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2d9e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8dfec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c2d9e6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d4edda;
  --bs-table-striped-bg: #c9e1cf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bfd5c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c4dbca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bfd5c4;
}

.table-info {
  --bs-table-bg: #d1ecf1;
  --bs-table-striped-bg: #c7e0e5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd4d9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1dadf;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd4d9;
}

.table-warning {
  --bs-table-bg: #fdf0cc;
  --bs-table-striped-bg: #f0e4c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4d8b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eadebd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e4d8b8;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #0f1a1c;
  --bs-table-striped-bg: #313131;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #3c3c3c;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #363636;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #3c3c3c;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #adb5bd;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0f1a1c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #0f1a1c;
  background-color: #fff;
  border-color: #9fdcff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 0 0.25rem rgba(62, 184, 255, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #0f1a1c;
  background-color: #e9ecef;
  pointer-events: none;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #0f1a1c;
  background-color: #e9ecef;
  pointer-events: none;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #f8f9fa;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #f8f9fa;
  background-color: #303032;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23f8f9fa' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #6c757d;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-select:focus {
  border-color: #9fdcff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 0 0.25rem rgba(62, 184, 255, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #f8f9fa;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #9fdcff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(62, 184, 255, 0.25);
}

.form-check-input:checked {
  background-color: #a4ff66;
  border-color: #a4ff66;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #a4ff66;
  border-color: #a4ff66;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0,0,0,0.25)'/%3E%3C/svg%3E");
  background-position: 0;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%239fdcff'/%3E%3C/svg%3E");
}

.form-switch .form-check-input:checked {
  background-position: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #141414, 0 0 0 0.25rem rgba(62, 184, 255, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #141414, 0 0 0 0.25rem rgba(62, 184, 255, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #a4ff66;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #c5eaff;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #a4ff66;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #c5eaff;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}

.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0f1a1c;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: #28a745;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23f8f9fa' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E"),
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #28a745;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #28a745;
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: #dc3545;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23f8f9fa' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E"),
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #dc3545;
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #dc3545;
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #f8f9fa;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #f8f9fa;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(62, 184, 255, 0.25);
}

.btn-check:active + .btn,
.btn-check:checked + .btn,
.btn.active,
.btn:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-check:active + .btn:focus,
.btn-check:checked + .btn:focus,
.btn.active:focus,
.btn:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 184, 255, 0.25),
    inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}

.btn-primary {
  color: #000;
  background-color: #a4ff66;
  border-color: #a4ff66;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btn-primary:hover {
  color: #000;
  background-color: #5bc3ff;
  border-color: #51bfff;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(53, 156, 217, 0.5);
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: #65c6ff;
  border-color: #51bfff;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(53, 156, 217, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #000;
  background-color: #a4ff66;
  border-color: #a4ff66;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}

.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #000;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-check:focus + .btn-success,
.btn-success:focus,
.btn-success:hover {
  color: #000;
  background-color: #48b461;
  border-color: #3eb058;
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(34, 142, 59, 0.5);
}

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #53b96a;
  border-color: #3eb058;
}

.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(34, 142, 59, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #000;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-info {
  color: #000;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-check:focus + .btn-info,
.btn-info:focus,
.btn-info:hover {
  color: #000;
  background-color: #3ab0c3;
  border-color: #2eabbf;
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(20, 138, 156, 0.5);
}

.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #45b5c6;
  border-color: #2eabbf;
}

.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(20, 138, 156, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #000;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-warning {
  color: #000;
  background-color: #f7b500;
  border-color: #f7b500;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-check:focus + .btn-warning,
.btn-warning:focus,
.btn-warning:hover {
  color: #000;
  background-color: #f8c026;
  border-color: #f8bc1a;
}

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(210, 154, 0, 0.5);
}

.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning:active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #f9c433;
  border-color: #f8bc1a;
}

.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(210, 154, 0, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #000;
  background-color: #f7b500;
  border-color: #f7b500;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-check:focus + .btn-danger,
.btn-danger:focus,
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger:active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-check:focus + .btn-light,
.btn-light:focus,
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light:active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #0f1a1c;
  border-color: #0f1a1c;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-check:focus + .btn-dark,
.btn-dark:focus,
.btn-dark:hover {
  color: #fff;
  background-color: #202020;
  border-color: #1e1e1e;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(71, 71, 71, 0.5);
}

.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark:active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1e1e1e;
  border-color: #1d1d1d;
}

.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(71, 71, 71, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #0f1a1c;
  border-color: #0f1a1c;
}

.btn-dark-1 {
  color: #fff;
  background-color: #303032;
  border-color: #303032;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-check:focus + .btn-dark-1,
.btn-dark-1:focus,
.btn-dark-1:hover {
  color: #fff;
  background-color: #29292b;
  border-color: #262628;
}

.btn-check:focus + .btn-dark-1,
.btn-dark-1:focus {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(79, 79, 81, 0.5);
}

.btn-check:active + .btn-dark-1,
.btn-check:checked + .btn-dark-1,
.btn-dark-1.active,
.btn-dark-1:active,
.show > .btn-dark-1.dropdown-toggle {
  color: #fff;
  background-color: #262628;
  border-color: #242426;
}

.btn-check:active + .btn-dark-1:focus,
.btn-check:checked + .btn-dark-1:focus,
.btn-dark-1.active:focus,
.btn-dark-1:active:focus,
.show > .btn-dark-1.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(79, 79, 81, 0.5);
}

.btn-dark-1.disabled,
.btn-dark-1:disabled {
  color: #fff;
  background-color: #303032;
  border-color: #303032;
}

.btn-dark-2 {
  color: #fff;
  background-color: #383838;
  border-color: #383838;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-check:focus + .btn-dark-2,
.btn-dark-2:focus,
.btn-dark-2:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-color: #2d2d2d;
}

.btn-check:focus + .btn-dark-2,
.btn-dark-2:focus {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(86, 86, 86, 0.5);
}

.btn-check:active + .btn-dark-2,
.btn-check:checked + .btn-dark-2,
.btn-dark-2.active,
.btn-dark-2:active,
.show > .btn-dark-2.dropdown-toggle {
  color: #fff;
  background-color: #2d2d2d;
  border-color: #2a2a2a;
}

.btn-check:active + .btn-dark-2:focus,
.btn-check:checked + .btn-dark-2:focus,
.btn-dark-2.active:focus,
.btn-dark-2:active:focus,
.show > .btn-dark-2.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(86, 86, 86, 0.5);
}

.btn-dark-2.disabled,
.btn-dark-2:disabled {
  color: #fff;
  background-color: #383838;
  border-color: #383838;
}

.btn-blue-dark {
  color: #fff;
  background-color: #141722;
  border-color: #141722;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-blue-dark:focus,
.btn-blue-dark:hover,
.btn-check:focus + .btn-blue-dark {
  color: #fff;
  background-color: #11141d;
  border-color: #10121b;
}

.btn-blue-dark:focus,
.btn-check:focus + .btn-blue-dark {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(55, 58, 67, 0.5);
}

.btn-blue-dark.active,
.btn-blue-dark:active,
.btn-check:active + .btn-blue-dark,
.btn-check:checked + .btn-blue-dark,
.show > .btn-blue-dark.dropdown-toggle {
  color: #fff;
  background-color: #10121b;
  border-color: #0f111a;
}

.btn-blue-dark.active:focus,
.btn-blue-dark:active:focus,
.btn-check:active + .btn-blue-dark:focus,
.btn-check:checked + .btn-blue-dark:focus,
.show > .btn-blue-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(55, 58, 67, 0.5);
}

.btn-blue-dark.disabled,
.btn-blue-dark:disabled {
  color: #fff;
  background-color: #141722;
  border-color: #141722;
}

.btn-outline-primary {
  color: #a4ff66;
  border-color: #a4ff66;
}

.btn-outline-primary:hover {
  color: #000;
  background-color: #a4ff66;
  border-color: #a4ff66;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 184, 255, 0.5);
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #000;
  background-color: #a4ff66;
  border-color: #a4ff66;
}

.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(62, 184, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #a4ff66;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #000;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.5);
}

.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  color: #000;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(23, 162, 184, 0.5);
}

.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #000;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-check:active + .btn-outline-info:focus,
.btn-check:checked + .btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-warning {
  color: #f7b500;
  border-color: #f7b500;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #f7b500;
  border-color: #f7b500;
}

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 181, 0, 0.5);
}

.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: #000;
  background-color: #f7b500;
  border-color: #f7b500;
}

.btn-check:active + .btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(247, 181, 0, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #f7b500;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:active + .btn-outline-light:focus,
.btn-check:checked + .btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #0f1a1c;
  border-color: #0f1a1c;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #0f1a1c;
  border-color: #0f1a1c;
}

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}

.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  color: #fff;
  background-color: #0f1a1c;
  border-color: #0f1a1c;
}

.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #0f1a1c;
  background-color: transparent;
}

.btn-outline-dark-1 {
  color: #303032;
  border-color: #303032;
}

.btn-outline-dark-1:hover {
  color: #fff;
  background-color: #303032;
  border-color: #303032;
}

.btn-check:focus + .btn-outline-dark-1,
.btn-outline-dark-1:focus {
  box-shadow: 0 0 0 0.25rem rgba(48, 48, 50, 0.5);
}

.btn-check:active + .btn-outline-dark-1,
.btn-check:checked + .btn-outline-dark-1,
.btn-outline-dark-1.active,
.btn-outline-dark-1.dropdown-toggle.show,
.btn-outline-dark-1:active {
  color: #fff;
  background-color: #303032;
  border-color: #303032;
}

.btn-check:active + .btn-outline-dark-1:focus,
.btn-check:checked + .btn-outline-dark-1:focus,
.btn-outline-dark-1.active:focus,
.btn-outline-dark-1.dropdown-toggle.show:focus,
.btn-outline-dark-1:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(48, 48, 50, 0.5);
}

.btn-outline-dark-1.disabled,
.btn-outline-dark-1:disabled {
  color: #303032;
  background-color: transparent;
}

.btn-outline-dark-2 {
  color: #383838;
  border-color: #383838;
}

.btn-outline-dark-2:hover {
  color: #fff;
  background-color: #383838;
  border-color: #383838;
}

.btn-check:focus + .btn-outline-dark-2,
.btn-outline-dark-2:focus {
  box-shadow: 0 0 0 0.25rem rgba(56, 56, 56, 0.5);
}

.btn-check:active + .btn-outline-dark-2,
.btn-check:checked + .btn-outline-dark-2,
.btn-outline-dark-2.active,
.btn-outline-dark-2.dropdown-toggle.show,
.btn-outline-dark-2:active {
  color: #fff;
  background-color: #383838;
  border-color: #383838;
}

.btn-check:active + .btn-outline-dark-2:focus,
.btn-check:checked + .btn-outline-dark-2:focus,
.btn-outline-dark-2.active:focus,
.btn-outline-dark-2.dropdown-toggle.show:focus,
.btn-outline-dark-2:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(56, 56, 56, 0.5);
}

.btn-outline-dark-2.disabled,
.btn-outline-dark-2:disabled {
  color: #383838;
  background-color: transparent;
}

.btn-outline-blue-dark {
  color: #141722;
  border-color: #141722;
}

.btn-outline-blue-dark:hover {
  color: #fff;
  background-color: #141722;
  border-color: #141722;
}

.btn-check:focus + .btn-outline-blue-dark,
.btn-outline-blue-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(20, 23, 34, 0.5);
}

.btn-check:active + .btn-outline-blue-dark,
.btn-check:checked + .btn-outline-blue-dark,
.btn-outline-blue-dark.active,
.btn-outline-blue-dark.dropdown-toggle.show,
.btn-outline-blue-dark:active {
  color: #fff;
  background-color: #141722;
  border-color: #141722;
}

.btn-check:active + .btn-outline-blue-dark:focus,
.btn-check:checked + .btn-outline-blue-dark:focus,
.btn-outline-blue-dark.active:focus,
.btn-outline-blue-dark.dropdown-toggle.show:focus,
.btn-outline-blue-dark:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.25rem rgba(20, 23, 34, 0.5);
}

.btn-outline-blue-dark.disabled,
.btn-outline-blue-dark:disabled {
  color: #141722;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #f8f9fa;
  text-decoration: none;
}

.btn-link:hover {
  color: #3293cc;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #f8f9fa;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  display: none;
}

.dropstart .dropdown-toggle:before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #0f1a1c;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #222;
  background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #a4ff66;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #0f1a1c;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #303032;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.15);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #a4ff66;
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split:after,
.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #0f1a1c;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #0f1a1c;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #141414;
  border-color: #f8f9fa;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #a4ff66;
}

.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: 75vh;
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0,0,0,0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: hsla(0, 0%, 100%, 0.55);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: hsla(0, 0%, 100%, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: hsla(0, 0%, 100%, 0.25);
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: hsla(0, 0%, 100%, 0.55);
  border-color: hsla(0, 0%, 100%, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255,255,255,0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: hsla(0, 0%, 100%, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
}

.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #f8f9fa;
  text-align: left;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button.collapsed {
  border-bottom-width: 0;
}

.accordion-button:not(.collapsed) {
  color: #38a6e6;
  background-color: #ecf8ff;
}

.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2338a6e6'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E");
  transform: rotate(180deg);
}

.accordion-button:after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f8f9fa'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #9fdcff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(62, 184, 255, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed,
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 0 1px;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #a4ff66;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  border-color: #dee2e6;
}

.page-link:focus,
.page-link:hover {
  color: #3293cc;
  background-color: #e9ecef;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(62, 184, 255, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #a4ff66;
  border-color: #a4ff66;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 1.5rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.625rem 0.5rem;
}

.alert-primary {
  color: #256e99;
  background-color: #d8f1ff;
  border-color: #c5eaff;
}

.alert-primary .alert-link {
  color: #1e587a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #186429;
  background-color: #d4edda;
  border-color: #bfe5c7;
}

.alert-success .alert-link {
  color: #135021;
}

.alert-info {
  color: #0e616e;
  background-color: #d1ecf1;
  border-color: #b9e3ea;
}

.alert-info .alert-link {
  color: #0b4e58;
}

.alert-warning {
  color: #634800;
  background-color: #fdf0cc;
  border-color: #fde9b3;
}

.alert-warning .alert-link {
  color: #4f3a00;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #171717;
  background-color: #d4d4d4;
  border-color: #bebebe;
}

.alert-dark .alert-link {
  color: #121212;
}

.alert-dark-1 {
  color: #1d1d1e;
  background-color: #d6d6d6;
  border-color: #c1c1c2;
}

.alert-dark-1 .alert-link {
  color: #171718;
}

.alert-dark-2 {
  color: #222;
  background-color: #d7d7d7;
  border-color: #c3c3c3;
}

.alert-dark-2 .alert-link {
  color: #1b1b1b;
}

.alert-blue-dark {
  color: #0c0e14;
  background-color: #d0d1d3;
  border-color: #b9b9bd;
}

.alert-blue-dark .alert-link {
  color: #0a0b10;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  height: 1rem;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.progress,
.progress-bar {
  display: flex;
  overflow: hidden;
}

.progress-bar {
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #a4ff66;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #f8f9fa;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #a4ff66;
  border-color: #a4ff66;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #256e99;
  background-color: #d8f1ff;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #256e99;
  background-color: #c2d9e6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #256e99;
  border-color: #256e99;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #186429;
  background-color: #d4edda;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #186429;
  background-color: #bfd5c4;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #186429;
  border-color: #186429;
}

.list-group-item-info {
  color: #0e616e;
  background-color: #d1ecf1;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0e616e;
  background-color: #bcd4d9;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0e616e;
  border-color: #0e616e;
}

.list-group-item-warning {
  color: #634800;
  background-color: #fdf0cc;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #634800;
  background-color: #e4d8b8;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #634800;
  border-color: #634800;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #171717;
  background-color: #d4d4d4;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #171717;
  background-color: #bfbfbf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #171717;
  border-color: #171717;
}

.list-group-item-dark-1 {
  color: #1d1d1e;
  background-color: #d6d6d6;
}

.list-group-item-dark-1.list-group-item-action:focus,
.list-group-item-dark-1.list-group-item-action:hover {
  color: #1d1d1e;
  background-color: #c1c1c1;
}

.list-group-item-dark-1.list-group-item-action.active {
  color: #fff;
  background-color: #1d1d1e;
  border-color: #1d1d1e;
}

.list-group-item-dark-2 {
  color: #222;
  background-color: #d7d7d7;
}

.list-group-item-dark-2.list-group-item-action:focus,
.list-group-item-dark-2.list-group-item-action:hover {
  color: #222;
  background-color: #c2c2c2;
}

.list-group-item-dark-2.list-group-item-action.active {
  color: #fff;
  background-color: #222;
  border-color: #222;
}

.list-group-item-blue-dark {
  color: #0c0e14;
  background-color: #d0d1d3;
}

.list-group-item-blue-dark.list-group-item-action:focus,
.list-group-item-blue-dark.list-group-item-action:hover {
  color: #0c0e14;
  background-color: #bbbcbe;
}

.list-group-item-blue-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0c0e14;
  border-color: #0c0e14;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E")
    50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(62, 184, 255, 0.25);
  opacity: 1;
}

.btn-close.disabled,
.btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translateY(-50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #0f1a1c;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #0f1a1c;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  /* font-family: "Lato", sans-serif; */
  /* font-family: var(--bs-font-sans-serif); */
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow:before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[data-popper-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before,
.bs-tooltip-top .tooltip-arrow:before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-auto[data-popper-placement^="right"],
.bs-tooltip-end {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-auto[data-popper-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before,
.bs-tooltip-bottom .tooltip-arrow:before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-auto[data-popper-placement^="left"],
.bs-tooltip-start {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before,
.bs-tooltip-start .tooltip-arrow:before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  /* font-family: "Lato", sans-serif; */
  /* font-family: var(--bs-font-sans-serif); */
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow:after,
.popover .popover-arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before,
.bs-popover-top > .popover-arrow:before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after,
.bs-popover-top > .popover-arrow:after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before,
.bs-popover-end > .popover-arrow:before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after,
.bs-popover-end > .popover-arrow:after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before,
.bs-popover-bottom > .popover-arrow:before {
  top: 0;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after,
.bs-popover-bottom > .popover-arrow:after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before,
.bs-popover-bottom .popover-header:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before,
.bs-popover-start > .popover-arrow:before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after,
.bs-popover-start > .popover-arrow:after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem;
  color: #f8f9fa;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner:after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%);
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    transition: none;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  -webkit-filter: invert(1) grayscale(100);
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #a4ff66;
}

.link-primary:focus,
.link-primary:hover {
  color: #65c6ff;
}

.link-secondary {
  color: #6c757d;
}

.link-secondary:focus,
.link-secondary:hover {
  color: #565e64;
}

.link-success {
  color: #28a745;
}

.link-success:focus,
.link-success:hover {
  color: #53b96a;
}

.link-info {
  color: #17a2b8;
}

.link-info:focus,
.link-info:hover {
  color: #45b5c6;
}

.link-warning {
  color: #f7b500;
}

.link-warning:focus,
.link-warning:hover {
  color: #f9c433;
}

.link-danger {
  color: #dc3545;
}

.link-danger:focus,
.link-danger:hover {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}

.link-light:focus,
.link-light:hover {
  color: #f9fafb;
}

.link-dark {
  color: #0f1a1c;
}

.link-dark:focus,
.link-dark:hover {
  color: #1e1e1e;
}

.link-dark-1 {
  color: #303032;
}

.link-dark-1:focus,
.link-dark-1:hover {
  color: #262628;
}

.link-dark-2 {
  color: #383838;
}

.link-dark-2:focus,
.link-dark-2:hover {
  color: #2d2d2d;
}

.link-blue-dark {
  color: #141722;
}

.link-blue-dark:focus,
.link-blue-dark:hover {
  color: #10121b;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio:before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.85714%;
}

.fixed-top {
  top: 0;
}

.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  bottom: 0;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #0f1a1c !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #0f1a1c !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #0f1a1c !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #0f1a1c !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #0f1a1c !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #a4ff66 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #f7b500 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #0f1a1c !important;
}

.border-dark-1 {
  border-color: #303032 !important;
}

.border-dark-2 {
  border-color: #383838 !important;
}

.border-blue-dark {
  border-color: #141722 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  grid-gap: 0 !important;
  gap: 0 !important;
}

.gap-1 {
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important;
}

.gap-2 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.gap-3 {
  grid-gap: 1rem !important;
  gap: 1rem !important;
}

.gap-4 {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
}

.gap-5 {
  grid-gap: 3rem !important;
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #a4ff66 !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #28a745 !important;
}

.text-info {
  color: #17a2b8 !important;
}

.text-warning {
  color: #f7b500 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #0f1a1c !important;
}

.text-dark-1 {
  color: #303032 !important;
}

.text-dark-2 {
  color: #383838 !important;
}

.text-blue-dark {
  color: #141722 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #f8f9fa !important;
}

.text-muted {
  color: #adb5bd !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-primary {
  background-color: #a4ff66 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #28a745 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-warning {
  background-color: #f7b500 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #0f1a1c !important;
}

.bg-dark-1 {
  background-color: #303032 !important;
}

.bg-dark-2 {
  background-color: #383838 !important;
}

.bg-blue-dark {
  background-color: #141722 !important;
}

.bg-body {
  background-color: #141414 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  ) !important;
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.font-monospace {
  /* font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; */
  /* font-family: var(--bs-font-monospace) !important */
}

.user-select-all {
  -webkit-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
}

.rounded-end,
.rounded-top {
  border-top-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-end {
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .gap-sm-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .gap-sm-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .gap-md-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .gap-md-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .gap-lg-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .gap-lg-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .gap-xl-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .gap-xl-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .gap-xxl-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

html {
  height: 100%;
}

body,
body > div,
html,
html > div {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

body .ReactModalPortal,
html .ReactModalPortal {
  flex-grow: 0;
  flex-shrink: 1;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

hr {
  opacity: 1;
}

.font-size-base {
  font-size: 1rem;
}

.font-size-sm {
  font-size: 0.875rem;
}

.font-size-xs {
  font-size: 0.75rem;
}

.font-size-xxs {
  font-size: 0.625rem;
}

.font-size-h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  .font-size-h1 {
    font-size: 2.5rem;
  }
}

.font-size-h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  .font-size-h2 {
    font-size: 2rem;
  }
}

.font-size-h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  .font-size-h3 {
    font-size: 1.75rem;
  }
}

.font-size-h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  .font-size-h4 {
    font-size: 1.5rem;
  }
}

.font-size-h5 {
  font-size: 1.25rem;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-small,
.text-small p {
  font-size: 0.875rem;
}

.text-small p {
  margin-bottom: 0.375rem;
}

.text-xs {
  font-size: 0.75rem;
}

.img-fluid {
  min-height: 1px;
}

.circle {
  border-radius: 999px;
}

p {
  font-size: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

li {
  font-size: 1rem;
}

.h1,
h1 {
  font-weight: 700;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.spin1 {
  animation-name: spin;
  animation-duration: 0.2s;
  animation-iteration-count: 5;
  animation-timing-function: linear;
}

.pulse {
  animation: pulse 0.3s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

.outline-0 {
  outline: none !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.react-stockchart {
  left: 0;
}

body {
  background: #0f1a1c;
}

.unpad-3 {
  margin-right: -1rem;
  margin-left: -1rem;
}

.unpad-2 {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.bg-black {
  background-color: #000;
}

.table-color {
  background-color: #0f1a1c;
  border-color: #484e53;
}

.nav-link {
  color: #fff;
}

.table-col-wrap {
  word-wrap: break-word;
  white-space: unset;
  text-overflow: unset;
  overflow: visible !important;
  overflow: initial !important;
}

.ReactVirtualized__Table {
  font-size: 0.875rem;
}

.ReactVirtualized__Table .font-size-sm,
.ReactVirtualized__Table .small,
.ReactVirtualized__Table small {
  font-size: 0.75rem;
}

.w-0 {
  width: 0;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.main-nav a,
.main-nav a:hover {
  color: #f8f9fa;
}

.main-nav .active {
  font-weight: 700;
  color: #6c757d;
}

.main-nav .active:hover {
  color: #6c757d;
}

.white-space-wrap {
  white-space: normal;
}

.alert {
  padding-right: 32px;
}

.highcharts-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif; */
  font-size: 12px;
}

.highcharts-root {
  display: block;
}

.highcharts-root text {
  stroke-width: 0;
}

.highcharts-strong {
  font-weight: 700;
}

.highcharts-emphasized {
  font-style: italic;
}

.highcharts-anchor {
  cursor: pointer;
}

.highcharts-background {
  fill: #0e1119;
}

.highcharts-label-box,
.highcharts-plot-background,
.highcharts-plot-border {
  fill: none;
}

.highcharts-button-box {
  fill: inherit;
}

.highcharts-tracker-line {
  stroke-linejoin: round;
  stroke: hsla(0, 0%, 75.3%, 0.0001);
  stroke-width: 22;
  fill: none;
}

.highcharts-tracker-area {
  fill: hsla(0, 0%, 75.3%, 0.0001);
  stroke-width: 0;
}

.highcharts-title {
  fill: #333;
  font-size: 1.5em;
}

.highcharts-subtitle {
  fill: #666;
}

.highcharts-axis-line {
  fill: none;
  stroke: #ccd6eb;
}

.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-axis-title {
  fill: #666;
}

.highcharts-axis-labels {
  fill: #666;
  cursor: default;
  font-size: 0.9em;
}

.highcharts-grid-line {
  fill: none;
  stroke: #e6e6e6;
}

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0px;
}

.highcharts-tick {
  stroke: #ccd6eb;
}

.highcharts-yaxis .highcharts-tick {
  stroke-width: 0;
}

.highcharts-minor-grid-line {
  stroke: #f2f2f2;
}

.highcharts-crosshair-thin {
  stroke-width: 1px;
  stroke: #ccc;
}

.highcharts-crosshair-category {
  stroke: #ccd6eb;
  stroke-opacity: 0.25;
}

.highcharts-credits {
  cursor: pointer;
  fill: #999;
  font-size: 0.7em;
  transition: fill 0.25s, font-size 0.25s;
}

.highcharts-credits:hover {
  fill: #000;
  font-size: 1em;
}

.highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  transition: stroke 0.15s;
}

.highcharts-tooltip text {
  fill: #333;
}

.highcharts-tooltip .highcharts-header {
  font-size: 0.85em;
}

.highcharts-tooltip-box {
  stroke-width: 1px;
}

.highcharts-tooltip-box,
.highcharts-tooltip-box .highcharts-label-box {
  fill: #f7f7f7;
  fill-opacity: 0.85;
}

.highcharts-selection-marker {
  fill: #335cad;
  fill-opacity: 0.25;
}

.highcharts-graph {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.highcharts-state-hover .highcharts-graph {
  stroke-width: 3;
}

.highcharts-state-hover path {
  transition: stroke-width 50;
}

.highcharts-state-normal path {
  transition: stroke-width 0.25s;
}

.highcharts-data-labels,
.highcharts-markers,
.highcharts-point,
g.highcharts-series {
  transition: opacity 0.25s;
}

.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active
  .highcharts-data-labels:not(.highcharts-series-hover),
.highcharts-legend-series-active
  .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active
  g.highcharts-series:not(.highcharts-series-hover) {
  opacity: 0.2;
}

.highcharts-color-0 {
  fill: "blue" #a4ff66;
  stroke: "blue" #a4ff66;
}

.highcharts-color-1 {
  fill: "blue-dark" #141722;
  stroke: "blue-dark" #141722;
}

.highcharts-color-2 {
  fill: "indigo" #6610f2;
  stroke: "indigo" #6610f2;
}

.highcharts-color-3 {
  fill: "purple" #6f42c1;
  stroke: "purple" #6f42c1;
}

.highcharts-color-4 {
  fill: "pink" #d63384;
  stroke: "pink" #d63384;
}

.highcharts-color-5 {
  fill: "red" #dc3545;
  stroke: "red" #dc3545;
}

.highcharts-color-6 {
  fill: "orange" #fd7e14;
  stroke: "orange" #fd7e14;
}

.highcharts-color-7 {
  fill: "yellow" #f7b500;
  stroke: "yellow" #f7b500;
}

.highcharts-color-8 {
  fill: "green" #28a745;
  stroke: "green" #28a745;
}

.highcharts-color-9 {
  fill: "teal" #20c997;
  stroke: "teal" #20c997;
}

.highcharts-color-10 {
  fill: "cyan" #17a2b8;
  stroke: "cyan" #17a2b8;
}

.highcharts-color-11 {
  fill: "white" #fff;
  stroke: "white" #fff;
}

.highcharts-color-12 {
  fill: "gray" #6c757d;
  stroke: "gray" #6c757d;
}

.highcharts-color-13 {
  fill: "gray-dark" #0f1a1c;
  stroke: "gray-dark" #0f1a1c;
}

.highcharts-area {
  fill-opacity: 0.75;
  stroke-width: 0;
}

.highcharts-markers {
  stroke-width: 1px;
  stroke: #0e1119;
}

.highcharts-point {
  stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
  stroke-width: 0;
}

.highcharts-data-label {
  font-size: 0.9em;
  font-weight: 700;
}

.highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-data-label text,
text.highcharts-data-label {
  fill: #333;
}

.highcharts-data-label-connector {
  fill: none;
}

.highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select {
  fill: #ccc;
  stroke: #fff;
}

.highcharts-column-series rect.highcharts-point {
  stroke: #0e1119;
}

.highcharts-column-series .highcharts-point {
  transition: fill-opacity 0.25s;
}

.highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #0e1119;
}

.highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #0e1119;
}

.highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #0e1119;
}

.highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  stroke: #e6e6e6;
  transition: stroke 0.25s, fill 0.25s, fill-opacity 0.25s;
}

.highcharts-treemap-series .highcharts-point-hover {
  stroke: #999;
  transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
  display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
  fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75;
}

.highcharts-legend-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-legend-item text {
  fill: #333;
  font-weight: 700;
  font-size: 1.5rem;
  cursor: pointer;
  stroke-width: 0;
}

.highcharts-legend-item:hover text {
  fill: #fff;
}

.highcharts-legend-item-hidden * {
  fill: #ccc !important;
  stroke: #ccc !important;
  transition: fill 0.25s;
}

.highcharts-legend-nav-active {
  fill: #039;
  cursor: pointer;
}

.highcharts-legend-nav-inactive {
  fill: #ccc;
}

.highcharts-legend-title-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-loading {
  position: absolute;
  background-color: #0e1119;
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  transition: opacity 0.25s;
}

.highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.25s, height 0.25s step-end;
}

.highcharts-loading-inner {
  font-weight: 700;
  position: relative;
  top: 45%;
}

.highcharts-pane,
.highcharts-plot-band {
  fill: #fff;
  fill-opacity: 0.05;
}

.highcharts-plot-line {
  fill: none;
  stroke: #999;
  stroke-width: 1px;
}

.highcharts-boxplot-box {
  fill: #0e1119;
}

.highcharts-boxplot-median {
  stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
  stroke: #fff;
}

.highcharts-gauge-series .highcharts-data-label-box {
  stroke: #ccc;
  stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
  fill: #fff;
  stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
  stroke: #333;
  stroke-dasharray: 1, 3;
}

.highcharts-sankey-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-sankey-series .highcharts-link {
  transition: fill 0.25s, fill-opacity 0.25s;
  fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
  transition: fill 50ms, fill-opacity 50ms;
  fill-opacity: 1;
}

.highcharts-navigator-mask-outside {
  fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
  fill: #6685c2;
  fill-opacity: 0.25;
  cursor: ew-resize;
}

.highcharts-navigator-outline {
  stroke: #ccc;
  fill: none;
}

.highcharts-navigator-handle {
  stroke: #ccc;
  fill: #f2f2f2;
  cursor: ew-resize;
}

.highcharts-navigator-series {
  fill: #335cad;
  stroke: #335cad;
}

.highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #e6e6e6;
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
  fill: #999;
}

.highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}

.highcharts-scrollbar-thumb {
  fill: #ccc;
  stroke: #ccc;
  stroke-width: 1px;
}

.highcharts-scrollbar-button {
  fill: #e6e6e6;
  stroke: #ccc;
  stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
  fill: #666;
}

.highcharts-scrollbar-rifles {
  stroke: #666;
  stroke-width: 1px;
}

.highcharts-scrollbar-track {
  fill: #f2f2f2;
  stroke: #f2f2f2;
  stroke-width: 1px;
}

.highcharts-button {
  fill: #f7f7f7;
  stroke: #ccc;
  cursor: default;
  stroke-width: 1px;
  transition: fill 0.25s;
}

.highcharts-button text {
  fill: #333;
}

.highcharts-button-hover {
  transition: fill 0ms;
  fill: #e6e6e6;
  stroke: #ccc;
}

.highcharts-button-hover text {
  fill: #333;
}

.highcharts-button-pressed {
  font-weight: 700;
  fill: #e6ebf5;
  stroke: #ccc;
}

.highcharts-button-pressed text {
  fill: #333;
  font-weight: 700;
}

.highcharts-button-disabled text {
  fill: #333;
}

.highcharts-range-selector-buttons .highcharts-button {
  stroke-width: 0px;
}

.highcharts-range-label rect {
  fill: none;
}

.highcharts-range-label text {
  fill: #666;
}

.highcharts-range-input rect {
  fill: none;
}

.highcharts-range-input text {
  fill: #333;
}

.highcharts-range-input {
  stroke-width: 1px;
  stroke: #ccc;
}

input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em;
}

.highcharts-crosshair-label text {
  fill: #0e1119;
  font-size: 1.1em;
}

.highcharts-crosshair-label .highcharts-label-box {
  fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
  stroke: #fff;
  stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
  fill: green;
}

.highcharts-candlestick-series .highcharts-point-down {
  fill: red;
}

.highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
  stroke: #999;
  fill: #0e1119;
  transition: fill 0.25s;
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
  stroke: #fff;
  fill: #ccd6eb;
}

.highcharts-flags-series .highcharts-point text {
  fill: #fff;
  font-size: 0.9em;
  font-weight: 700;
}

.highcharts-map-series .highcharts-point {
  transition: fill 0.5s, fill-opacity 0.5s, stroke-width 0.25s;
  stroke: #ccc;
}

.highcharts-map-series .highcharts-point-hover {
  transition: fill 0ms, fill-opacity 0ms;
  fill-opacity: 0.5;
  stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
  fill: none;
}

.highcharts-heatmap-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-map-navigation {
  font-size: 1.3em;
  font-weight: 700;
  text-align: center;
}

.highcharts-coloraxis {
  stroke-width: 0;
}

.highcharts-coloraxis-marker {
  fill: #999;
}

.highcharts-null-point {
  fill: #f7f7f7;
}

.highcharts-3d-frame {
  fill: transparent;
}

.highcharts-contextbutton {
  fill: #0e1119;
  stroke: none;
  stroke-linecap: round;
}

.highcharts-contextbutton:hover {
  fill: #e6e6e6;
  stroke: #e6e6e6;
}

.highcharts-button-symbol {
  stroke: #666;
  stroke-width: 3px;
}

.highcharts-menu {
  border: 1px solid #999;
  background: #0e1119;
  padding: 5px 0;
  box-shadow: 3px 3px 10px #888;
}

.highcharts-menu-item {
  padding: 0.5em 1em;
  background: none;
  color: #333;
  cursor: pointer;
  transition: background 0.25s, color 0.25s;
}

.highcharts-menu-item:hover {
  background: #335cad;
  color: #0e1119;
}

.highcharts-drilldown-point {
  cursor: pointer;
}

.highcharts-drilldown-axis-label,
.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label {
  cursor: pointer;
  fill: #039;
  font-weight: 700;
  text-decoration: underline;
}

.highcharts-no-data text {
  font-weight: 700;
  font-size: 12px;
  fill: #666;
}

.highcharts-axis-resizer {
  cursor: ns-resize;
  stroke: #000;
  stroke-width: 2px;
}

.highcharts-bullet-target {
  stroke-width: 0;
}

.highcharts-lineargauge-target,
.highcharts-lineargauge-target-line {
  stroke-width: 1px;
  stroke: #333;
}

.highcharts-annotation-label-box {
  stroke-width: 1px;
  stroke: #fff;
  fill: #fff;
  fill-opacity: 0.75;
}

.highcharts-annotation-label text {
  fill: #e6e6e6;
}

.highcharts-treegrid-node-collapsed,
.highcharts-treegrid-node-expanded {
  cursor: pointer;
}

.highcharts-point-connecting-path {
  fill: none;
}

.highcharts-grid-axis .highcharts-axis-line,
.highcharts-grid-axis .highcharts-tick {
  stroke-width: 1px;
}

.highcharts-credits {
  display: none !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #0c0c0c;
}

.ReactVirtualized__Table__row {
  border-bottom: 1px solid #0f1a1c;
}

.ReactVirtualized__Table__rowColumn:not(:last-child) {
  border-right: 1px solid #0f1a1c;
}

.ReactVirtualized__Table__headerRow {
  text-transform: none;
  color: #f8f9fa;
  background-color: #0f1a1c;
  line-height: 1;
}

.ReactVirtualized__Table__headerRow.bg-blue-dark {
  background-color: #0f1a1c !important;
}

.ReactVirtualized__Table__headerRow div:focus {
  outline: none;
}

.ReactVirtualized__Table__row.bg-blue-dark:nth-child(odd) {
  background-color: #0e1119 !important;
}

.Loading_lds-spinner__pbVUk {
  color: official;
  display: inline-block;
  position: relative;
}

.Loading_lds-spinner__pbVUk div {
  transform-origin: 40px 40px;
  animation: Loading_lds-spinner__pbVUk 1.2s linear infinite;
}

.Loading_lds-spinner__pbVUk div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.Loading_lds-spinner__pbVUk div:first-child {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.Loading_lds-spinner__pbVUk div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.Loading_lds-spinner__pbVUk div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.Loading_lds-spinner__pbVUk div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.Loading_lds-spinner__pbVUk div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.Loading_lds-spinner__pbVUk div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.Loading_lds-spinner__pbVUk div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.Loading_lds-spinner__pbVUk div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.Loading_lds-spinner__pbVUk div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.Loading_lds-spinner__pbVUk div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.Loading_lds-spinner__pbVUk div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.Loading_lds-spinner__pbVUk div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes Loading_lds-spinner__pbVUk {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.Navbar_logo__3jo1w {
  font-size: 24px;
  color: #000;
  font-weight: 800;
  display: flex;
  align-items: center;
}

.Navbar_nav__YcM5- {
  border-bottom: 3px solid #484e53;
}

.TokenAddressFinder_option__1xUAP:hover {
  background-color: #eee;
}

.SwapTokenList_swapTokenList__DKCmE .ReactVirtualized__Table__row:hover {
  background-color: #444;
}

.SwapTokenList_swapTokenList__DKCmE .ReactVirtualized__Table__rowColumn {
  height: 100%;
  display: flex;
}

/*!
 * Bootstrap Utilities v5.0.0-beta2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.WalletTokens_clearfix__3-Ncr:after {
  display: block;
  clear: both;
  content: "";
}

.WalletTokens_link-primary__3QqjX {
  color: #a4ff66;
}

.WalletTokens_link-primary__3QqjX:focus,
.WalletTokens_link-primary__3QqjX:hover {
  color: #65c6ff;
}

.WalletTokens_link-secondary__lt2Ek {
  color: #6c757d;
}

.WalletTokens_link-secondary__lt2Ek:focus,
.WalletTokens_link-secondary__lt2Ek:hover {
  color: #565e64;
}

.WalletTokens_link-success__35fM- {
  color: #28a745;
}

.WalletTokens_link-success__35fM-:focus,
.WalletTokens_link-success__35fM-:hover {
  color: #53b96a;
}

.WalletTokens_link-info__3HmuF {
  color: #17a2b8;
}

.WalletTokens_link-info__3HmuF:focus,
.WalletTokens_link-info__3HmuF:hover {
  color: #45b5c6;
}

.WalletTokens_link-warning__3wQr9 {
  color: #f7b500;
}

.WalletTokens_link-warning__3wQr9:focus,
.WalletTokens_link-warning__3wQr9:hover {
  color: #f9c433;
}

.WalletTokens_link-danger__1LCB9 {
  color: #dc3545;
}

.WalletTokens_link-danger__1LCB9:focus,
.WalletTokens_link-danger__1LCB9:hover {
  color: #b02a37;
}

.WalletTokens_link-light__3XIFp {
  color: #f8f9fa;
}

.WalletTokens_link-light__3XIFp:focus,
.WalletTokens_link-light__3XIFp:hover {
  color: #f9fafb;
}

.WalletTokens_link-dark__3jFlN {
  color: #0f1a1c;
}

.WalletTokens_link-dark__3jFlN:focus,
.WalletTokens_link-dark__3jFlN:hover {
  color: #1e1e1e;
}

.WalletTokens_link-dark-1__CGXSd {
  color: #303032;
}

.WalletTokens_link-dark-1__CGXSd:focus,
.WalletTokens_link-dark-1__CGXSd:hover {
  color: #262628;
}

.WalletTokens_link-dark-2__11G2l {
  color: #383838;
}

.WalletTokens_link-dark-2__11G2l:focus,
.WalletTokens_link-dark-2__11G2l:hover {
  color: #2d2d2d;
}

.WalletTokens_link-blue-dark__GrZG6 {
  color: #141722;
}

.WalletTokens_link-blue-dark__GrZG6:focus,
.WalletTokens_link-blue-dark__GrZG6:hover {
  color: #10121b;
}

.WalletTokens_ratio__2kTqx {
  position: relative;
  width: 100%;
}

.WalletTokens_ratio__2kTqx:before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.WalletTokens_ratio__2kTqx > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.WalletTokens_ratio-1x1__16WSh {
  --bs-aspect-ratio: 100%;
}

.WalletTokens_ratio-4x3__uEy3A {
  --bs-aspect-ratio: 75%;
}

.WalletTokens_ratio-16x9__vPsfw {
  --bs-aspect-ratio: 56.25%;
}

.WalletTokens_ratio-21x9__33cCX {
  --bs-aspect-ratio: 42.85714%;
}

.WalletTokens_fixed-top__20SoI {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.WalletTokens_fixed-bottom__2Z5Ao {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.WalletTokens_sticky-top__1w9d2 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .WalletTokens_sticky-sm-top__10ryh {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .WalletTokens_sticky-md-top__1dkWI {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .WalletTokens_sticky-lg-top__1XRY6 {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .WalletTokens_sticky-xl-top__3lI4s {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .WalletTokens_sticky-xxl-top__2pWEE {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.WalletTokens_visually-hidden-focusable__1wubd:not(:focus):not(:focus-within),
.WalletTokens_visually-hidden__HcFmF {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.WalletTokens_stretched-link__1gZPJ:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.WalletTokens_text-truncate__JurWo {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.WalletTokens_align-baseline__Fmhfd {
  vertical-align: baseline !important;
}

.WalletTokens_align-top__kw2CM {
  vertical-align: top !important;
}

.WalletTokens_align-middle__2Jdhl {
  vertical-align: middle !important;
}

.WalletTokens_align-bottom__3Lx2i {
  vertical-align: bottom !important;
}

.WalletTokens_align-text-bottom__mr7QT {
  vertical-align: text-bottom !important;
}

.WalletTokens_align-text-top__WoNQz {
  vertical-align: text-top !important;
}

.WalletTokens_float-start__caM6S {
  float: left !important;
}

.WalletTokens_float-end__2h3i3 {
  float: right !important;
}

.WalletTokens_float-none__3latI {
  float: none !important;
}

.WalletTokens_overflow-auto__20wKJ {
  overflow: auto !important;
}

.WalletTokens_overflow-hidden__2470o {
  overflow: hidden !important;
}

.WalletTokens_overflow-visible__1XQsN {
  overflow: visible !important;
}

.WalletTokens_overflow-scroll__2uJ5G {
  overflow: scroll !important;
}

.WalletTokens_d-inline__2Oe9n {
  display: inline !important;
}

.WalletTokens_d-inline-block__UcqQe {
  display: inline-block !important;
}

.WalletTokens_d-block__cmdEp {
  display: block !important;
}

.WalletTokens_d-grid__1VbwR {
  display: grid !important;
}

.WalletTokens_d-table__1vSAX {
  display: table !important;
}

.WalletTokens_d-table-row__hSfxj {
  display: table-row !important;
}

.WalletTokens_d-table-cell__15G8r {
  display: table-cell !important;
}

.WalletTokens_d-flex__3YAgN {
  display: flex !important;
}

.WalletTokens_d-inline-flex__37n1O {
  display: inline-flex !important;
}

.WalletTokens_d-none__3EJQg {
  display: none !important;
}

.WalletTokens_shadow__h6Z2e {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.WalletTokens_shadow-sm__2uOXP {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.WalletTokens_shadow-lg__zLekU {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.WalletTokens_shadow-none__39Uoe {
  box-shadow: none !important;
}

.WalletTokens_position-static__1VOa9 {
  position: static !important;
}

.WalletTokens_position-relative__MLfA9 {
  position: relative !important;
}

.WalletTokens_position-absolute__TGLEj {
  position: absolute !important;
}

.WalletTokens_position-fixed__32Nm5 {
  position: fixed !important;
}

.WalletTokens_position-sticky__1yleE {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.WalletTokens_top-0__3fVSl {
  top: 0 !important;
}

.WalletTokens_top-50__3ORvM {
  top: 50% !important;
}

.WalletTokens_top-100__2Euk4 {
  top: 100% !important;
}

.WalletTokens_bottom-0__9y92M {
  bottom: 0 !important;
}

.WalletTokens_bottom-50__1xReP {
  bottom: 50% !important;
}

.WalletTokens_bottom-100__bqSqp {
  bottom: 100% !important;
}

.WalletTokens_start-0__2_Gjr {
  left: 0 !important;
}

.WalletTokens_start-50__2ifbq {
  left: 50% !important;
}

.WalletTokens_start-100__vU_5s {
  left: 100% !important;
}

.WalletTokens_end-0__2S_NE {
  right: 0 !important;
}

.WalletTokens_end-50__s4YXu {
  right: 50% !important;
}

.WalletTokens_end-100__7ZOz_ {
  right: 100% !important;
}

.WalletTokens_translate-middle__2U57A {
  transform: translate(-50%, -50%) !important;
}

.WalletTokens_translate-middle-x__1mP3G {
  transform: translateX(-50%) !important;
}

.WalletTokens_translate-middle-y__3Nw2p {
  transform: translateY(-50%) !important;
}

.WalletTokens_border__3ACPo {
  border: 1px solid #0f1a1c !important;
}

.WalletTokens_border-0__-l9pK {
  border: 0 !important;
}

.WalletTokens_border-top__352zI {
  border-top: 1px solid #0f1a1c !important;
}

.WalletTokens_border-top-0__2Lmvf {
  border-top: 0 !important;
}

.WalletTokens_border-end__18dDx {
  border-right: 1px solid #0f1a1c !important;
}

.WalletTokens_border-end-0__3kw0n {
  border-right: 0 !important;
}

.WalletTokens_border-bottom__2AlRn {
  border-bottom: 1px solid #0f1a1c !important;
}

.WalletTokens_border-bottom-0__3G17p {
  border-bottom: 0 !important;
}

.WalletTokens_border-start__2ztqn {
  border-left: 1px solid #0f1a1c !important;
}

.WalletTokens_border-start-0__1q-j2 {
  border-left: 0 !important;
}

.WalletTokens_border-primary__EGlQE {
  border-color: #a4ff66 !important;
}

.WalletTokens_border-secondary__qBPto {
  border-color: #6c757d !important;
}

.WalletTokens_border-success__2NTSX {
  border-color: #28a745 !important;
}

.WalletTokens_border-info__2-jVm {
  border-color: #17a2b8 !important;
}

.WalletTokens_border-warning__2WGn1 {
  border-color: #f7b500 !important;
}

.WalletTokens_border-danger__PSdtV {
  border-color: #dc3545 !important;
}

.WalletTokens_border-light__23gYe {
  border-color: #f8f9fa !important;
}

.WalletTokens_border-dark__2EhgL {
  border-color: #0f1a1c !important;
}

.WalletTokens_border-dark-1__1yxEU {
  border-color: #303032 !important;
}

.WalletTokens_border-dark-2__qBimS {
  border-color: #383838 !important;
}

.WalletTokens_border-blue-dark__1Ly8u {
  border-color: #141722 !important;
}

.WalletTokens_border-white__1Rzfi {
  border-color: #fff !important;
}

.WalletTokens_border-0__-l9pK {
  border-width: 0 !important;
}

.WalletTokens_border-1__3PLtN {
  border-width: 1px !important;
}

.WalletTokens_border-2__F6DMT {
  border-width: 2px !important;
}

.WalletTokens_border-3__2UvtT {
  border-width: 3px !important;
}

.WalletTokens_border-4__rwV_P {
  border-width: 4px !important;
}

.WalletTokens_border-5__1QfOP {
  border-width: 5px !important;
}

.WalletTokens_w-25__3fxjY {
  width: 25% !important;
}

.WalletTokens_w-50__3Ny35 {
  width: 50% !important;
}

.WalletTokens_w-75__2rH37 {
  width: 75% !important;
}

.WalletTokens_w-100__n6KXA {
  width: 100% !important;
}

.WalletTokens_w-auto__sI3cq {
  width: auto !important;
}

.WalletTokens_mw-100__32Yp4 {
  max-width: 100% !important;
}

.WalletTokens_vw-100__3iHCO {
  width: 100vw !important;
}

.WalletTokens_min-vw-100__X00BF {
  min-width: 100vw !important;
}

.WalletTokens_h-25__cfeHV {
  height: 25% !important;
}

.WalletTokens_h-50__32aj9 {
  height: 50% !important;
}

.WalletTokens_h-75__33Gcz {
  height: 75% !important;
}

.WalletTokens_h-100__Jr7v5 {
  height: 100% !important;
}

.WalletTokens_h-auto__1m4vL {
  height: auto !important;
}

.WalletTokens_mh-100__1kmip {
  max-height: 100% !important;
}

.WalletTokens_vh-100__2wlKD {
  height: 100vh !important;
}

.WalletTokens_min-vh-100__1QTK1 {
  min-height: 100vh !important;
}

.WalletTokens_flex-fill__10VpK {
  flex: 1 1 auto !important;
}

.WalletTokens_flex-row__1C4DL {
  flex-direction: row !important;
}

.WalletTokens_flex-column__2lMJ5 {
  flex-direction: column !important;
}

.WalletTokens_flex-row-reverse__uuNFo {
  flex-direction: row-reverse !important;
}

.WalletTokens_flex-column-reverse__2VAKl {
  flex-direction: column-reverse !important;
}

.WalletTokens_flex-grow-0__1XHUm {
  flex-grow: 0 !important;
}

.WalletTokens_flex-grow-1__3eF7N {
  flex-grow: 1 !important;
}

.WalletTokens_flex-shrink-0__31yTg {
  flex-shrink: 0 !important;
}

.WalletTokens_flex-shrink-1__3_MZR {
  flex-shrink: 1 !important;
}

.WalletTokens_flex-wrap__1L_4Q {
  flex-wrap: wrap !important;
}

.WalletTokens_flex-nowrap__1HkH8 {
  flex-wrap: nowrap !important;
}

.WalletTokens_flex-wrap-reverse__33yMy {
  flex-wrap: wrap-reverse !important;
}

.WalletTokens_gap-0__JBi9I {
  grid-gap: 0 !important;
  gap: 0 !important;
}

.WalletTokens_gap-1__bcXIU {
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important;
}

.WalletTokens_gap-2__3kL5Q {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.WalletTokens_gap-3__NQkow {
  grid-gap: 1rem !important;
  gap: 1rem !important;
}

.WalletTokens_gap-4__2wJDY {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
}

.WalletTokens_gap-5__txCRT {
  grid-gap: 3rem !important;
  gap: 3rem !important;
}

.WalletTokens_justify-content-start__3bj3g {
  justify-content: flex-start !important;
}

.WalletTokens_justify-content-end__2V-GE {
  justify-content: flex-end !important;
}

.WalletTokens_justify-content-center__2oy2y {
  justify-content: center !important;
}

.WalletTokens_justify-content-between__2ZtdJ {
  justify-content: space-between !important;
}

.WalletTokens_justify-content-around__180qe {
  justify-content: space-around !important;
}

.WalletTokens_justify-content-evenly__2Y-6c {
  justify-content: space-evenly !important;
}

.WalletTokens_align-items-start__vqJ8j {
  align-items: flex-start !important;
}

.WalletTokens_align-items-end__2sR-N {
  align-items: flex-end !important;
}

.WalletTokens_align-items-center__3sQ87 {
  align-items: center !important;
}

.WalletTokens_align-items-baseline__2Ebif {
  align-items: baseline !important;
}

.WalletTokens_align-items-stretch__2lFoK {
  align-items: stretch !important;
}

.WalletTokens_align-content-start__3WHry {
  align-content: flex-start !important;
}

.WalletTokens_align-content-end__2JR_Q {
  align-content: flex-end !important;
}

.WalletTokens_align-content-center__1rGz0 {
  align-content: center !important;
}

.WalletTokens_align-content-between__G3a-U {
  align-content: space-between !important;
}

.WalletTokens_align-content-around__2Op7x {
  align-content: space-around !important;
}

.WalletTokens_align-content-stretch__2WJOc {
  align-content: stretch !important;
}

.WalletTokens_align-self-auto__14gvo {
  align-self: auto !important;
}

.WalletTokens_align-self-start__2hiWZ {
  align-self: flex-start !important;
}

.WalletTokens_align-self-end__nsBUv {
  align-self: flex-end !important;
}

.WalletTokens_align-self-center__JFGy- {
  align-self: center !important;
}

.WalletTokens_align-self-baseline__3sQU0 {
  align-self: baseline !important;
}

.WalletTokens_align-self-stretch__3dL3I {
  align-self: stretch !important;
}

.WalletTokens_order-first__1DsPC {
  order: -1 !important;
}

.WalletTokens_order-0__3Q3ne {
  order: 0 !important;
}

.WalletTokens_order-1__1OBRm {
  order: 1 !important;
}

.WalletTokens_order-2__3ULG1 {
  order: 2 !important;
}

.WalletTokens_order-3__2X7bC {
  order: 3 !important;
}

.WalletTokens_order-4__16zwQ {
  order: 4 !important;
}

.WalletTokens_order-5__1wTVX {
  order: 5 !important;
}

.WalletTokens_order-last__3cMzO {
  order: 6 !important;
}

.WalletTokens_m-0__1loWC {
  margin: 0 !important;
}

.WalletTokens_m-1__e2mZC {
  margin: 0.25rem !important;
}

.WalletTokens_m-2__3QjCI {
  margin: 0.5rem !important;
}

.WalletTokens_m-3__3aueI {
  margin: 1rem !important;
}

.WalletTokens_m-4__2BQTF {
  margin: 1.5rem !important;
}

.WalletTokens_m-5__1Farh {
  margin: 3rem !important;
}

.WalletTokens_m-auto__34MwG {
  margin: auto !important;
}

.WalletTokens_mx-0__2FoNe {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.WalletTokens_mx-1__xYrYS {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.WalletTokens_mx-2__1QTWr {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.WalletTokens_mx-3__2cK6J {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.WalletTokens_mx-4__mwDY9 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.WalletTokens_mx-5__1o559 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.WalletTokens_mx-auto__1u-G1 {
  margin-right: auto !important;
  margin-left: auto !important;
}

.WalletTokens_my-0__1N42u {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.WalletTokens_my-1__1QPNw {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.WalletTokens_my-2__3O0IX {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.WalletTokens_my-3__JTt10 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.WalletTokens_my-4__2WrHi {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.WalletTokens_my-5__2TZu5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.WalletTokens_my-auto__1s7LF {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.WalletTokens_mt-0__297TB {
  margin-top: 0 !important;
}

.WalletTokens_mt-1__1ivOr {
  margin-top: 0.25rem !important;
}

.WalletTokens_mt-2__2bMHO {
  margin-top: 0.5rem !important;
}

.WalletTokens_mt-3__1dp2z {
  margin-top: 1rem !important;
}

.WalletTokens_mt-4__2bpjF {
  margin-top: 1.5rem !important;
}

.WalletTokens_mt-5__3Xp8f {
  margin-top: 3rem !important;
}

.WalletTokens_mt-auto__3iN3K {
  margin-top: auto !important;
}

.WalletTokens_me-0__3ViMH {
  margin-right: 0 !important;
}

.WalletTokens_me-1__2-YS1 {
  margin-right: 0.25rem !important;
}

.WalletTokens_me-2__3_6Ze {
  margin-right: 0.5rem !important;
}

.WalletTokens_me-3__2xL3k {
  margin-right: 1rem !important;
}

.WalletTokens_me-4__25DQe {
  margin-right: 1.5rem !important;
}

.WalletTokens_me-5__369DB {
  margin-right: 3rem !important;
}

.WalletTokens_me-auto__ekmhF {
  margin-right: auto !important;
}

.WalletTokens_mb-0__3i9Rx {
  margin-bottom: 0 !important;
}

.WalletTokens_mb-1__2uA7i {
  margin-bottom: 0.25rem !important;
}

.WalletTokens_mb-2__3bnSt {
  margin-bottom: 0.5rem !important;
}

.WalletTokens_mb-3__3YBAs {
  margin-bottom: 1rem !important;
}

.WalletTokens_mb-4__2SvmZ {
  margin-bottom: 1.5rem !important;
}

.WalletTokens_mb-5__2Xtux {
  margin-bottom: 3rem !important;
}

.WalletTokens_mb-auto__3IcdN {
  margin-bottom: auto !important;
}

.WalletTokens_ms-0__1bDCP {
  margin-left: 0 !important;
}

.WalletTokens_ms-1__3Edwb {
  margin-left: 0.25rem !important;
}

.WalletTokens_ms-2__1GIZV {
  margin-left: 0.5rem !important;
}

.WalletTokens_ms-3__iWTEs {
  margin-left: 1rem !important;
}

.WalletTokens_ms-4__8LN-h {
  margin-left: 1.5rem !important;
}

.WalletTokens_ms-5__2nmUV {
  margin-left: 3rem !important;
}

.WalletTokens_ms-auto__zhBjA {
  margin-left: auto !important;
}

.WalletTokens_p-0__1znjl {
  padding: 0 !important;
}

.WalletTokens_p-1__1poq- {
  padding: 0.25rem !important;
}

.WalletTokens_p-2__1g208 {
  padding: 0.5rem !important;
}

.WalletTokens_p-3__2BiQV {
  padding: 1rem !important;
}

.WalletTokens_p-4__3hMwu {
  padding: 1.5rem !important;
}

.WalletTokens_p-5__HqAFF {
  padding: 3rem !important;
}

.WalletTokens_px-0__6wUr4 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.WalletTokens_px-1__1hzbg {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.WalletTokens_px-2__2TM7n {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.WalletTokens_px-3__3dKem {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.WalletTokens_px-4__31jsC {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.WalletTokens_px-5__Y_VHe {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.WalletTokens_py-0__135Mr {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.WalletTokens_py-1__2sbKr {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.WalletTokens_py-2__BEld3 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.WalletTokens_py-3__34cC6 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.WalletTokens_py-4__31y_6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.WalletTokens_py-5__dWyW_ {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.WalletTokens_pt-0__1yriB {
  padding-top: 0 !important;
}

.WalletTokens_pt-1__1Z6bI {
  padding-top: 0.25rem !important;
}

.WalletTokens_pt-2__1ZXdj {
  padding-top: 0.5rem !important;
}

.WalletTokens_pt-3__1Hf_C {
  padding-top: 1rem !important;
}

.WalletTokens_pt-4__2FyP2 {
  padding-top: 1.5rem !important;
}

.WalletTokens_pt-5__2guR8 {
  padding-top: 3rem !important;
}

.WalletTokens_pe-0__1bs4M {
  padding-right: 0 !important;
}

.WalletTokens_pe-1__hx-Lw {
  padding-right: 0.25rem !important;
}

.WalletTokens_pe-2__VwIYu {
  padding-right: 0.5rem !important;
}

.WalletTokens_pe-3__1UrEr {
  padding-right: 1rem !important;
}

.WalletTokens_pe-4__39rlK {
  padding-right: 1.5rem !important;
}

.WalletTokens_pe-5__2dxhd {
  padding-right: 3rem !important;
}

.WalletTokens_pb-0__3r1eZ {
  padding-bottom: 0 !important;
}

.WalletTokens_pb-1__1ZPDL {
  padding-bottom: 0.25rem !important;
}

.WalletTokens_pb-2__1mXgU {
  padding-bottom: 0.5rem !important;
}

.WalletTokens_pb-3__2FxDT {
  padding-bottom: 1rem !important;
}

.WalletTokens_pb-4__3J8z5 {
  padding-bottom: 1.5rem !important;
}

.WalletTokens_pb-5__2mQ2L {
  padding-bottom: 3rem !important;
}

.WalletTokens_ps-0__lfIlt {
  padding-left: 0 !important;
}

.WalletTokens_ps-1__1ccVv {
  padding-left: 0.25rem !important;
}

.WalletTokens_ps-2__2gqTk {
  padding-left: 0.5rem !important;
}

.WalletTokens_ps-3__RgY9Z {
  padding-left: 1rem !important;
}

.WalletTokens_ps-4__1fkVU {
  padding-left: 1.5rem !important;
}

.WalletTokens_ps-5__gBOkv {
  padding-left: 3rem !important;
}

.WalletTokens_fs-1__1TVXJ {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.WalletTokens_fs-2__2G5QB {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.WalletTokens_fs-3__3AKx1 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.WalletTokens_fs-4__34XrO {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.WalletTokens_fs-5__3Vd6i {
  font-size: 1.25rem !important;
}

.WalletTokens_fs-6__2wfXI {
  font-size: 1rem !important;
}

.WalletTokens_fst-italic__2ckY8 {
  font-style: italic !important;
}

.WalletTokens_fst-normal__1cA01 {
  font-style: normal !important;
}

.WalletTokens_fw-light__-SLub {
  font-weight: 300 !important;
}

.WalletTokens_fw-lighter__66r7K {
  font-weight: lighter !important;
}

.WalletTokens_fw-normal__3W16a {
  font-weight: 400 !important;
}

.WalletTokens_fw-bold__3G_2i {
  font-weight: 700 !important;
}

.WalletTokens_fw-bolder__38efv {
  font-weight: bolder !important;
}

.WalletTokens_text-lowercase__33sVC {
  text-transform: lowercase !important;
}

.WalletTokens_text-uppercase__29nm5 {
  text-transform: uppercase !important;
}

.WalletTokens_text-capitalize__20MiO {
  text-transform: capitalize !important;
}

.WalletTokens_text-start__11rzD {
  text-align: left !important;
}

.WalletTokens_text-end__2qoF- {
  text-align: right !important;
}

.WalletTokens_text-center__5KbSp {
  text-align: center !important;
}

.WalletTokens_text-primary__1iCW0 {
  color: #a4ff66 !important;
}

.WalletTokens_text-secondary__2mq4W {
  color: #6c757d !important;
}

.WalletTokens_text-success__1rHc3 {
  color: #28a745 !important;
}

.WalletTokens_text-info__a1r6U {
  color: #17a2b8 !important;
}

.WalletTokens_text-warning__1sdq3 {
  color: #f7b500 !important;
}

.WalletTokens_text-danger__1AgFr {
  color: #dc3545 !important;
}

.WalletTokens_text-light__2Nhlx {
  color: #f8f9fa !important;
}

.WalletTokens_text-dark__2TNFM {
  color: #0f1a1c !important;
}

.WalletTokens_text-dark-1__1yDwI {
  color: #303032 !important;
}

.WalletTokens_text-dark-2__1_xRi {
  color: #383838 !important;
}

.WalletTokens_text-blue-dark__lMZO3 {
  color: #141722 !important;
}

.WalletTokens_text-white__2MLgw {
  color: #fff !important;
}

.WalletTokens_text-body__351u2 {
  color: #f8f9fa !important;
}

.WalletTokens_text-muted__1Vzpw {
  color: #adb5bd !important;
}

.WalletTokens_text-black-50__2cNBh {
  color: rgba(0, 0, 0, 0.5) !important;
}

.WalletTokens_text-white-50__874I0 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}

.WalletTokens_text-reset__25xBI {
  color: inherit !important;
}

.WalletTokens_lh-1__Aeiig {
  line-height: 1 !important;
}

.WalletTokens_lh-sm__33wKP {
  line-height: 1.25 !important;
}

.WalletTokens_lh-base__1neY7 {
  line-height: 1.5 !important;
}

.WalletTokens_lh-lg__1vLD8 {
  line-height: 2 !important;
}

.WalletTokens_bg-primary__3FCq9 {
  background-color: #a4ff66 !important;
}

.WalletTokens_bg-secondary__2E4mZ {
  background-color: #6c757d !important;
}

.WalletTokens_bg-success__3HbB2 {
  background-color: #28a745 !important;
}

.WalletTokens_bg-info__IY8Ld {
  background-color: #17a2b8 !important;
}

.WalletTokens_bg-warning__2kNXZ {
  background-color: #f7b500 !important;
}

.WalletTokens_bg-danger__2gVBe {
  background-color: #dc3545 !important;
}

.WalletTokens_bg-light__-ZHlA {
  background-color: #f8f9fa !important;
}

.WalletTokens_bg-dark__XifJQ {
  background-color: #0f1a1c !important;
}

.WalletTokens_bg-dark-1__2lYyx {
  background-color: #303032 !important;
}

.WalletTokens_bg-dark-2__3b_uz {
  background-color: #383838 !important;
}

.WalletTokens_bg-blue-dark__1MX1d {
  background-color: #141722 !important;
}

.WalletTokens_bg-body__Wkomz {
  background-color: #141414 !important;
}

.WalletTokens_bg-white__2aMkR {
  background-color: #fff !important;
}

.WalletTokens_bg-transparent__3msl6 {
  background-color: transparent !important;
}

.WalletTokens_bg-gradient__22qC7 {
  background-image: var(--bs-gradient) !important;
}

.WalletTokens_text-wrap__1Yyu7 {
  white-space: normal !important;
}

.WalletTokens_text-nowrap__1E6n7 {
  white-space: nowrap !important;
}

.WalletTokens_text-decoration-none__3a1iB {
  text-decoration: none !important;
}

.WalletTokens_text-decoration-underline__sAcX9 {
  text-decoration: underline !important;
}

.WalletTokens_text-decoration-line-through__3_o1y {
  text-decoration: line-through !important;
}

.WalletTokens_text-break__3YaaR {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.WalletTokens_font-monospace__2RGve {
  /* font-family: var(--bs-font-monospace) !important */
}

.WalletTokens_user-select-all__1mOQn {
  -webkit-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.WalletTokens_user-select-auto__1jr10 {
  -webkit-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.WalletTokens_user-select-none__2UtvI {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.WalletTokens_pe-none__aL4Te {
  pointer-events: none !important;
}

.WalletTokens_pe-auto__1QfZO {
  pointer-events: auto !important;
}

.WalletTokens_rounded__1HY9r {
  border-radius: 0.25rem !important;
}

.WalletTokens_rounded-0__18L4V {
  border-radius: 0 !important;
}

.WalletTokens_rounded-1__2tmkt {
  border-radius: 0.2rem !important;
}

.WalletTokens_rounded-2__28hRK {
  border-radius: 0.25rem !important;
}

.WalletTokens_rounded-3__3r3NI {
  border-radius: 0.3rem !important;
}

.WalletTokens_rounded-circle__3FWYS {
  border-radius: 50% !important;
}

.WalletTokens_rounded-pill__3axsa {
  border-radius: 50rem !important;
}

.WalletTokens_rounded-top__18BZV {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.WalletTokens_rounded-end__C6bbw {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.WalletTokens_rounded-bottom__1_Mtb {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.WalletTokens_rounded-start__1ZORX {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.WalletTokens_visible__2y1lY {
  visibility: visible !important;
}

.WalletTokens_invisible__3douy {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .WalletTokens_float-sm-start__13ldt {
    float: left !important;
  }

  .WalletTokens_float-sm-end__2S-SA {
    float: right !important;
  }

  .WalletTokens_float-sm-none__59_Ym {
    float: none !important;
  }

  .WalletTokens_d-sm-inline__3VgjL {
    display: inline !important;
  }

  .WalletTokens_d-sm-inline-block__2Hjp0 {
    display: inline-block !important;
  }

  .WalletTokens_d-sm-block__13a5v {
    display: block !important;
  }

  .WalletTokens_d-sm-grid__o0haz {
    display: grid !important;
  }

  .WalletTokens_d-sm-table__UNZaG {
    display: table !important;
  }

  .WalletTokens_d-sm-table-row__2TAX7 {
    display: table-row !important;
  }

  .WalletTokens_d-sm-table-cell__1fbuP {
    display: table-cell !important;
  }

  .WalletTokens_d-sm-flex__WS1Rp {
    display: flex !important;
  }

  .WalletTokens_d-sm-inline-flex__Cwa8T {
    display: inline-flex !important;
  }

  .WalletTokens_d-sm-none__3zquz {
    display: none !important;
  }

  .WalletTokens_flex-sm-fill__1S2aN {
    flex: 1 1 auto !important;
  }

  .WalletTokens_flex-sm-row__3BJj8 {
    flex-direction: row !important;
  }

  .WalletTokens_flex-sm-column__2vZLC {
    flex-direction: column !important;
  }

  .WalletTokens_flex-sm-row-reverse__1S078 {
    flex-direction: row-reverse !important;
  }

  .WalletTokens_flex-sm-column-reverse__1pP1W {
    flex-direction: column-reverse !important;
  }

  .WalletTokens_flex-sm-grow-0__1odzV {
    flex-grow: 0 !important;
  }

  .WalletTokens_flex-sm-grow-1__30DmX {
    flex-grow: 1 !important;
  }

  .WalletTokens_flex-sm-shrink-0__3GsD_ {
    flex-shrink: 0 !important;
  }

  .WalletTokens_flex-sm-shrink-1__1Sv5- {
    flex-shrink: 1 !important;
  }

  .WalletTokens_flex-sm-wrap__3_Zle {
    flex-wrap: wrap !important;
  }

  .WalletTokens_flex-sm-nowrap__1mPwW {
    flex-wrap: nowrap !important;
  }

  .WalletTokens_flex-sm-wrap-reverse__205vr {
    flex-wrap: wrap-reverse !important;
  }

  .WalletTokens_gap-sm-0__x-zo5 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .WalletTokens_gap-sm-1__2c6Ao {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .WalletTokens_gap-sm-2__3q0yj {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .WalletTokens_gap-sm-3__1cEfe {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .WalletTokens_gap-sm-4__1PEyq {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .WalletTokens_gap-sm-5__2bCrW {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .WalletTokens_justify-content-sm-start__39gTV {
    justify-content: flex-start !important;
  }

  .WalletTokens_justify-content-sm-end__3sZg3 {
    justify-content: flex-end !important;
  }

  .WalletTokens_justify-content-sm-center__3V9a- {
    justify-content: center !important;
  }

  .WalletTokens_justify-content-sm-between__1Jfhy {
    justify-content: space-between !important;
  }

  .WalletTokens_justify-content-sm-around__2Qf93 {
    justify-content: space-around !important;
  }

  .WalletTokens_justify-content-sm-evenly__1zz0W {
    justify-content: space-evenly !important;
  }

  .WalletTokens_align-items-sm-start__2fSGI {
    align-items: flex-start !important;
  }

  .WalletTokens_align-items-sm-end__1z7hZ {
    align-items: flex-end !important;
  }

  .WalletTokens_align-items-sm-center__2-4DK {
    align-items: center !important;
  }

  .WalletTokens_align-items-sm-baseline__txow8 {
    align-items: baseline !important;
  }

  .WalletTokens_align-items-sm-stretch__2WCNo {
    align-items: stretch !important;
  }

  .WalletTokens_align-content-sm-start__iB1c3 {
    align-content: flex-start !important;
  }

  .WalletTokens_align-content-sm-end__1nwRv {
    align-content: flex-end !important;
  }

  .WalletTokens_align-content-sm-center__36ubh {
    align-content: center !important;
  }

  .WalletTokens_align-content-sm-between__3vQfI {
    align-content: space-between !important;
  }

  .WalletTokens_align-content-sm-around__15n_7 {
    align-content: space-around !important;
  }

  .WalletTokens_align-content-sm-stretch__36DuA {
    align-content: stretch !important;
  }

  .WalletTokens_align-self-sm-auto__1xs8C {
    align-self: auto !important;
  }

  .WalletTokens_align-self-sm-start__zfoMB {
    align-self: flex-start !important;
  }

  .WalletTokens_align-self-sm-end__3tGjn {
    align-self: flex-end !important;
  }

  .WalletTokens_align-self-sm-center__3uCMP {
    align-self: center !important;
  }

  .WalletTokens_align-self-sm-baseline__1hDPA {
    align-self: baseline !important;
  }

  .WalletTokens_align-self-sm-stretch__2MqtC {
    align-self: stretch !important;
  }

  .WalletTokens_order-sm-first__3bAYp {
    order: -1 !important;
  }

  .WalletTokens_order-sm-0__3bo2z {
    order: 0 !important;
  }

  .WalletTokens_order-sm-1__37g_J {
    order: 1 !important;
  }

  .WalletTokens_order-sm-2___FloV {
    order: 2 !important;
  }

  .WalletTokens_order-sm-3__3MCCw {
    order: 3 !important;
  }

  .WalletTokens_order-sm-4__17aMx {
    order: 4 !important;
  }

  .WalletTokens_order-sm-5__qNPoi {
    order: 5 !important;
  }

  .WalletTokens_order-sm-last___P7VI {
    order: 6 !important;
  }

  .WalletTokens_m-sm-0__iI6AD {
    margin: 0 !important;
  }

  .WalletTokens_m-sm-1__1csA2 {
    margin: 0.25rem !important;
  }

  .WalletTokens_m-sm-2__29dvm {
    margin: 0.5rem !important;
  }

  .WalletTokens_m-sm-3__3yyq7 {
    margin: 1rem !important;
  }

  .WalletTokens_m-sm-4__3gpDx {
    margin: 1.5rem !important;
  }

  .WalletTokens_m-sm-5___b6Sn {
    margin: 3rem !important;
  }

  .WalletTokens_m-sm-auto__BaflV {
    margin: auto !important;
  }

  .WalletTokens_mx-sm-0__1kHfe {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .WalletTokens_mx-sm-1__wCYqs {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .WalletTokens_mx-sm-2__31Iat {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .WalletTokens_mx-sm-3__RgoOc {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .WalletTokens_mx-sm-4__1NXSU {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .WalletTokens_mx-sm-5__1FtVi {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .WalletTokens_mx-sm-auto__117I9 {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .WalletTokens_my-sm-0__33qix {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .WalletTokens_my-sm-1__17KG0 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .WalletTokens_my-sm-2__2QV29 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .WalletTokens_my-sm-3__1glLY {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .WalletTokens_my-sm-4__3i-E9 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .WalletTokens_my-sm-5__1StZz {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .WalletTokens_my-sm-auto__B8EbA {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .WalletTokens_mt-sm-0__1kxOp {
    margin-top: 0 !important;
  }

  .WalletTokens_mt-sm-1__2jsSP {
    margin-top: 0.25rem !important;
  }

  .WalletTokens_mt-sm-2__3OQsK {
    margin-top: 0.5rem !important;
  }

  .WalletTokens_mt-sm-3__1EZY3 {
    margin-top: 1rem !important;
  }

  .WalletTokens_mt-sm-4__pDiRT {
    margin-top: 1.5rem !important;
  }

  .WalletTokens_mt-sm-5__1Imme {
    margin-top: 3rem !important;
  }

  .WalletTokens_mt-sm-auto__1ehLj {
    margin-top: auto !important;
  }

  .WalletTokens_me-sm-0__1I512 {
    margin-right: 0 !important;
  }

  .WalletTokens_me-sm-1__3XVqC {
    margin-right: 0.25rem !important;
  }

  .WalletTokens_me-sm-2__3FYD_ {
    margin-right: 0.5rem !important;
  }

  .WalletTokens_me-sm-3__ovqzh {
    margin-right: 1rem !important;
  }

  .WalletTokens_me-sm-4__1mnws {
    margin-right: 1.5rem !important;
  }

  .WalletTokens_me-sm-5__1bjRa {
    margin-right: 3rem !important;
  }

  .WalletTokens_me-sm-auto__3J7y1 {
    margin-right: auto !important;
  }

  .WalletTokens_mb-sm-0__1UYxO {
    margin-bottom: 0 !important;
  }

  .WalletTokens_mb-sm-1__3OZKO {
    margin-bottom: 0.25rem !important;
  }

  .WalletTokens_mb-sm-2__3oPN5 {
    margin-bottom: 0.5rem !important;
  }

  .WalletTokens_mb-sm-3__1mhUB {
    margin-bottom: 1rem !important;
  }

  .WalletTokens_mb-sm-4__nZrMc {
    margin-bottom: 1.5rem !important;
  }

  .WalletTokens_mb-sm-5__aNIlh {
    margin-bottom: 3rem !important;
  }

  .WalletTokens_mb-sm-auto__1Ym6I {
    margin-bottom: auto !important;
  }

  .WalletTokens_ms-sm-0__TL_Uv {
    margin-left: 0 !important;
  }

  .WalletTokens_ms-sm-1__2KnGa {
    margin-left: 0.25rem !important;
  }

  .WalletTokens_ms-sm-2__oOFLt {
    margin-left: 0.5rem !important;
  }

  .WalletTokens_ms-sm-3__E2OZP {
    margin-left: 1rem !important;
  }

  .WalletTokens_ms-sm-4__3cSiz {
    margin-left: 1.5rem !important;
  }

  .WalletTokens_ms-sm-5__1CR6i {
    margin-left: 3rem !important;
  }

  .WalletTokens_ms-sm-auto__1nTQa {
    margin-left: auto !important;
  }

  .WalletTokens_p-sm-0__YXtef {
    padding: 0 !important;
  }

  .WalletTokens_p-sm-1__1nZ5e {
    padding: 0.25rem !important;
  }

  .WalletTokens_p-sm-2__2lr-B {
    padding: 0.5rem !important;
  }

  .WalletTokens_p-sm-3__z2WMs {
    padding: 1rem !important;
  }

  .WalletTokens_p-sm-4__1av5d {
    padding: 1.5rem !important;
  }

  .WalletTokens_p-sm-5__l5mIf {
    padding: 3rem !important;
  }

  .WalletTokens_px-sm-0__3AZ8h {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .WalletTokens_px-sm-1__1I6b8 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .WalletTokens_px-sm-2__--6Ol {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .WalletTokens_px-sm-3__1VhxL {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .WalletTokens_px-sm-4__3S-QU {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .WalletTokens_px-sm-5__2RaRF {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .WalletTokens_py-sm-0__azNmg {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .WalletTokens_py-sm-1__E6_Qy {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .WalletTokens_py-sm-2__38Vq0 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .WalletTokens_py-sm-3__2TM0J {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .WalletTokens_py-sm-4__1qg4p {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .WalletTokens_py-sm-5__3YfQH {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .WalletTokens_pt-sm-0__3ffYX {
    padding-top: 0 !important;
  }

  .WalletTokens_pt-sm-1__iGAfT {
    padding-top: 0.25rem !important;
  }

  .WalletTokens_pt-sm-2__1RYEB {
    padding-top: 0.5rem !important;
  }

  .WalletTokens_pt-sm-3__cuVru {
    padding-top: 1rem !important;
  }

  .WalletTokens_pt-sm-4__259fg {
    padding-top: 1.5rem !important;
  }

  .WalletTokens_pt-sm-5__2tILN {
    padding-top: 3rem !important;
  }

  .WalletTokens_pe-sm-0__22Ft7 {
    padding-right: 0 !important;
  }

  .WalletTokens_pe-sm-1__wIZMq {
    padding-right: 0.25rem !important;
  }

  .WalletTokens_pe-sm-2__2wDi_ {
    padding-right: 0.5rem !important;
  }

  .WalletTokens_pe-sm-3__zD9yO {
    padding-right: 1rem !important;
  }

  .WalletTokens_pe-sm-4__HOVye {
    padding-right: 1.5rem !important;
  }

  .WalletTokens_pe-sm-5__1afPp {
    padding-right: 3rem !important;
  }

  .WalletTokens_pb-sm-0__2Wjyd {
    padding-bottom: 0 !important;
  }

  .WalletTokens_pb-sm-1__3xSXl {
    padding-bottom: 0.25rem !important;
  }

  .WalletTokens_pb-sm-2__3QBbI {
    padding-bottom: 0.5rem !important;
  }

  .WalletTokens_pb-sm-3__2YzT- {
    padding-bottom: 1rem !important;
  }

  .WalletTokens_pb-sm-4__1wsje {
    padding-bottom: 1.5rem !important;
  }

  .WalletTokens_pb-sm-5__1kUe- {
    padding-bottom: 3rem !important;
  }

  .WalletTokens_ps-sm-0__2QjJO {
    padding-left: 0 !important;
  }

  .WalletTokens_ps-sm-1__2SW7G {
    padding-left: 0.25rem !important;
  }

  .WalletTokens_ps-sm-2__3Kn5- {
    padding-left: 0.5rem !important;
  }

  .WalletTokens_ps-sm-3__36mz5 {
    padding-left: 1rem !important;
  }

  .WalletTokens_ps-sm-4__2OaT6 {
    padding-left: 1.5rem !important;
  }

  .WalletTokens_ps-sm-5__2qD4T {
    padding-left: 3rem !important;
  }

  .WalletTokens_text-sm-start__SI9o- {
    text-align: left !important;
  }

  .WalletTokens_text-sm-end__OGGSN {
    text-align: right !important;
  }

  .WalletTokens_text-sm-center__3aFzo {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .WalletTokens_float-md-start__ueafp {
    float: left !important;
  }

  .WalletTokens_float-md-end__ea02P {
    float: right !important;
  }

  .WalletTokens_float-md-none__3y41p {
    float: none !important;
  }

  .WalletTokens_d-md-inline__39afI {
    display: inline !important;
  }

  .WalletTokens_d-md-inline-block__1j6eM {
    display: inline-block !important;
  }

  .WalletTokens_d-md-block__2q5ae {
    display: block !important;
  }

  .WalletTokens_d-md-grid__3nVzN {
    display: grid !important;
  }

  .WalletTokens_d-md-table__3hw3Y {
    display: table !important;
  }

  .WalletTokens_d-md-table-row__1gYG5 {
    display: table-row !important;
  }

  .WalletTokens_d-md-table-cell__jU3XW {
    display: table-cell !important;
  }

  .WalletTokens_d-md-flex__1DVs1 {
    display: flex !important;
  }

  .WalletTokens_d-md-inline-flex__1JZ-R {
    display: inline-flex !important;
  }

  .WalletTokens_d-md-none__2vy0C {
    display: none !important;
  }

  .WalletTokens_flex-md-fill__255ea {
    flex: 1 1 auto !important;
  }

  .WalletTokens_flex-md-row__3u-4a {
    flex-direction: row !important;
  }

  .WalletTokens_flex-md-column__28ZJU {
    flex-direction: column !important;
  }

  .WalletTokens_flex-md-row-reverse__3asEC {
    flex-direction: row-reverse !important;
  }

  .WalletTokens_flex-md-column-reverse__2dsm3 {
    flex-direction: column-reverse !important;
  }

  .WalletTokens_flex-md-grow-0__2gl31 {
    flex-grow: 0 !important;
  }

  .WalletTokens_flex-md-grow-1__1nCEP {
    flex-grow: 1 !important;
  }

  .WalletTokens_flex-md-shrink-0__2R74m {
    flex-shrink: 0 !important;
  }

  .WalletTokens_flex-md-shrink-1__28iap {
    flex-shrink: 1 !important;
  }

  .WalletTokens_flex-md-wrap__13jol {
    flex-wrap: wrap !important;
  }

  .WalletTokens_flex-md-nowrap__18Pbi {
    flex-wrap: nowrap !important;
  }

  .WalletTokens_flex-md-wrap-reverse__2KUnt {
    flex-wrap: wrap-reverse !important;
  }

  .WalletTokens_gap-md-0__1LtdD {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .WalletTokens_gap-md-1__1fCa5 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .WalletTokens_gap-md-2__3Sx-H {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .WalletTokens_gap-md-3__3bs6U {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .WalletTokens_gap-md-4__3VARQ {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .WalletTokens_gap-md-5__36qxC {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .WalletTokens_justify-content-md-start__2aQIs {
    justify-content: flex-start !important;
  }

  .WalletTokens_justify-content-md-end__2PZTQ {
    justify-content: flex-end !important;
  }

  .WalletTokens_justify-content-md-center__1AehX {
    justify-content: center !important;
  }

  .WalletTokens_justify-content-md-between__vCVUw {
    justify-content: space-between !important;
  }

  .WalletTokens_justify-content-md-around__39-RT {
    justify-content: space-around !important;
  }

  .WalletTokens_justify-content-md-evenly__1qJOJ {
    justify-content: space-evenly !important;
  }

  .WalletTokens_align-items-md-start__jkLHF {
    align-items: flex-start !important;
  }

  .WalletTokens_align-items-md-end__20reY {
    align-items: flex-end !important;
  }

  .WalletTokens_align-items-md-center__a1hBH {
    align-items: center !important;
  }

  .WalletTokens_align-items-md-baseline__BL3en {
    align-items: baseline !important;
  }

  .WalletTokens_align-items-md-stretch__1Oof8 {
    align-items: stretch !important;
  }

  .WalletTokens_align-content-md-start__2Bh9y {
    align-content: flex-start !important;
  }

  .WalletTokens_align-content-md-end__1Lhac {
    align-content: flex-end !important;
  }

  .WalletTokens_align-content-md-center__20R0c {
    align-content: center !important;
  }

  .WalletTokens_align-content-md-between__3AWov {
    align-content: space-between !important;
  }

  .WalletTokens_align-content-md-around__36PFc {
    align-content: space-around !important;
  }

  .WalletTokens_align-content-md-stretch__1HgHf {
    align-content: stretch !important;
  }

  .WalletTokens_align-self-md-auto__1ArSB {
    align-self: auto !important;
  }

  .WalletTokens_align-self-md-start__-fYdF {
    align-self: flex-start !important;
  }

  .WalletTokens_align-self-md-end__SiiPn {
    align-self: flex-end !important;
  }

  .WalletTokens_align-self-md-center__25GGf {
    align-self: center !important;
  }

  .WalletTokens_align-self-md-baseline__BKnxj {
    align-self: baseline !important;
  }

  .WalletTokens_align-self-md-stretch__2T_01 {
    align-self: stretch !important;
  }

  .WalletTokens_order-md-first__saubG {
    order: -1 !important;
  }

  .WalletTokens_order-md-0__oyJeH {
    order: 0 !important;
  }

  .WalletTokens_order-md-1__11Kop {
    order: 1 !important;
  }

  .WalletTokens_order-md-2__Juq-X {
    order: 2 !important;
  }

  .WalletTokens_order-md-3__1yqjJ {
    order: 3 !important;
  }

  .WalletTokens_order-md-4__xRfjM {
    order: 4 !important;
  }

  .WalletTokens_order-md-5__1WfGX {
    order: 5 !important;
  }

  .WalletTokens_order-md-last__2IZ-9 {
    order: 6 !important;
  }

  .WalletTokens_m-md-0__21U0t {
    margin: 0 !important;
  }

  .WalletTokens_m-md-1__PNMSE {
    margin: 0.25rem !important;
  }

  .WalletTokens_m-md-2__3EZe3 {
    margin: 0.5rem !important;
  }

  .WalletTokens_m-md-3__dX2mK {
    margin: 1rem !important;
  }

  .WalletTokens_m-md-4__3X1BM {
    margin: 1.5rem !important;
  }

  .WalletTokens_m-md-5__2XO6J {
    margin: 3rem !important;
  }

  .WalletTokens_m-md-auto__2e6xx {
    margin: auto !important;
  }

  .WalletTokens_mx-md-0__3iEQe {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .WalletTokens_mx-md-1__32NFu {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .WalletTokens_mx-md-2__fum6l {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .WalletTokens_mx-md-3__qrwjH {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .WalletTokens_mx-md-4__34Vo9 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .WalletTokens_mx-md-5__1vD2x {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .WalletTokens_mx-md-auto__3H1Ky {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .WalletTokens_my-md-0__3sA6I {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .WalletTokens_my-md-1__3vSyb {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .WalletTokens_my-md-2__3Ltrr {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .WalletTokens_my-md-3__2K5mD {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .WalletTokens_my-md-4__2Ojff {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .WalletTokens_my-md-5__OX1pb {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .WalletTokens_my-md-auto__2wcuR {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .WalletTokens_mt-md-0__36Ny5 {
    margin-top: 0 !important;
  }

  .WalletTokens_mt-md-1__1UxBp {
    margin-top: 0.25rem !important;
  }

  .WalletTokens_mt-md-2__2cG2k {
    margin-top: 0.5rem !important;
  }

  .WalletTokens_mt-md-3__3u6KY {
    margin-top: 1rem !important;
  }

  .WalletTokens_mt-md-4__3v-BQ {
    margin-top: 1.5rem !important;
  }

  .WalletTokens_mt-md-5__2oePa {
    margin-top: 3rem !important;
  }

  .WalletTokens_mt-md-auto__2sPOz {
    margin-top: auto !important;
  }

  .WalletTokens_me-md-0__3npyN {
    margin-right: 0 !important;
  }

  .WalletTokens_me-md-1__36Lwe {
    margin-right: 0.25rem !important;
  }

  .WalletTokens_me-md-2__3KjAw {
    margin-right: 0.5rem !important;
  }

  .WalletTokens_me-md-3__SsmNH {
    margin-right: 1rem !important;
  }

  .WalletTokens_me-md-4__2aNzG {
    margin-right: 1.5rem !important;
  }

  .WalletTokens_me-md-5__2mrgs {
    margin-right: 3rem !important;
  }

  .WalletTokens_me-md-auto__2rnK7 {
    margin-right: auto !important;
  }

  .WalletTokens_mb-md-0__12Q_3 {
    margin-bottom: 0 !important;
  }

  .WalletTokens_mb-md-1__26hk7 {
    margin-bottom: 0.25rem !important;
  }

  .WalletTokens_mb-md-2__1EoaK {
    margin-bottom: 0.5rem !important;
  }

  .WalletTokens_mb-md-3__6Lp9G {
    margin-bottom: 1rem !important;
  }

  .WalletTokens_mb-md-4__2x9fz {
    margin-bottom: 1.5rem !important;
  }

  .WalletTokens_mb-md-5__1QeP0 {
    margin-bottom: 3rem !important;
  }

  .WalletTokens_mb-md-auto__1SNOH {
    margin-bottom: auto !important;
  }

  .WalletTokens_ms-md-0__1HyGZ {
    margin-left: 0 !important;
  }

  .WalletTokens_ms-md-1__37RLK {
    margin-left: 0.25rem !important;
  }

  .WalletTokens_ms-md-2__4V1o3 {
    margin-left: 0.5rem !important;
  }

  .WalletTokens_ms-md-3__2-7Uf {
    margin-left: 1rem !important;
  }

  .WalletTokens_ms-md-4__2X3qQ {
    margin-left: 1.5rem !important;
  }

  .WalletTokens_ms-md-5__2nQux {
    margin-left: 3rem !important;
  }

  .WalletTokens_ms-md-auto__wNGcV {
    margin-left: auto !important;
  }

  .WalletTokens_p-md-0__39KmA {
    padding: 0 !important;
  }

  .WalletTokens_p-md-1__DBD00 {
    padding: 0.25rem !important;
  }

  .WalletTokens_p-md-2__1hbIC {
    padding: 0.5rem !important;
  }

  .WalletTokens_p-md-3__OxhhR {
    padding: 1rem !important;
  }

  .WalletTokens_p-md-4__2uA17 {
    padding: 1.5rem !important;
  }

  .WalletTokens_p-md-5__3mmef {
    padding: 3rem !important;
  }

  .WalletTokens_px-md-0__10uJ- {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .WalletTokens_px-md-1__1S1E9 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .WalletTokens_px-md-2__1TPFr {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .WalletTokens_px-md-3__HyaCD {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .WalletTokens_px-md-4__3GCPU {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .WalletTokens_px-md-5__2qpzg {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .WalletTokens_py-md-0__3YziM {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .WalletTokens_py-md-1__3cEZg {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .WalletTokens_py-md-2__FwyPC {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .WalletTokens_py-md-3__1xAxw {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .WalletTokens_py-md-4__qh3un {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .WalletTokens_py-md-5__3MP-z {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .WalletTokens_pt-md-0__3RJBg {
    padding-top: 0 !important;
  }

  .WalletTokens_pt-md-1__3CTmP {
    padding-top: 0.25rem !important;
  }

  .WalletTokens_pt-md-2__1KE4M {
    padding-top: 0.5rem !important;
  }

  .WalletTokens_pt-md-3__2z9kp {
    padding-top: 1rem !important;
  }

  .WalletTokens_pt-md-4__31GDb {
    padding-top: 1.5rem !important;
  }

  .WalletTokens_pt-md-5__3aYv5 {
    padding-top: 3rem !important;
  }

  .WalletTokens_pe-md-0__7XWfe {
    padding-right: 0 !important;
  }

  .WalletTokens_pe-md-1__h6YF_ {
    padding-right: 0.25rem !important;
  }

  .WalletTokens_pe-md-2__35K37 {
    padding-right: 0.5rem !important;
  }

  .WalletTokens_pe-md-3__3n505 {
    padding-right: 1rem !important;
  }

  .WalletTokens_pe-md-4__tmalV {
    padding-right: 1.5rem !important;
  }

  .WalletTokens_pe-md-5__1fV-8 {
    padding-right: 3rem !important;
  }

  .WalletTokens_pb-md-0__cGCJX {
    padding-bottom: 0 !important;
  }

  .WalletTokens_pb-md-1__2sXUE {
    padding-bottom: 0.25rem !important;
  }

  .WalletTokens_pb-md-2__FdLsw {
    padding-bottom: 0.5rem !important;
  }

  .WalletTokens_pb-md-3__2YDnm {
    padding-bottom: 1rem !important;
  }

  .WalletTokens_pb-md-4__3vryG {
    padding-bottom: 1.5rem !important;
  }

  .WalletTokens_pb-md-5__J_BnW {
    padding-bottom: 3rem !important;
  }

  .WalletTokens_ps-md-0__3TAAk {
    padding-left: 0 !important;
  }

  .WalletTokens_ps-md-1__r4jfR {
    padding-left: 0.25rem !important;
  }

  .WalletTokens_ps-md-2__3Upqc {
    padding-left: 0.5rem !important;
  }

  .WalletTokens_ps-md-3__2zmOq {
    padding-left: 1rem !important;
  }

  .WalletTokens_ps-md-4__2wvjA {
    padding-left: 1.5rem !important;
  }

  .WalletTokens_ps-md-5__U4lUD {
    padding-left: 3rem !important;
  }

  .WalletTokens_text-md-start__eC_zT {
    text-align: left !important;
  }

  .WalletTokens_text-md-end__G6SN_ {
    text-align: right !important;
  }

  .WalletTokens_text-md-center__CmCDo {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .WalletTokens_float-lg-start__lpVcb {
    float: left !important;
  }

  .WalletTokens_float-lg-end__1qTA2 {
    float: right !important;
  }

  .WalletTokens_float-lg-none__16b0B {
    float: none !important;
  }

  .WalletTokens_d-lg-inline__2M8Mh {
    display: inline !important;
  }

  .WalletTokens_d-lg-inline-block__3msWG {
    display: inline-block !important;
  }

  .WalletTokens_d-lg-block__uaTNb {
    display: block !important;
  }

  .WalletTokens_d-lg-grid__3M4zF {
    display: grid !important;
  }

  .WalletTokens_d-lg-table__2XpVa {
    display: table !important;
  }

  .WalletTokens_d-lg-table-row__2cc0W {
    display: table-row !important;
  }

  .WalletTokens_d-lg-table-cell__2qxct {
    display: table-cell !important;
  }

  .WalletTokens_d-lg-flex__vPcnZ {
    display: flex !important;
  }

  .WalletTokens_d-lg-inline-flex__3nYxB {
    display: inline-flex !important;
  }

  .WalletTokens_d-lg-none__2gSHQ {
    display: none !important;
  }

  .WalletTokens_flex-lg-fill__3XW51 {
    flex: 1 1 auto !important;
  }

  .WalletTokens_flex-lg-row__2fqNq {
    flex-direction: row !important;
  }

  .WalletTokens_flex-lg-column__2ZtlI {
    flex-direction: column !important;
  }

  .WalletTokens_flex-lg-row-reverse__IP35B {
    flex-direction: row-reverse !important;
  }

  .WalletTokens_flex-lg-column-reverse__1ffpP {
    flex-direction: column-reverse !important;
  }

  .WalletTokens_flex-lg-grow-0__1x0JG {
    flex-grow: 0 !important;
  }

  .WalletTokens_flex-lg-grow-1__3p7ze {
    flex-grow: 1 !important;
  }

  .WalletTokens_flex-lg-shrink-0__2X2rI {
    flex-shrink: 0 !important;
  }

  .WalletTokens_flex-lg-shrink-1__12fAK {
    flex-shrink: 1 !important;
  }

  .WalletTokens_flex-lg-wrap__3MO-m {
    flex-wrap: wrap !important;
  }

  .WalletTokens_flex-lg-nowrap__33Xwl {
    flex-wrap: nowrap !important;
  }

  .WalletTokens_flex-lg-wrap-reverse__2lUTl {
    flex-wrap: wrap-reverse !important;
  }

  .WalletTokens_gap-lg-0__2BhiM {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .WalletTokens_gap-lg-1__nQWnp {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .WalletTokens_gap-lg-2__3MgpK {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .WalletTokens_gap-lg-3__17e8v {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .WalletTokens_gap-lg-4__mivdk {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .WalletTokens_gap-lg-5__2Onv7 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .WalletTokens_justify-content-lg-start__3Gblo {
    justify-content: flex-start !important;
  }

  .WalletTokens_justify-content-lg-end__2Jf0G {
    justify-content: flex-end !important;
  }

  .WalletTokens_justify-content-lg-center__31Zd2 {
    justify-content: center !important;
  }

  .WalletTokens_justify-content-lg-between__2sVkh {
    justify-content: space-between !important;
  }

  .WalletTokens_justify-content-lg-around__NgLkW {
    justify-content: space-around !important;
  }

  .WalletTokens_justify-content-lg-evenly__3kPv6 {
    justify-content: space-evenly !important;
  }

  .WalletTokens_align-items-lg-start__2Nq5X {
    align-items: flex-start !important;
  }

  .WalletTokens_align-items-lg-end__1WzLU {
    align-items: flex-end !important;
  }

  .WalletTokens_align-items-lg-center__nymbE {
    align-items: center !important;
  }

  .WalletTokens_align-items-lg-baseline__2igZr {
    align-items: baseline !important;
  }

  .WalletTokens_align-items-lg-stretch__3Q3NK {
    align-items: stretch !important;
  }

  .WalletTokens_align-content-lg-start__1EweE {
    align-content: flex-start !important;
  }

  .WalletTokens_align-content-lg-end__e94nc {
    align-content: flex-end !important;
  }

  .WalletTokens_align-content-lg-center__NkO22 {
    align-content: center !important;
  }

  .WalletTokens_align-content-lg-between__2e1US {
    align-content: space-between !important;
  }

  .WalletTokens_align-content-lg-around__1zufQ {
    align-content: space-around !important;
  }

  .WalletTokens_align-content-lg-stretch__2q4VV {
    align-content: stretch !important;
  }

  .WalletTokens_align-self-lg-auto__1s7Uq {
    align-self: auto !important;
  }

  .WalletTokens_align-self-lg-start__h-MAp {
    align-self: flex-start !important;
  }

  .WalletTokens_align-self-lg-end__2a4VL {
    align-self: flex-end !important;
  }

  .WalletTokens_align-self-lg-center__1l2R7 {
    align-self: center !important;
  }

  .WalletTokens_align-self-lg-baseline__2xX8D {
    align-self: baseline !important;
  }

  .WalletTokens_align-self-lg-stretch__1RIwV {
    align-self: stretch !important;
  }

  .WalletTokens_order-lg-first__1CE6k {
    order: -1 !important;
  }

  .WalletTokens_order-lg-0__1yUit {
    order: 0 !important;
  }

  .WalletTokens_order-lg-1__1w4oV {
    order: 1 !important;
  }

  .WalletTokens_order-lg-2__3RvXD {
    order: 2 !important;
  }

  .WalletTokens_order-lg-3__1OZAj {
    order: 3 !important;
  }

  .WalletTokens_order-lg-4__1tmYq {
    order: 4 !important;
  }

  .WalletTokens_order-lg-5__1z42c {
    order: 5 !important;
  }

  .WalletTokens_order-lg-last__wOs2A {
    order: 6 !important;
  }

  .WalletTokens_m-lg-0__3ehXf {
    margin: 0 !important;
  }

  .WalletTokens_m-lg-1__1zD9- {
    margin: 0.25rem !important;
  }

  .WalletTokens_m-lg-2__1xvmS {
    margin: 0.5rem !important;
  }

  .WalletTokens_m-lg-3__1A-En {
    margin: 1rem !important;
  }

  .WalletTokens_m-lg-4__2VVt2 {
    margin: 1.5rem !important;
  }

  .WalletTokens_m-lg-5__2Z8IF {
    margin: 3rem !important;
  }

  .WalletTokens_m-lg-auto__19-PW {
    margin: auto !important;
  }

  .WalletTokens_mx-lg-0__3rFki {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .WalletTokens_mx-lg-1__2bUlb {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .WalletTokens_mx-lg-2__3CM0n {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .WalletTokens_mx-lg-3__1DJ2C {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .WalletTokens_mx-lg-4__12vPV {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .WalletTokens_mx-lg-5__lp82n {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .WalletTokens_mx-lg-auto__l4Q3v {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .WalletTokens_my-lg-0__26eRo {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .WalletTokens_my-lg-1__3yiEp {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .WalletTokens_my-lg-2__1v6Pv {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .WalletTokens_my-lg-3__2R11q {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .WalletTokens_my-lg-4__3Ji3h {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .WalletTokens_my-lg-5__2WEF5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .WalletTokens_my-lg-auto__O1XVP {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .WalletTokens_mt-lg-0__13TnD {
    margin-top: 0 !important;
  }

  .WalletTokens_mt-lg-1__2UXU- {
    margin-top: 0.25rem !important;
  }

  .WalletTokens_mt-lg-2__1XyCr {
    margin-top: 0.5rem !important;
  }

  .WalletTokens_mt-lg-3___uJGG {
    margin-top: 1rem !important;
  }

  .WalletTokens_mt-lg-4__100vp {
    margin-top: 1.5rem !important;
  }

  .WalletTokens_mt-lg-5__2HeWR {
    margin-top: 3rem !important;
  }

  .WalletTokens_mt-lg-auto__dCHTi {
    margin-top: auto !important;
  }

  .WalletTokens_me-lg-0__3tN2S {
    margin-right: 0 !important;
  }

  .WalletTokens_me-lg-1__2vODT {
    margin-right: 0.25rem !important;
  }

  .WalletTokens_me-lg-2__1fCfi {
    margin-right: 0.5rem !important;
  }

  .WalletTokens_me-lg-3__1O3RV {
    margin-right: 1rem !important;
  }

  .WalletTokens_me-lg-4__3zxGw {
    margin-right: 1.5rem !important;
  }

  .WalletTokens_me-lg-5__1A5kI {
    margin-right: 3rem !important;
  }

  .WalletTokens_me-lg-auto__3fVEj {
    margin-right: auto !important;
  }

  .WalletTokens_mb-lg-0__Dl0kv {
    margin-bottom: 0 !important;
  }

  .WalletTokens_mb-lg-1__2Wyf8 {
    margin-bottom: 0.25rem !important;
  }

  .WalletTokens_mb-lg-2__3ocVL {
    margin-bottom: 0.5rem !important;
  }

  .WalletTokens_mb-lg-3__jZxaT {
    margin-bottom: 1rem !important;
  }

  .WalletTokens_mb-lg-4__1qVO4 {
    margin-bottom: 1.5rem !important;
  }

  .WalletTokens_mb-lg-5__2NFgY {
    margin-bottom: 3rem !important;
  }

  .WalletTokens_mb-lg-auto__3XbSD {
    margin-bottom: auto !important;
  }

  .WalletTokens_ms-lg-0__1WAxW {
    margin-left: 0 !important;
  }

  .WalletTokens_ms-lg-1___LGdR {
    margin-left: 0.25rem !important;
  }

  .WalletTokens_ms-lg-2__193Of {
    margin-left: 0.5rem !important;
  }

  .WalletTokens_ms-lg-3__3MCG5 {
    margin-left: 1rem !important;
  }

  .WalletTokens_ms-lg-4__23TFl {
    margin-left: 1.5rem !important;
  }

  .WalletTokens_ms-lg-5__3sinS {
    margin-left: 3rem !important;
  }

  .WalletTokens_ms-lg-auto__1L4Ir {
    margin-left: auto !important;
  }

  .WalletTokens_p-lg-0__1Wd4s {
    padding: 0 !important;
  }

  .WalletTokens_p-lg-1__eF0Pu {
    padding: 0.25rem !important;
  }

  .WalletTokens_p-lg-2__1--qV {
    padding: 0.5rem !important;
  }

  .WalletTokens_p-lg-3__2trgY {
    padding: 1rem !important;
  }

  .WalletTokens_p-lg-4__WfRxy {
    padding: 1.5rem !important;
  }

  .WalletTokens_p-lg-5__1Jqdx {
    padding: 3rem !important;
  }

  .WalletTokens_px-lg-0__11lfQ {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .WalletTokens_px-lg-1__3haT2 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .WalletTokens_px-lg-2__1eTGQ {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .WalletTokens_px-lg-3__3YUkE {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .WalletTokens_px-lg-4__35-kJ {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .WalletTokens_px-lg-5__3wOw3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .WalletTokens_py-lg-0__2Iay0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .WalletTokens_py-lg-1__jXfpy {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .WalletTokens_py-lg-2__1hYnq {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .WalletTokens_py-lg-3__1hq9N {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .WalletTokens_py-lg-4__2l_ix {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .WalletTokens_py-lg-5__3uEZL {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .WalletTokens_pt-lg-0__3HC0h {
    padding-top: 0 !important;
  }

  .WalletTokens_pt-lg-1__1AdRU {
    padding-top: 0.25rem !important;
  }

  .WalletTokens_pt-lg-2__1sThA {
    padding-top: 0.5rem !important;
  }

  .WalletTokens_pt-lg-3__j6tud {
    padding-top: 1rem !important;
  }

  .WalletTokens_pt-lg-4__2xrr2 {
    padding-top: 1.5rem !important;
  }

  .WalletTokens_pt-lg-5__1vFMr {
    padding-top: 3rem !important;
  }

  .WalletTokens_pe-lg-0__3W-YQ {
    padding-right: 0 !important;
  }

  .WalletTokens_pe-lg-1__2oi84 {
    padding-right: 0.25rem !important;
  }

  .WalletTokens_pe-lg-2__3Vful {
    padding-right: 0.5rem !important;
  }

  .WalletTokens_pe-lg-3__1l5gv {
    padding-right: 1rem !important;
  }

  .WalletTokens_pe-lg-4__85RMb {
    padding-right: 1.5rem !important;
  }

  .WalletTokens_pe-lg-5__2_pte {
    padding-right: 3rem !important;
  }

  .WalletTokens_pb-lg-0__BpP4A {
    padding-bottom: 0 !important;
  }

  .WalletTokens_pb-lg-1__1F073 {
    padding-bottom: 0.25rem !important;
  }

  .WalletTokens_pb-lg-2__1bva_ {
    padding-bottom: 0.5rem !important;
  }

  .WalletTokens_pb-lg-3__2xEHV {
    padding-bottom: 1rem !important;
  }

  .WalletTokens_pb-lg-4__2L0Up {
    padding-bottom: 1.5rem !important;
  }

  .WalletTokens_pb-lg-5__gZGwn {
    padding-bottom: 3rem !important;
  }

  .WalletTokens_ps-lg-0__2UQFk {
    padding-left: 0 !important;
  }

  .WalletTokens_ps-lg-1__MJ9aq {
    padding-left: 0.25rem !important;
  }

  .WalletTokens_ps-lg-2__3mhxw {
    padding-left: 0.5rem !important;
  }

  .WalletTokens_ps-lg-3__27x2t {
    padding-left: 1rem !important;
  }

  .WalletTokens_ps-lg-4__1H2Te {
    padding-left: 1.5rem !important;
  }

  .WalletTokens_ps-lg-5__1dBwc {
    padding-left: 3rem !important;
  }

  .WalletTokens_text-lg-start__18ibi {
    text-align: left !important;
  }

  .WalletTokens_text-lg-end__PDmzM {
    text-align: right !important;
  }

  .WalletTokens_text-lg-center__1ZQs2 {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .WalletTokens_float-xl-start__2yo0Z {
    float: left !important;
  }

  .WalletTokens_float-xl-end__3u5lj {
    float: right !important;
  }

  .WalletTokens_float-xl-none__29q0I {
    float: none !important;
  }

  .WalletTokens_d-xl-inline__2Jh2m {
    display: inline !important;
  }

  .WalletTokens_d-xl-inline-block__2ttLx {
    display: inline-block !important;
  }

  .WalletTokens_d-xl-block__1yDD8 {
    display: block !important;
  }

  .WalletTokens_d-xl-grid__3cMlC {
    display: grid !important;
  }

  .WalletTokens_d-xl-table__353aK {
    display: table !important;
  }

  .WalletTokens_d-xl-table-row__Q8Bjq {
    display: table-row !important;
  }

  .WalletTokens_d-xl-table-cell__1Ufae {
    display: table-cell !important;
  }

  .WalletTokens_d-xl-flex__2obbK {
    display: flex !important;
  }

  .WalletTokens_d-xl-inline-flex__1EyGn {
    display: inline-flex !important;
  }

  .WalletTokens_d-xl-none__2bNdv {
    display: none !important;
  }

  .WalletTokens_flex-xl-fill__1dag7 {
    flex: 1 1 auto !important;
  }

  .WalletTokens_flex-xl-row__2IQn9 {
    flex-direction: row !important;
  }

  .WalletTokens_flex-xl-column__ngkYY {
    flex-direction: column !important;
  }

  .WalletTokens_flex-xl-row-reverse__26VM7 {
    flex-direction: row-reverse !important;
  }

  .WalletTokens_flex-xl-column-reverse__d85tc {
    flex-direction: column-reverse !important;
  }

  .WalletTokens_flex-xl-grow-0__h8Vur {
    flex-grow: 0 !important;
  }

  .WalletTokens_flex-xl-grow-1__3u_PL {
    flex-grow: 1 !important;
  }

  .WalletTokens_flex-xl-shrink-0__1pp5p {
    flex-shrink: 0 !important;
  }

  .WalletTokens_flex-xl-shrink-1__1DsXo {
    flex-shrink: 1 !important;
  }

  .WalletTokens_flex-xl-wrap__2jh_1 {
    flex-wrap: wrap !important;
  }

  .WalletTokens_flex-xl-nowrap__iNi3i {
    flex-wrap: nowrap !important;
  }

  .WalletTokens_flex-xl-wrap-reverse__37J4J {
    flex-wrap: wrap-reverse !important;
  }

  .WalletTokens_gap-xl-0__2xkQT {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .WalletTokens_gap-xl-1__10eY3 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .WalletTokens_gap-xl-2__1prAI {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .WalletTokens_gap-xl-3__3KKp2 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .WalletTokens_gap-xl-4___8Z9B {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .WalletTokens_gap-xl-5__ykk-z {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .WalletTokens_justify-content-xl-start__1-VDD {
    justify-content: flex-start !important;
  }

  .WalletTokens_justify-content-xl-end__3hVGm {
    justify-content: flex-end !important;
  }

  .WalletTokens_justify-content-xl-center__1TFcI {
    justify-content: center !important;
  }

  .WalletTokens_justify-content-xl-between__ycpnn {
    justify-content: space-between !important;
  }

  .WalletTokens_justify-content-xl-around__1o9c_ {
    justify-content: space-around !important;
  }

  .WalletTokens_justify-content-xl-evenly__2v1yf {
    justify-content: space-evenly !important;
  }

  .WalletTokens_align-items-xl-start__CdkN- {
    align-items: flex-start !important;
  }

  .WalletTokens_align-items-xl-end__26mio {
    align-items: flex-end !important;
  }

  .WalletTokens_align-items-xl-center__3fXAY {
    align-items: center !important;
  }

  .WalletTokens_align-items-xl-baseline__1ED-4 {
    align-items: baseline !important;
  }

  .WalletTokens_align-items-xl-stretch__2cH3d {
    align-items: stretch !important;
  }

  .WalletTokens_align-content-xl-start__QpBBg {
    align-content: flex-start !important;
  }

  .WalletTokens_align-content-xl-end__38kQD {
    align-content: flex-end !important;
  }

  .WalletTokens_align-content-xl-center__3iR0B {
    align-content: center !important;
  }

  .WalletTokens_align-content-xl-between__1AhNI {
    align-content: space-between !important;
  }

  .WalletTokens_align-content-xl-around__5zGZt {
    align-content: space-around !important;
  }

  .WalletTokens_align-content-xl-stretch__1ItLq {
    align-content: stretch !important;
  }

  .WalletTokens_align-self-xl-auto__LKg0F {
    align-self: auto !important;
  }

  .WalletTokens_align-self-xl-start__3M5b0 {
    align-self: flex-start !important;
  }

  .WalletTokens_align-self-xl-end__K0s6C {
    align-self: flex-end !important;
  }

  .WalletTokens_align-self-xl-center__yWm2D {
    align-self: center !important;
  }

  .WalletTokens_align-self-xl-baseline__2AD1f {
    align-self: baseline !important;
  }

  .WalletTokens_align-self-xl-stretch__2Hgv2 {
    align-self: stretch !important;
  }

  .WalletTokens_order-xl-first__1eULG {
    order: -1 !important;
  }

  .WalletTokens_order-xl-0__1H5zl {
    order: 0 !important;
  }

  .WalletTokens_order-xl-1__14cuK {
    order: 1 !important;
  }

  .WalletTokens_order-xl-2__1AwLV {
    order: 2 !important;
  }

  .WalletTokens_order-xl-3__Kg3YS {
    order: 3 !important;
  }

  .WalletTokens_order-xl-4__VAFGc {
    order: 4 !important;
  }

  .WalletTokens_order-xl-5__jNRTa {
    order: 5 !important;
  }

  .WalletTokens_order-xl-last__1msTP {
    order: 6 !important;
  }

  .WalletTokens_m-xl-0__GrxIC {
    margin: 0 !important;
  }

  .WalletTokens_m-xl-1__3WS8M {
    margin: 0.25rem !important;
  }

  .WalletTokens_m-xl-2__3PPZD {
    margin: 0.5rem !important;
  }

  .WalletTokens_m-xl-3__39iGA {
    margin: 1rem !important;
  }

  .WalletTokens_m-xl-4__3K0-m {
    margin: 1.5rem !important;
  }

  .WalletTokens_m-xl-5__3MSDN {
    margin: 3rem !important;
  }

  .WalletTokens_m-xl-auto__2jf9d {
    margin: auto !important;
  }

  .WalletTokens_mx-xl-0__2kxEm {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .WalletTokens_mx-xl-1__F_EpN {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .WalletTokens_mx-xl-2__7KC4s {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .WalletTokens_mx-xl-3__35wVX {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .WalletTokens_mx-xl-4__1aS8F {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .WalletTokens_mx-xl-5__pl6dq {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .WalletTokens_mx-xl-auto__ztE1E {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .WalletTokens_my-xl-0__2dU4v {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .WalletTokens_my-xl-1__1MlWl {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .WalletTokens_my-xl-2__1Q9ZQ {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .WalletTokens_my-xl-3__3ZEy4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .WalletTokens_my-xl-4__3U3f8 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .WalletTokens_my-xl-5__3k28t {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .WalletTokens_my-xl-auto__3rcwR {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .WalletTokens_mt-xl-0__1BnoP {
    margin-top: 0 !important;
  }

  .WalletTokens_mt-xl-1__5Ttdz {
    margin-top: 0.25rem !important;
  }

  .WalletTokens_mt-xl-2__yUZhe {
    margin-top: 0.5rem !important;
  }

  .WalletTokens_mt-xl-3__3vjUg {
    margin-top: 1rem !important;
  }

  .WalletTokens_mt-xl-4__2AD6y {
    margin-top: 1.5rem !important;
  }

  .WalletTokens_mt-xl-5__TvZr8 {
    margin-top: 3rem !important;
  }

  .WalletTokens_mt-xl-auto__15m87 {
    margin-top: auto !important;
  }

  .WalletTokens_me-xl-0__3Wftf {
    margin-right: 0 !important;
  }

  .WalletTokens_me-xl-1__L9b82 {
    margin-right: 0.25rem !important;
  }

  .WalletTokens_me-xl-2__ZpAIF {
    margin-right: 0.5rem !important;
  }

  .WalletTokens_me-xl-3__3bOm8 {
    margin-right: 1rem !important;
  }

  .WalletTokens_me-xl-4__vER4P {
    margin-right: 1.5rem !important;
  }

  .WalletTokens_me-xl-5__3RIrj {
    margin-right: 3rem !important;
  }

  .WalletTokens_me-xl-auto__14pp1 {
    margin-right: auto !important;
  }

  .WalletTokens_mb-xl-0__1TrwT {
    margin-bottom: 0 !important;
  }

  .WalletTokens_mb-xl-1__3xPnA {
    margin-bottom: 0.25rem !important;
  }

  .WalletTokens_mb-xl-2__1NNvt {
    margin-bottom: 0.5rem !important;
  }

  .WalletTokens_mb-xl-3__2ibej {
    margin-bottom: 1rem !important;
  }

  .WalletTokens_mb-xl-4__26GRD {
    margin-bottom: 1.5rem !important;
  }

  .WalletTokens_mb-xl-5__3VY96 {
    margin-bottom: 3rem !important;
  }

  .WalletTokens_mb-xl-auto__1JlEx {
    margin-bottom: auto !important;
  }

  .WalletTokens_ms-xl-0__1bXo3 {
    margin-left: 0 !important;
  }

  .WalletTokens_ms-xl-1__2G-kp {
    margin-left: 0.25rem !important;
  }

  .WalletTokens_ms-xl-2__S1rgX {
    margin-left: 0.5rem !important;
  }

  .WalletTokens_ms-xl-3__26adQ {
    margin-left: 1rem !important;
  }

  .WalletTokens_ms-xl-4__E07Hr {
    margin-left: 1.5rem !important;
  }

  .WalletTokens_ms-xl-5__2Fkdr {
    margin-left: 3rem !important;
  }

  .WalletTokens_ms-xl-auto__3z2hE {
    margin-left: auto !important;
  }

  .WalletTokens_p-xl-0__2u0ss {
    padding: 0 !important;
  }

  .WalletTokens_p-xl-1__1dVsg {
    padding: 0.25rem !important;
  }

  .WalletTokens_p-xl-2__22iM4 {
    padding: 0.5rem !important;
  }

  .WalletTokens_p-xl-3__j7FS2 {
    padding: 1rem !important;
  }

  .WalletTokens_p-xl-4__xdQL9 {
    padding: 1.5rem !important;
  }

  .WalletTokens_p-xl-5__1TElV {
    padding: 3rem !important;
  }

  .WalletTokens_px-xl-0__2mWfW {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .WalletTokens_px-xl-1__9T_Bo {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .WalletTokens_px-xl-2__2W2YG {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .WalletTokens_px-xl-3__3HYvr {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .WalletTokens_px-xl-4__XlS5s {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .WalletTokens_px-xl-5__VJSWt {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .WalletTokens_py-xl-0__gSDH- {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .WalletTokens_py-xl-1__2Vy2L {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .WalletTokens_py-xl-2__27HJP {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .WalletTokens_py-xl-3__1vLCl {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .WalletTokens_py-xl-4__2zZms {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .WalletTokens_py-xl-5__23_hC {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .WalletTokens_pt-xl-0__b1x3- {
    padding-top: 0 !important;
  }

  .WalletTokens_pt-xl-1__2Dn15 {
    padding-top: 0.25rem !important;
  }

  .WalletTokens_pt-xl-2__2lbzb {
    padding-top: 0.5rem !important;
  }

  .WalletTokens_pt-xl-3__3gTBY {
    padding-top: 1rem !important;
  }

  .WalletTokens_pt-xl-4__2q1TQ {
    padding-top: 1.5rem !important;
  }

  .WalletTokens_pt-xl-5__2v_qd {
    padding-top: 3rem !important;
  }

  .WalletTokens_pe-xl-0__10fTP {
    padding-right: 0 !important;
  }

  .WalletTokens_pe-xl-1__3YCdi {
    padding-right: 0.25rem !important;
  }

  .WalletTokens_pe-xl-2__3U2xE {
    padding-right: 0.5rem !important;
  }

  .WalletTokens_pe-xl-3__ipbyE {
    padding-right: 1rem !important;
  }

  .WalletTokens_pe-xl-4__Aumli {
    padding-right: 1.5rem !important;
  }

  .WalletTokens_pe-xl-5__2RAWI {
    padding-right: 3rem !important;
  }

  .WalletTokens_pb-xl-0__zwt6l {
    padding-bottom: 0 !important;
  }

  .WalletTokens_pb-xl-1__Zyp22 {
    padding-bottom: 0.25rem !important;
  }

  .WalletTokens_pb-xl-2__2T5BN {
    padding-bottom: 0.5rem !important;
  }

  .WalletTokens_pb-xl-3__2YZfF {
    padding-bottom: 1rem !important;
  }

  .WalletTokens_pb-xl-4__1eTge {
    padding-bottom: 1.5rem !important;
  }

  .WalletTokens_pb-xl-5__2-esc {
    padding-bottom: 3rem !important;
  }

  .WalletTokens_ps-xl-0__2RQuY {
    padding-left: 0 !important;
  }

  .WalletTokens_ps-xl-1__1uP0T {
    padding-left: 0.25rem !important;
  }

  .WalletTokens_ps-xl-2__1W1o_ {
    padding-left: 0.5rem !important;
  }

  .WalletTokens_ps-xl-3__1vZ2f {
    padding-left: 1rem !important;
  }

  .WalletTokens_ps-xl-4__2O-NB {
    padding-left: 1.5rem !important;
  }

  .WalletTokens_ps-xl-5__3lwYl {
    padding-left: 3rem !important;
  }

  .WalletTokens_text-xl-start__1fR10 {
    text-align: left !important;
  }

  .WalletTokens_text-xl-end__3pKr0 {
    text-align: right !important;
  }

  .WalletTokens_text-xl-center__zYYg2 {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .WalletTokens_float-xxl-start__2ANSD {
    float: left !important;
  }

  .WalletTokens_float-xxl-end__3qLvP {
    float: right !important;
  }

  .WalletTokens_float-xxl-none__3ABdy {
    float: none !important;
  }

  .WalletTokens_d-xxl-inline__TGBGc {
    display: inline !important;
  }

  .WalletTokens_d-xxl-inline-block__3U-A8 {
    display: inline-block !important;
  }

  .WalletTokens_d-xxl-block__dyf2g {
    display: block !important;
  }

  .WalletTokens_d-xxl-grid__1PGkb {
    display: grid !important;
  }

  .WalletTokens_d-xxl-table__3J9k6 {
    display: table !important;
  }

  .WalletTokens_d-xxl-table-row__3uHR4 {
    display: table-row !important;
  }

  .WalletTokens_d-xxl-table-cell__1oTBy {
    display: table-cell !important;
  }

  .WalletTokens_d-xxl-flex__1C18P {
    display: flex !important;
  }

  .WalletTokens_d-xxl-inline-flex__1y3UA {
    display: inline-flex !important;
  }

  .WalletTokens_d-xxl-none__3u_Sc {
    display: none !important;
  }

  .WalletTokens_flex-xxl-fill__2gECk {
    flex: 1 1 auto !important;
  }

  .WalletTokens_flex-xxl-row__2GCxD {
    flex-direction: row !important;
  }

  .WalletTokens_flex-xxl-column__2YLau {
    flex-direction: column !important;
  }

  .WalletTokens_flex-xxl-row-reverse__6eA_b {
    flex-direction: row-reverse !important;
  }

  .WalletTokens_flex-xxl-column-reverse__21RMW {
    flex-direction: column-reverse !important;
  }

  .WalletTokens_flex-xxl-grow-0__2I29- {
    flex-grow: 0 !important;
  }

  .WalletTokens_flex-xxl-grow-1__2Y348 {
    flex-grow: 1 !important;
  }

  .WalletTokens_flex-xxl-shrink-0__1NKKQ {
    flex-shrink: 0 !important;
  }

  .WalletTokens_flex-xxl-shrink-1__2WI2L {
    flex-shrink: 1 !important;
  }

  .WalletTokens_flex-xxl-wrap__2dV8N {
    flex-wrap: wrap !important;
  }

  .WalletTokens_flex-xxl-nowrap__1Kv6X {
    flex-wrap: nowrap !important;
  }

  .WalletTokens_flex-xxl-wrap-reverse__2GMiQ {
    flex-wrap: wrap-reverse !important;
  }

  .WalletTokens_gap-xxl-0__3KMZP {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .WalletTokens_gap-xxl-1__1dJ0L {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .WalletTokens_gap-xxl-2__3_azn {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .WalletTokens_gap-xxl-3__30lLF {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .WalletTokens_gap-xxl-4__19Zys {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .WalletTokens_gap-xxl-5__328To {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .WalletTokens_justify-content-xxl-start__3OA8n {
    justify-content: flex-start !important;
  }

  .WalletTokens_justify-content-xxl-end__sTb92 {
    justify-content: flex-end !important;
  }

  .WalletTokens_justify-content-xxl-center__1wcUy {
    justify-content: center !important;
  }

  .WalletTokens_justify-content-xxl-between__2dkaL {
    justify-content: space-between !important;
  }

  .WalletTokens_justify-content-xxl-around__6je4C {
    justify-content: space-around !important;
  }

  .WalletTokens_justify-content-xxl-evenly__2EiNE {
    justify-content: space-evenly !important;
  }

  .WalletTokens_align-items-xxl-start__bjw5a {
    align-items: flex-start !important;
  }

  .WalletTokens_align-items-xxl-end__hf3iU {
    align-items: flex-end !important;
  }

  .WalletTokens_align-items-xxl-center__3QYYR {
    align-items: center !important;
  }

  .WalletTokens_align-items-xxl-baseline__31hF8 {
    align-items: baseline !important;
  }

  .WalletTokens_align-items-xxl-stretch__2-3-c {
    align-items: stretch !important;
  }

  .WalletTokens_align-content-xxl-start__1_Ffm {
    align-content: flex-start !important;
  }

  .WalletTokens_align-content-xxl-end__2EhM5 {
    align-content: flex-end !important;
  }

  .WalletTokens_align-content-xxl-center__23ErY {
    align-content: center !important;
  }

  .WalletTokens_align-content-xxl-between__3cU1h {
    align-content: space-between !important;
  }

  .WalletTokens_align-content-xxl-around__1Ql76 {
    align-content: space-around !important;
  }

  .WalletTokens_align-content-xxl-stretch__30NYL {
    align-content: stretch !important;
  }

  .WalletTokens_align-self-xxl-auto__2biX_ {
    align-self: auto !important;
  }

  .WalletTokens_align-self-xxl-start__3Ofo2 {
    align-self: flex-start !important;
  }

  .WalletTokens_align-self-xxl-end__Kjb6t {
    align-self: flex-end !important;
  }

  .WalletTokens_align-self-xxl-center__2V5La {
    align-self: center !important;
  }

  .WalletTokens_align-self-xxl-baseline__13O-9 {
    align-self: baseline !important;
  }

  .WalletTokens_align-self-xxl-stretch__1OJFz {
    align-self: stretch !important;
  }

  .WalletTokens_order-xxl-first__2yUFF {
    order: -1 !important;
  }

  .WalletTokens_order-xxl-0__31iSs {
    order: 0 !important;
  }

  .WalletTokens_order-xxl-1__3uewO {
    order: 1 !important;
  }

  .WalletTokens_order-xxl-2__zjGyz {
    order: 2 !important;
  }

  .WalletTokens_order-xxl-3__S7KRa {
    order: 3 !important;
  }

  .WalletTokens_order-xxl-4__1BSC6 {
    order: 4 !important;
  }

  .WalletTokens_order-xxl-5__2VJHK {
    order: 5 !important;
  }

  .WalletTokens_order-xxl-last__39pp2 {
    order: 6 !important;
  }

  .WalletTokens_m-xxl-0__2vqdd {
    margin: 0 !important;
  }

  .WalletTokens_m-xxl-1__3p0t5 {
    margin: 0.25rem !important;
  }

  .WalletTokens_m-xxl-2__2DEty {
    margin: 0.5rem !important;
  }

  .WalletTokens_m-xxl-3__1NfC5 {
    margin: 1rem !important;
  }

  .WalletTokens_m-xxl-4__iNmE7 {
    margin: 1.5rem !important;
  }

  .WalletTokens_m-xxl-5__2wEEn {
    margin: 3rem !important;
  }

  .WalletTokens_m-xxl-auto__1vCws {
    margin: auto !important;
  }

  .WalletTokens_mx-xxl-0__5hLk8 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .WalletTokens_mx-xxl-1__36gOq {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .WalletTokens_mx-xxl-2__2gIOy {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .WalletTokens_mx-xxl-3__29L5t {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .WalletTokens_mx-xxl-4__3dipJ {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .WalletTokens_mx-xxl-5__3HYrC {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .WalletTokens_mx-xxl-auto__14eAV {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .WalletTokens_my-xxl-0__3K8u0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .WalletTokens_my-xxl-1__2uNMH {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .WalletTokens_my-xxl-2__3Be2- {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .WalletTokens_my-xxl-3__1F5i4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .WalletTokens_my-xxl-4__1O4Zr {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .WalletTokens_my-xxl-5__38ne4 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .WalletTokens_my-xxl-auto__66aO4 {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .WalletTokens_mt-xxl-0__1IlZU {
    margin-top: 0 !important;
  }

  .WalletTokens_mt-xxl-1__38Ltc {
    margin-top: 0.25rem !important;
  }

  .WalletTokens_mt-xxl-2__1A_NM {
    margin-top: 0.5rem !important;
  }

  .WalletTokens_mt-xxl-3__J3iFg {
    margin-top: 1rem !important;
  }

  .WalletTokens_mt-xxl-4__Z3q9P {
    margin-top: 1.5rem !important;
  }

  .WalletTokens_mt-xxl-5__1EvRz {
    margin-top: 3rem !important;
  }

  .WalletTokens_mt-xxl-auto__VGLI8 {
    margin-top: auto !important;
  }

  .WalletTokens_me-xxl-0__11i7A {
    margin-right: 0 !important;
  }

  .WalletTokens_me-xxl-1__2__Sb {
    margin-right: 0.25rem !important;
  }

  .WalletTokens_me-xxl-2__3YDUv {
    margin-right: 0.5rem !important;
  }

  .WalletTokens_me-xxl-3__TJ13N {
    margin-right: 1rem !important;
  }

  .WalletTokens_me-xxl-4__3rL0K {
    margin-right: 1.5rem !important;
  }

  .WalletTokens_me-xxl-5__1oJti {
    margin-right: 3rem !important;
  }

  .WalletTokens_me-xxl-auto__2HO2k {
    margin-right: auto !important;
  }

  .WalletTokens_mb-xxl-0__3EYsP {
    margin-bottom: 0 !important;
  }

  .WalletTokens_mb-xxl-1__BUdCc {
    margin-bottom: 0.25rem !important;
  }

  .WalletTokens_mb-xxl-2__smjHJ {
    margin-bottom: 0.5rem !important;
  }

  .WalletTokens_mb-xxl-3__2SV4M {
    margin-bottom: 1rem !important;
  }

  .WalletTokens_mb-xxl-4__YKMpx {
    margin-bottom: 1.5rem !important;
  }

  .WalletTokens_mb-xxl-5__nOpyi {
    margin-bottom: 3rem !important;
  }

  .WalletTokens_mb-xxl-auto__dIXJp {
    margin-bottom: auto !important;
  }

  .WalletTokens_ms-xxl-0__3f5Qi {
    margin-left: 0 !important;
  }

  .WalletTokens_ms-xxl-1__13Ig2 {
    margin-left: 0.25rem !important;
  }

  .WalletTokens_ms-xxl-2__19YC6 {
    margin-left: 0.5rem !important;
  }

  .WalletTokens_ms-xxl-3__7cF9G {
    margin-left: 1rem !important;
  }

  .WalletTokens_ms-xxl-4__3WFwH {
    margin-left: 1.5rem !important;
  }

  .WalletTokens_ms-xxl-5__AoZ-t {
    margin-left: 3rem !important;
  }

  .WalletTokens_ms-xxl-auto__1296U {
    margin-left: auto !important;
  }

  .WalletTokens_p-xxl-0__1C677 {
    padding: 0 !important;
  }

  .WalletTokens_p-xxl-1__kQrut {
    padding: 0.25rem !important;
  }

  .WalletTokens_p-xxl-2__2XsZS {
    padding: 0.5rem !important;
  }

  .WalletTokens_p-xxl-3__p6k9u {
    padding: 1rem !important;
  }

  .WalletTokens_p-xxl-4__3xG5h {
    padding: 1.5rem !important;
  }

  .WalletTokens_p-xxl-5__2VzAi {
    padding: 3rem !important;
  }

  .WalletTokens_px-xxl-0__H1N9S {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .WalletTokens_px-xxl-1__1sRzl {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .WalletTokens_px-xxl-2__1BT0T {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .WalletTokens_px-xxl-3__19nuQ {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .WalletTokens_px-xxl-4__1E-p- {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .WalletTokens_px-xxl-5__2qceq {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .WalletTokens_py-xxl-0__2bv_j {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .WalletTokens_py-xxl-1__RAYKY {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .WalletTokens_py-xxl-2__1KP0F {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .WalletTokens_py-xxl-3__3fUFJ {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .WalletTokens_py-xxl-4__cGeUa {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .WalletTokens_py-xxl-5__mJymO {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .WalletTokens_pt-xxl-0__3bucc {
    padding-top: 0 !important;
  }

  .WalletTokens_pt-xxl-1__2H66k {
    padding-top: 0.25rem !important;
  }

  .WalletTokens_pt-xxl-2__rczFQ {
    padding-top: 0.5rem !important;
  }

  .WalletTokens_pt-xxl-3__TUWH4 {
    padding-top: 1rem !important;
  }

  .WalletTokens_pt-xxl-4__1Gy37 {
    padding-top: 1.5rem !important;
  }

  .WalletTokens_pt-xxl-5__m-wfB {
    padding-top: 3rem !important;
  }

  .WalletTokens_pe-xxl-0__QN24g {
    padding-right: 0 !important;
  }

  .WalletTokens_pe-xxl-1__2UDDQ {
    padding-right: 0.25rem !important;
  }

  .WalletTokens_pe-xxl-2__1hRbe {
    padding-right: 0.5rem !important;
  }

  .WalletTokens_pe-xxl-3__6wNmM {
    padding-right: 1rem !important;
  }

  .WalletTokens_pe-xxl-4__3YxHa {
    padding-right: 1.5rem !important;
  }

  .WalletTokens_pe-xxl-5__Acosn {
    padding-right: 3rem !important;
  }

  .WalletTokens_pb-xxl-0__3Ibj_ {
    padding-bottom: 0 !important;
  }

  .WalletTokens_pb-xxl-1__3N5ZU {
    padding-bottom: 0.25rem !important;
  }

  .WalletTokens_pb-xxl-2__2mac9 {
    padding-bottom: 0.5rem !important;
  }

  .WalletTokens_pb-xxl-3__ceS2A {
    padding-bottom: 1rem !important;
  }

  .WalletTokens_pb-xxl-4__9pAQy {
    padding-bottom: 1.5rem !important;
  }

  .WalletTokens_pb-xxl-5__oNMYv {
    padding-bottom: 3rem !important;
  }

  .WalletTokens_ps-xxl-0__3YtNd {
    padding-left: 0 !important;
  }

  .WalletTokens_ps-xxl-1__1BPs7 {
    padding-left: 0.25rem !important;
  }

  .WalletTokens_ps-xxl-2__eHNdB {
    padding-left: 0.5rem !important;
  }

  .WalletTokens_ps-xxl-3__2DrDK {
    padding-left: 1rem !important;
  }

  .WalletTokens_ps-xxl-4__8-dnS {
    padding-left: 1.5rem !important;
  }

  .WalletTokens_ps-xxl-5__beYO- {
    padding-left: 3rem !important;
  }

  .WalletTokens_text-xxl-start__1r6vT {
    text-align: left !important;
  }

  .WalletTokens_text-xxl-end__2fPNA {
    text-align: right !important;
  }

  .WalletTokens_text-xxl-center__1vEfM {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .WalletTokens_fs-1__1TVXJ {
    font-size: 2.5rem !important;
  }

  .WalletTokens_fs-2__2G5QB {
    font-size: 2rem !important;
  }

  .WalletTokens_fs-3__3AKx1 {
    font-size: 1.75rem !important;
  }

  .WalletTokens_fs-4__34XrO {
    font-size: 1.5rem !important;
  }
}

@media print {
  .WalletTokens_d-print-inline__3A87t {
    display: inline !important;
  }

  .WalletTokens_d-print-inline-block__3vwqT {
    display: inline-block !important;
  }

  .WalletTokens_d-print-block__9RRIp {
    display: block !important;
  }

  .WalletTokens_d-print-grid__3_7VD {
    display: grid !important;
  }

  .WalletTokens_d-print-table__CbCSj {
    display: table !important;
  }

  .WalletTokens_d-print-table-row__THPDq {
    display: table-row !important;
  }

  .WalletTokens_d-print-table-cell__1yIOu {
    display: table-cell !important;
  }

  .WalletTokens_d-print-flex__2Bxhn {
    display: flex !important;
  }

  .WalletTokens_d-print-inline-flex__1fVRA {
    display: inline-flex !important;
  }

  .WalletTokens_d-print-none__3-ycC {
    display: none !important;
  }
}

.WalletTokens_walletTokensTable__2X44S {
  line-height: 1.2;
  word-wrap: break-word;
  font-size: 0.8125rem;
}

.WalletTokens_walletTokensTable__2X44S small {
  font-size: 0.75rem;
}

.TradingViewChart_tradingViewChart__39mEQ {
  background-color: #fff;
  height: 100% !important;
}

.TradingViewChart_tradingViewChart__39mEQ iframe {
  height: 100% !important;
}

/*!
 * Bootstrap Utilities v5.0.0-beta2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.TokenFeed_clearfix__2GsOj:after {
  display: block;
  clear: both;
  content: "";
}

.TokenFeed_link-primary__Jv12s {
  color: #a4ff66;
}

.TokenFeed_link-primary__Jv12s:focus,
.TokenFeed_link-primary__Jv12s:hover {
  color: #65c6ff;
}

.TokenFeed_link-secondary__t4jwN {
  color: #6c757d;
}

.TokenFeed_link-secondary__t4jwN:focus,
.TokenFeed_link-secondary__t4jwN:hover {
  color: #565e64;
}

.TokenFeed_link-success__1d3VH {
  color: #28a745;
}

.TokenFeed_link-success__1d3VH:focus,
.TokenFeed_link-success__1d3VH:hover {
  color: #53b96a;
}

.TokenFeed_link-info__3CbGA {
  color: #17a2b8;
}

.TokenFeed_link-info__3CbGA:focus,
.TokenFeed_link-info__3CbGA:hover {
  color: #45b5c6;
}

.TokenFeed_link-warning__VH995 {
  color: #f7b500;
}

.TokenFeed_link-warning__VH995:focus,
.TokenFeed_link-warning__VH995:hover {
  color: #f9c433;
}

.TokenFeed_link-danger__33P93 {
  color: #dc3545;
}

.TokenFeed_link-danger__33P93:focus,
.TokenFeed_link-danger__33P93:hover {
  color: #b02a37;
}

.TokenFeed_link-light__SL7ws {
  color: #f8f9fa;
}

.TokenFeed_link-light__SL7ws:focus,
.TokenFeed_link-light__SL7ws:hover {
  color: #f9fafb;
}

.TokenFeed_link-dark__2IeMO {
  color: #0f1a1c;
}

.TokenFeed_link-dark__2IeMO:focus,
.TokenFeed_link-dark__2IeMO:hover {
  color: #1e1e1e;
}

.TokenFeed_link-dark-1__1kEl- {
  color: #303032;
}

.TokenFeed_link-dark-1__1kEl-:focus,
.TokenFeed_link-dark-1__1kEl-:hover {
  color: #262628;
}

.TokenFeed_link-dark-2__2mDIQ {
  color: #383838;
}

.TokenFeed_link-dark-2__2mDIQ:focus,
.TokenFeed_link-dark-2__2mDIQ:hover {
  color: #2d2d2d;
}

.TokenFeed_link-blue-dark__3Nkoo {
  color: #141722;
}

.TokenFeed_link-blue-dark__3Nkoo:focus,
.TokenFeed_link-blue-dark__3Nkoo:hover {
  color: #10121b;
}

.TokenFeed_ratio__3_B0q {
  position: relative;
  width: 100%;
}

.TokenFeed_ratio__3_B0q:before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.TokenFeed_ratio__3_B0q > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.TokenFeed_ratio-1x1__3-RH9 {
  --bs-aspect-ratio: 100%;
}

.TokenFeed_ratio-4x3__3ppo- {
  --bs-aspect-ratio: 75%;
}

.TokenFeed_ratio-16x9__3muJH {
  --bs-aspect-ratio: 56.25%;
}

.TokenFeed_ratio-21x9__2rTIA {
  --bs-aspect-ratio: 42.85714%;
}

.TokenFeed_fixed-top__2YPbG {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.TokenFeed_fixed-bottom__825US {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.TokenFeed_sticky-top__3LZGG {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .TokenFeed_sticky-sm-top__qkLKW {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .TokenFeed_sticky-md-top__f5g3J {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .TokenFeed_sticky-lg-top__2VbG8 {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .TokenFeed_sticky-xl-top__3n2G1 {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .TokenFeed_sticky-xxl-top__2eT58 {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.TokenFeed_visually-hidden-focusable__3A7k7:not(:focus):not(:focus-within),
.TokenFeed_visually-hidden__cXsew {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.TokenFeed_stretched-link__2Wfi9:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.TokenFeed_text-truncate__3lWiN {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TokenFeed_align-baseline__k65VT {
  vertical-align: baseline !important;
}

.TokenFeed_align-top__1o_-_ {
  vertical-align: top !important;
}

.TokenFeed_align-middle__1MM2e {
  vertical-align: middle !important;
}

.TokenFeed_align-bottom__3zwwH {
  vertical-align: bottom !important;
}

.TokenFeed_align-text-bottom__1pL8W {
  vertical-align: text-bottom !important;
}

.TokenFeed_align-text-top__qqQMM {
  vertical-align: text-top !important;
}

.TokenFeed_float-start__36JuD {
  float: left !important;
}

.TokenFeed_float-end__q7C3f {
  float: right !important;
}

.TokenFeed_float-none__17HAZ {
  float: none !important;
}

.TokenFeed_overflow-auto__10jFE {
  overflow: auto !important;
}

.TokenFeed_overflow-hidden__1ZHm6 {
  overflow: hidden !important;
}

.TokenFeed_overflow-visible__2GOw9 {
  overflow: visible !important;
}

.TokenFeed_overflow-scroll__1Znpn {
  overflow: scroll !important;
}

.TokenFeed_d-inline__RwBIN {
  display: inline !important;
}

.TokenFeed_d-inline-block__3hv8y {
  display: inline-block !important;
}

.TokenFeed_d-block__1Cay6 {
  display: block !important;
}

.TokenFeed_d-grid__yvcuD {
  display: grid !important;
}

.TokenFeed_d-table__2xcV0 {
  display: table !important;
}

.TokenFeed_d-table-row__2Iaqf {
  display: table-row !important;
}

.TokenFeed_d-table-cell__1jvPY {
  display: table-cell !important;
}

.TokenFeed_d-flex__LLUpP {
  display: flex !important;
}

.TokenFeed_d-inline-flex__Lc7J0 {
  display: inline-flex !important;
}

.TokenFeed_d-none__yyKQh {
  display: none !important;
}

.TokenFeed_shadow__2Quv1 {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.TokenFeed_shadow-sm__38xN3 {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.TokenFeed_shadow-lg__vjEFC {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.TokenFeed_shadow-none__Cgi0M {
  box-shadow: none !important;
}

.TokenFeed_position-static__386MA {
  position: static !important;
}

.TokenFeed_position-relative__3_aKt {
  position: relative !important;
}

.TokenFeed_position-absolute__10JxB {
  position: absolute !important;
}

.TokenFeed_position-fixed__hrEQ2 {
  position: fixed !important;
}

.TokenFeed_position-sticky__W8nEm {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.TokenFeed_top-0__GhFDO {
  top: 0 !important;
}

.TokenFeed_top-50__2XbVr {
  top: 50% !important;
}

.TokenFeed_top-100__3gUEY {
  top: 100% !important;
}

.TokenFeed_bottom-0__1I2Og {
  bottom: 0 !important;
}

.TokenFeed_bottom-50__32QRt {
  bottom: 50% !important;
}

.TokenFeed_bottom-100__2zUHo {
  bottom: 100% !important;
}

.TokenFeed_start-0__3qfVw {
  left: 0 !important;
}

.TokenFeed_start-50__1Nx_2 {
  left: 50% !important;
}

.TokenFeed_start-100__-MUi4 {
  left: 100% !important;
}

.TokenFeed_end-0__UJMmh {
  right: 0 !important;
}

.TokenFeed_end-50__X34t6 {
  right: 50% !important;
}

.TokenFeed_end-100__4VzfG {
  right: 100% !important;
}

.TokenFeed_translate-middle__1ZusD {
  transform: translate(-50%, -50%) !important;
}

.TokenFeed_translate-middle-x__1xtZT {
  transform: translateX(-50%) !important;
}

.TokenFeed_translate-middle-y__1UAvk {
  transform: translateY(-50%) !important;
}

.TokenFeed_border__1V4Gl {
  border: 1px solid #0f1a1c !important;
}

.TokenFeed_border-0__2mx-w {
  border: 0 !important;
}

.TokenFeed_border-top__1_675 {
  border-top: 1px solid #0f1a1c !important;
}

.TokenFeed_border-top-0__vfejd {
  border-top: 0 !important;
}

.TokenFeed_border-end__1o8aq {
  border-right: 1px solid #0f1a1c !important;
}

.TokenFeed_border-end-0__-Pj-O {
  border-right: 0 !important;
}

.TokenFeed_border-bottom__2LNos {
  border-bottom: 1px solid #0f1a1c !important;
}

.TokenFeed_border-bottom-0__3jr_T {
  border-bottom: 0 !important;
}

.TokenFeed_border-start__3Mo7C {
  border-left: 1px solid #0f1a1c !important;
}

.TokenFeed_border-start-0__lgHLg {
  border-left: 0 !important;
}

.TokenFeed_border-primary__1JNfX {
  border-color: #a4ff66 !important;
}

.TokenFeed_border-secondary__1H95n {
  border-color: #6c757d !important;
}

.TokenFeed_border-success__3q3DJ {
  border-color: #28a745 !important;
}

.TokenFeed_border-info__2lFpQ {
  border-color: #17a2b8 !important;
}

.TokenFeed_border-warning__1OSjb {
  border-color: #f7b500 !important;
}

.TokenFeed_border-danger__1HTTv {
  border-color: #dc3545 !important;
}

.TokenFeed_border-light__12Qzz {
  border-color: #f8f9fa !important;
}

.TokenFeed_border-dark__1Uz-S {
  border-color: #0f1a1c !important;
}

.TokenFeed_border-dark-1__3vZgw {
  border-color: #303032 !important;
}

.TokenFeed_border-dark-2__EyfjT {
  border-color: #383838 !important;
}

.TokenFeed_border-blue-dark__Ck2gg {
  border-color: #141722 !important;
}

.TokenFeed_border-white__1LcoG {
  border-color: #fff !important;
}

.TokenFeed_border-0__2mx-w {
  border-width: 0 !important;
}

.TokenFeed_border-1__2zOTV {
  border-width: 1px !important;
}

.TokenFeed_border-2__1o2da {
  border-width: 2px !important;
}

.TokenFeed_border-3__2Gfp6 {
  border-width: 3px !important;
}

.TokenFeed_border-4__2IdqU {
  border-width: 4px !important;
}

.TokenFeed_border-5__3zV_B {
  border-width: 5px !important;
}

.TokenFeed_w-25__qeBMD {
  width: 25% !important;
}

.TokenFeed_w-50__3GzoW {
  width: 50% !important;
}

.TokenFeed_w-75__1dFwg {
  width: 75% !important;
}

.TokenFeed_w-100__lI1YV {
  width: 100% !important;
}

.TokenFeed_w-auto__2-D1v {
  width: auto !important;
}

.TokenFeed_mw-100__3OZ8G {
  max-width: 100% !important;
}

.TokenFeed_vw-100__2TpPQ {
  width: 100vw !important;
}

.TokenFeed_min-vw-100___DH0n {
  min-width: 100vw !important;
}

.TokenFeed_h-25__1249g {
  height: 25% !important;
}

.TokenFeed_h-50__1Zm0w {
  height: 50% !important;
}

.TokenFeed_h-75__2FFyO {
  height: 75% !important;
}

.TokenFeed_h-100__3J2BI {
  height: 100% !important;
}

.TokenFeed_h-auto__2ASiC {
  height: auto !important;
}

.TokenFeed_mh-100__37QGK {
  max-height: 100% !important;
}

.TokenFeed_vh-100__2lf9m {
  height: 100vh !important;
}

.TokenFeed_min-vh-100__2arFK {
  min-height: 100vh !important;
}

.TokenFeed_flex-fill__1Kxo- {
  flex: 1 1 auto !important;
}

.TokenFeed_flex-row__3d1T3 {
  flex-direction: row !important;
}

.TokenFeed_flex-column__2yRhV {
  flex-direction: column !important;
}

.TokenFeed_flex-row-reverse__2dr28 {
  flex-direction: row-reverse !important;
}

.TokenFeed_flex-column-reverse__34H54 {
  flex-direction: column-reverse !important;
}

.TokenFeed_flex-grow-0__13lko {
  flex-grow: 0 !important;
}

.TokenFeed_flex-grow-1__TKfQJ {
  flex-grow: 1 !important;
}

.TokenFeed_flex-shrink-0__3g6ZF {
  flex-shrink: 0 !important;
}

.TokenFeed_flex-shrink-1__1t-VZ {
  flex-shrink: 1 !important;
}

.TokenFeed_flex-wrap__2Is4p {
  flex-wrap: wrap !important;
}

.TokenFeed_flex-nowrap__16iz9 {
  flex-wrap: nowrap !important;
}

.TokenFeed_flex-wrap-reverse__uhP6B {
  flex-wrap: wrap-reverse !important;
}

.TokenFeed_gap-0__1RvgT {
  grid-gap: 0 !important;
  gap: 0 !important;
}

.TokenFeed_gap-1__2tuvJ {
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important;
}

.TokenFeed_gap-2__2J6Lm {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.TokenFeed_gap-3__1lCkW {
  grid-gap: 1rem !important;
  gap: 1rem !important;
}

.TokenFeed_gap-4__28ZGM {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
}

.TokenFeed_gap-5__3D_cf {
  grid-gap: 3rem !important;
  gap: 3rem !important;
}

.TokenFeed_justify-content-start__2ft8O {
  justify-content: flex-start !important;
}

.TokenFeed_justify-content-end__2Z31p {
  justify-content: flex-end !important;
}

.TokenFeed_justify-content-center__32I6D {
  justify-content: center !important;
}

.TokenFeed_justify-content-between__zCXCm {
  justify-content: space-between !important;
}

.TokenFeed_justify-content-around__1V9Q- {
  justify-content: space-around !important;
}

.TokenFeed_justify-content-evenly__UuBf1 {
  justify-content: space-evenly !important;
}

.TokenFeed_align-items-start__3h3PH {
  align-items: flex-start !important;
}

.TokenFeed_align-items-end__1yLq- {
  align-items: flex-end !important;
}

.TokenFeed_align-items-center__AiwLw {
  align-items: center !important;
}

.TokenFeed_align-items-baseline__Vssl3 {
  align-items: baseline !important;
}

.TokenFeed_align-items-stretch__1NraV {
  align-items: stretch !important;
}

.TokenFeed_align-content-start__2-LXO {
  align-content: flex-start !important;
}

.TokenFeed_align-content-end__26dKD {
  align-content: flex-end !important;
}

.TokenFeed_align-content-center__1kx-_ {
  align-content: center !important;
}

.TokenFeed_align-content-between__iscKW {
  align-content: space-between !important;
}

.TokenFeed_align-content-around__2k067 {
  align-content: space-around !important;
}

.TokenFeed_align-content-stretch__19fcE {
  align-content: stretch !important;
}

.TokenFeed_align-self-auto__22QJ8 {
  align-self: auto !important;
}

.TokenFeed_align-self-start__3lwKG {
  align-self: flex-start !important;
}

.TokenFeed_align-self-end__URY6_ {
  align-self: flex-end !important;
}

.TokenFeed_align-self-center__1Mvrn {
  align-self: center !important;
}

.TokenFeed_align-self-baseline__MGQwK {
  align-self: baseline !important;
}

.TokenFeed_align-self-stretch__QVLHn {
  align-self: stretch !important;
}

.TokenFeed_order-first__31SG2 {
  order: -1 !important;
}

.TokenFeed_order-0__oSSO9 {
  order: 0 !important;
}

.TokenFeed_order-1__EaxTt {
  order: 1 !important;
}

.TokenFeed_order-2__2mbxJ {
  order: 2 !important;
}

.TokenFeed_order-3__aQ_rA {
  order: 3 !important;
}

.TokenFeed_order-4__HEKPU {
  order: 4 !important;
}

.TokenFeed_order-5__PWqJO {
  order: 5 !important;
}

.TokenFeed_order-last__3NWhU {
  order: 6 !important;
}

.TokenFeed_m-0__13nde {
  margin: 0 !important;
}

.TokenFeed_m-1__1Wb4V {
  margin: 0.25rem !important;
}

.TokenFeed_m-2__1ESmF {
  margin: 0.5rem !important;
}

.TokenFeed_m-3__YD9yX {
  margin: 1rem !important;
}

.TokenFeed_m-4__3ZAov {
  margin: 1.5rem !important;
}

.TokenFeed_m-5__1dGYP {
  margin: 3rem !important;
}

.TokenFeed_m-auto__2vHex {
  margin: auto !important;
}

.TokenFeed_mx-0__23esp {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.TokenFeed_mx-1__tLImp {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.TokenFeed_mx-2__LgTPh {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.TokenFeed_mx-3__1LNoM {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.TokenFeed_mx-4__9Zae3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.TokenFeed_mx-5__1JxVi {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.TokenFeed_mx-auto__2aoVD {
  margin-right: auto !important;
  margin-left: auto !important;
}

.TokenFeed_my-0__Afjsk {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.TokenFeed_my-1__3dJfO {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.TokenFeed_my-2__2oL-Y {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.TokenFeed_my-3__2wXBw {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.TokenFeed_my-4__Ku7Bd {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.TokenFeed_my-5__1Vg7n {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.TokenFeed_my-auto__1I5lY {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.TokenFeed_mt-0__Zj5YR {
  margin-top: 0 !important;
}

.TokenFeed_mt-1__1xoDM {
  margin-top: 0.25rem !important;
}

.TokenFeed_mt-2__DiCCD {
  margin-top: 0.5rem !important;
}

.TokenFeed_mt-3__2Vbpv {
  margin-top: 1rem !important;
}

.TokenFeed_mt-4__2kPXY {
  margin-top: 1.5rem !important;
}

.TokenFeed_mt-5__1GDXq {
  margin-top: 3rem !important;
}

.TokenFeed_mt-auto__tqKYN {
  margin-top: auto !important;
}

.TokenFeed_me-0__GGXO2 {
  margin-right: 0 !important;
}

.TokenFeed_me-1__2mhHh {
  margin-right: 0.25rem !important;
}

.TokenFeed_me-2__2Edev {
  margin-right: 0.5rem !important;
}

.TokenFeed_me-3__i9B1j {
  margin-right: 1rem !important;
}

.TokenFeed_me-4__fz9dd {
  margin-right: 1.5rem !important;
}

.TokenFeed_me-5__FjiKe {
  margin-right: 3rem !important;
}

.TokenFeed_me-auto__39X7N {
  margin-right: auto !important;
}

.TokenFeed_mb-0__10BND {
  margin-bottom: 0 !important;
}

.TokenFeed_mb-1__15hkf {
  margin-bottom: 0.25rem !important;
}

.TokenFeed_mb-2__h5XHR {
  margin-bottom: 0.5rem !important;
}

.TokenFeed_mb-3__1makA {
  margin-bottom: 1rem !important;
}

.TokenFeed_mb-4__3Lg40 {
  margin-bottom: 1.5rem !important;
}

.TokenFeed_mb-5__bP4LZ {
  margin-bottom: 3rem !important;
}

.TokenFeed_mb-auto__3u2zn {
  margin-bottom: auto !important;
}

.TokenFeed_ms-0__3OGYK {
  margin-left: 0 !important;
}

.TokenFeed_ms-1__-QkJ4 {
  margin-left: 0.25rem !important;
}

.TokenFeed_ms-2__1vZFN {
  margin-left: 0.5rem !important;
}

.TokenFeed_ms-3__A9UQ7 {
  margin-left: 1rem !important;
}

.TokenFeed_ms-4__37kkR {
  margin-left: 1.5rem !important;
}

.TokenFeed_ms-5__gMcuV {
  margin-left: 3rem !important;
}

.TokenFeed_ms-auto__3LhUt {
  margin-left: auto !important;
}

.TokenFeed_p-0__3yZU4 {
  padding: 0 !important;
}

.TokenFeed_p-1__3ksO9 {
  padding: 0.25rem !important;
}

.TokenFeed_p-2__1o2qE {
  padding: 0.5rem !important;
}

.TokenFeed_p-3__fP65J {
  padding: 1rem !important;
}

.TokenFeed_p-4__3plha {
  padding: 1.5rem !important;
}

.TokenFeed_p-5__3rW35 {
  padding: 3rem !important;
}

.TokenFeed_px-0__30dop {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.TokenFeed_px-1__3Q6KT {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.TokenFeed_px-2__2fph7 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.TokenFeed_px-3__3A-0E {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.TokenFeed_px-4__2lrxw {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.TokenFeed_px-5__cWdVA {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.TokenFeed_py-0__1DA2_ {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.TokenFeed_py-1__-wBH4 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.TokenFeed_py-2__3r_8x {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.TokenFeed_py-3__3n_5L {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.TokenFeed_py-4__3CaT7 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.TokenFeed_py-5__3C8jC {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.TokenFeed_pt-0__UNxZJ {
  padding-top: 0 !important;
}

.TokenFeed_pt-1__1M9Gx {
  padding-top: 0.25rem !important;
}

.TokenFeed_pt-2__1kZSd {
  padding-top: 0.5rem !important;
}

.TokenFeed_pt-3__3fxC5 {
  padding-top: 1rem !important;
}

.TokenFeed_pt-4__1tuT8 {
  padding-top: 1.5rem !important;
}

.TokenFeed_pt-5__V6pxo {
  padding-top: 3rem !important;
}

.TokenFeed_pe-0__3Kvan {
  padding-right: 0 !important;
}

.TokenFeed_pe-1__ImfdS {
  padding-right: 0.25rem !important;
}

.TokenFeed_pe-2__quNty {
  padding-right: 0.5rem !important;
}

.TokenFeed_pe-3__1XBl8 {
  padding-right: 1rem !important;
}

.TokenFeed_pe-4__3pMr2 {
  padding-right: 1.5rem !important;
}

.TokenFeed_pe-5__1uh79 {
  padding-right: 3rem !important;
}

.TokenFeed_pb-0__2ld1O {
  padding-bottom: 0 !important;
}

.TokenFeed_pb-1__sUknk {
  padding-bottom: 0.25rem !important;
}

.TokenFeed_pb-2__yQbg0 {
  padding-bottom: 0.5rem !important;
}

.TokenFeed_pb-3__1W7ML {
  padding-bottom: 1rem !important;
}

.TokenFeed_pb-4__1BCNN {
  padding-bottom: 1.5rem !important;
}

.TokenFeed_pb-5__1RuH0 {
  padding-bottom: 3rem !important;
}

.TokenFeed_ps-0__3mPSi {
  padding-left: 0 !important;
}

.TokenFeed_ps-1__1z5_z {
  padding-left: 0.25rem !important;
}

.TokenFeed_ps-2__1xti8 {
  padding-left: 0.5rem !important;
}

.TokenFeed_ps-3__2sTxo {
  padding-left: 1rem !important;
}

.TokenFeed_ps-4__3-kNO {
  padding-left: 1.5rem !important;
}

.TokenFeed_ps-5__2Z20y {
  padding-left: 3rem !important;
}

.TokenFeed_fs-1__2osQa {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.TokenFeed_fs-2__1FKPB {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.TokenFeed_fs-3__JaeeJ {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.TokenFeed_fs-4__1zJnz {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.TokenFeed_fs-5__3fFsk {
  font-size: 1.25rem !important;
}

.TokenFeed_fs-6__2QYRw {
  font-size: 1rem !important;
}

.TokenFeed_fst-italic__1TcvC {
  font-style: italic !important;
}

.TokenFeed_fst-normal__23jhk {
  font-style: normal !important;
}

.TokenFeed_fw-light__2KEmt {
  font-weight: 300 !important;
}

.TokenFeed_fw-lighter__3IdeT {
  font-weight: lighter !important;
}

.TokenFeed_fw-normal__2ylHV {
  font-weight: 400 !important;
}

.TokenFeed_fw-bold__2xMZ4 {
  font-weight: 700 !important;
}

.TokenFeed_fw-bolder__3epSw {
  font-weight: bolder !important;
}

.TokenFeed_text-lowercase__3jItA {
  text-transform: lowercase !important;
}

.TokenFeed_text-uppercase__NPtCd {
  text-transform: uppercase !important;
}

.TokenFeed_text-capitalize__33RQv {
  text-transform: capitalize !important;
}

.TokenFeed_text-start__1OHUP {
  text-align: left !important;
}

.TokenFeed_text-end__2YEHp {
  text-align: right !important;
}

.TokenFeed_text-center__2uMAM {
  text-align: center !important;
}

.TokenFeed_text-primary__dq7-K {
  color: #a4ff66 !important;
}

.TokenFeed_text-secondary__2Z9jc {
  color: #6c757d !important;
}

.TokenFeed_text-success__3weXO {
  color: #28a745 !important;
}

.TokenFeed_text-info__2c1s- {
  color: #17a2b8 !important;
}

.TokenFeed_text-warning__3wGbK {
  color: #f7b500 !important;
}

.TokenFeed_text-danger__1DkLp {
  color: #dc3545 !important;
}

.TokenFeed_text-light__1ECWy {
  color: #f8f9fa !important;
}

.TokenFeed_text-dark__1qdST {
  color: #0f1a1c !important;
}

.TokenFeed_text-dark-1__3bceV {
  color: #303032 !important;
}

.TokenFeed_text-dark-2__31aFL {
  color: #383838 !important;
}

.TokenFeed_text-blue-dark__2_T1R {
  color: #141722 !important;
}

.TokenFeed_text-white__iO5Lb {
  color: #fff !important;
}

.TokenFeed_text-body__113ul {
  color: #f8f9fa !important;
}

.TokenFeed_text-muted__QF6tK {
  color: #adb5bd !important;
}

.TokenFeed_text-black-50__MrzQS {
  color: rgba(0, 0, 0, 0.5) !important;
}

.TokenFeed_text-white-50__1RFWo {
  color: hsla(0, 0%, 100%, 0.5) !important;
}

.TokenFeed_text-reset__zSOp5 {
  color: inherit !important;
}

.TokenFeed_lh-1__AAG4J {
  line-height: 1 !important;
}

.TokenFeed_lh-sm__1lrVM {
  line-height: 1.25 !important;
}

.TokenFeed_lh-base__I0XSq {
  line-height: 1.5 !important;
}

.TokenFeed_lh-lg__bynvh {
  line-height: 2 !important;
}

.TokenFeed_bg-primary__dXwAe {
  background-color: #a4ff66 !important;
}

.TokenFeed_bg-secondary__3QWH2 {
  background-color: #6c757d !important;
}

.TokenFeed_bg-success__U2De2 {
  background-color: #28a745 !important;
}

.TokenFeed_bg-info__3vO0e {
  background-color: #17a2b8 !important;
}

.TokenFeed_bg-warning__1FuTE {
  background-color: #f7b500 !important;
}

.TokenFeed_bg-danger__Me74d {
  background-color: #dc3545 !important;
}

.TokenFeed_bg-light__2amDu {
  background-color: #f8f9fa !important;
}

.TokenFeed_bg-dark__30vG3 {
  background-color: #0f1a1c !important;
}

.TokenFeed_bg-dark-1__2H5bl {
  background-color: #303032 !important;
}

.TokenFeed_bg-dark-2__1rvvH {
  background-color: #383838 !important;
}

.TokenFeed_bg-blue-dark__3WOeU {
  background-color: #141722 !important;
}

.TokenFeed_bg-body__3I3XF {
  background-color: #141414 !important;
}

.TokenFeed_bg-white__2U1N0 {
  background-color: #fff !important;
}

.TokenFeed_bg-transparent__1S6i6 {
  background-color: transparent !important;
}

.TokenFeed_bg-gradient__3jAR5 {
  background-image: var(--bs-gradient) !important;
}

.TokenFeed_text-wrap__CRYRH {
  white-space: normal !important;
}

.TokenFeed_text-nowrap__22kEG {
  white-space: nowrap !important;
}

.TokenFeed_text-decoration-none__3S4qN {
  text-decoration: none !important;
}

.TokenFeed_text-decoration-underline__1fJln {
  text-decoration: underline !important;
}

.TokenFeed_text-decoration-line-through__1Kdxg {
  text-decoration: line-through !important;
}

.TokenFeed_text-break__307aX {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.TokenFeed_font-monospace__2uZCM {
  /* font-family: var(--bs-font-monospace) !important */
}

.TokenFeed_user-select-all__2dp2R {
  -webkit-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.TokenFeed_user-select-auto__X6J5E {
  -webkit-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.TokenFeed_user-select-none__1TRK1 {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.TokenFeed_pe-none__1AMDT {
  pointer-events: none !important;
}

.TokenFeed_pe-auto__2de4q {
  pointer-events: auto !important;
}

.TokenFeed_rounded__3Zr5b {
  border-radius: 0.25rem !important;
}

.TokenFeed_rounded-0__3Rdtw {
  border-radius: 0 !important;
}

.TokenFeed_rounded-1__3Ddpi {
  border-radius: 0.2rem !important;
}

.TokenFeed_rounded-2__3X0Os {
  border-radius: 0.25rem !important;
}

.TokenFeed_rounded-3__aNBmM {
  border-radius: 0.3rem !important;
}

.TokenFeed_rounded-circle__2TZGU {
  border-radius: 50% !important;
}

.TokenFeed_rounded-pill__1TTN- {
  border-radius: 50rem !important;
}

.TokenFeed_rounded-top__3eTRw {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.TokenFeed_rounded-end__19yLa {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.TokenFeed_rounded-bottom__mRKyg {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.TokenFeed_rounded-start__Y6LKc {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.TokenFeed_visible__19o0b {
  visibility: visible !important;
}

.TokenFeed_invisible__1Hd1D {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .TokenFeed_float-sm-start__2p-o2 {
    float: left !important;
  }

  .TokenFeed_float-sm-end__3KFgy {
    float: right !important;
  }

  .TokenFeed_float-sm-none__1l5g_ {
    float: none !important;
  }

  .TokenFeed_d-sm-inline__1Fy_W {
    display: inline !important;
  }

  .TokenFeed_d-sm-inline-block__3tNmu {
    display: inline-block !important;
  }

  .TokenFeed_d-sm-block__3ehG- {
    display: block !important;
  }

  .TokenFeed_d-sm-grid__3mW0T {
    display: grid !important;
  }

  .TokenFeed_d-sm-table__Sq3wL {
    display: table !important;
  }

  .TokenFeed_d-sm-table-row__3S8eJ {
    display: table-row !important;
  }

  .TokenFeed_d-sm-table-cell__PWW9u {
    display: table-cell !important;
  }

  .TokenFeed_d-sm-flex__3Qmu6 {
    display: flex !important;
  }

  .TokenFeed_d-sm-inline-flex__2tYjs {
    display: inline-flex !important;
  }

  .TokenFeed_d-sm-none__1uILJ {
    display: none !important;
  }

  .TokenFeed_flex-sm-fill__19KAu {
    flex: 1 1 auto !important;
  }

  .TokenFeed_flex-sm-row__1Kj7u {
    flex-direction: row !important;
  }

  .TokenFeed_flex-sm-column__xtbSk {
    flex-direction: column !important;
  }

  .TokenFeed_flex-sm-row-reverse__3wDR2 {
    flex-direction: row-reverse !important;
  }

  .TokenFeed_flex-sm-column-reverse__3QGCR {
    flex-direction: column-reverse !important;
  }

  .TokenFeed_flex-sm-grow-0__SkELx {
    flex-grow: 0 !important;
  }

  .TokenFeed_flex-sm-grow-1__hTb53 {
    flex-grow: 1 !important;
  }

  .TokenFeed_flex-sm-shrink-0__Tc5Qs {
    flex-shrink: 0 !important;
  }

  .TokenFeed_flex-sm-shrink-1__2Qnkr {
    flex-shrink: 1 !important;
  }

  .TokenFeed_flex-sm-wrap__2YagN {
    flex-wrap: wrap !important;
  }

  .TokenFeed_flex-sm-nowrap__1KK88 {
    flex-wrap: nowrap !important;
  }

  .TokenFeed_flex-sm-wrap-reverse__bmyB7 {
    flex-wrap: wrap-reverse !important;
  }

  .TokenFeed_gap-sm-0__1gj2q {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .TokenFeed_gap-sm-1__1HOIf {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .TokenFeed_gap-sm-2__he8s1 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .TokenFeed_gap-sm-3__1FHI1 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .TokenFeed_gap-sm-4__3ysFS {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .TokenFeed_gap-sm-5__1USj8 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .TokenFeed_justify-content-sm-start__1jg6E {
    justify-content: flex-start !important;
  }

  .TokenFeed_justify-content-sm-end__3UZEr {
    justify-content: flex-end !important;
  }

  .TokenFeed_justify-content-sm-center__KqRi2 {
    justify-content: center !important;
  }

  .TokenFeed_justify-content-sm-between__2Ww12 {
    justify-content: space-between !important;
  }

  .TokenFeed_justify-content-sm-around__XF_Uh {
    justify-content: space-around !important;
  }

  .TokenFeed_justify-content-sm-evenly__ymRg6 {
    justify-content: space-evenly !important;
  }

  .TokenFeed_align-items-sm-start__3xqAX {
    align-items: flex-start !important;
  }

  .TokenFeed_align-items-sm-end__TNRN4 {
    align-items: flex-end !important;
  }

  .TokenFeed_align-items-sm-center__1Iw7s {
    align-items: center !important;
  }

  .TokenFeed_align-items-sm-baseline__2mCE4 {
    align-items: baseline !important;
  }

  .TokenFeed_align-items-sm-stretch__DwvAm {
    align-items: stretch !important;
  }

  .TokenFeed_align-content-sm-start__2szLO {
    align-content: flex-start !important;
  }

  .TokenFeed_align-content-sm-end__2q9-C {
    align-content: flex-end !important;
  }

  .TokenFeed_align-content-sm-center__2L2Aa {
    align-content: center !important;
  }

  .TokenFeed_align-content-sm-between__3_HRQ {
    align-content: space-between !important;
  }

  .TokenFeed_align-content-sm-around__10j3_ {
    align-content: space-around !important;
  }

  .TokenFeed_align-content-sm-stretch__3bZGv {
    align-content: stretch !important;
  }

  .TokenFeed_align-self-sm-auto__1QcrR {
    align-self: auto !important;
  }

  .TokenFeed_align-self-sm-start__st9np {
    align-self: flex-start !important;
  }

  .TokenFeed_align-self-sm-end__2cZ6p {
    align-self: flex-end !important;
  }

  .TokenFeed_align-self-sm-center__1mxZS {
    align-self: center !important;
  }

  .TokenFeed_align-self-sm-baseline__1lqrQ {
    align-self: baseline !important;
  }

  .TokenFeed_align-self-sm-stretch__l5ZW5 {
    align-self: stretch !important;
  }

  .TokenFeed_order-sm-first__3O55N {
    order: -1 !important;
  }

  .TokenFeed_order-sm-0__2bdmO {
    order: 0 !important;
  }

  .TokenFeed_order-sm-1__2lIak {
    order: 1 !important;
  }

  .TokenFeed_order-sm-2__2e9ou {
    order: 2 !important;
  }

  .TokenFeed_order-sm-3__3im_S {
    order: 3 !important;
  }

  .TokenFeed_order-sm-4__1jcSO {
    order: 4 !important;
  }

  .TokenFeed_order-sm-5__kQTwA {
    order: 5 !important;
  }

  .TokenFeed_order-sm-last__1xfXX {
    order: 6 !important;
  }

  .TokenFeed_m-sm-0__3sJEC {
    margin: 0 !important;
  }

  .TokenFeed_m-sm-1__1ktax {
    margin: 0.25rem !important;
  }

  .TokenFeed_m-sm-2__15jUs {
    margin: 0.5rem !important;
  }

  .TokenFeed_m-sm-3__3bpVG {
    margin: 1rem !important;
  }

  .TokenFeed_m-sm-4__XQTjI {
    margin: 1.5rem !important;
  }

  .TokenFeed_m-sm-5__3HEa8 {
    margin: 3rem !important;
  }

  .TokenFeed_m-sm-auto__12wpz {
    margin: auto !important;
  }

  .TokenFeed_mx-sm-0__1mTUu {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .TokenFeed_mx-sm-1__1EIhk {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .TokenFeed_mx-sm-2__OLp9H {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .TokenFeed_mx-sm-3__2VYeI {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .TokenFeed_mx-sm-4__2413n {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .TokenFeed_mx-sm-5__GwlaH {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .TokenFeed_mx-sm-auto__k67AI {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .TokenFeed_my-sm-0__17-5f {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .TokenFeed_my-sm-1__54yZG {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .TokenFeed_my-sm-2__1mCxb {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .TokenFeed_my-sm-3__AMA-o {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .TokenFeed_my-sm-4__g-dmi {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .TokenFeed_my-sm-5__2CQWp {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .TokenFeed_my-sm-auto__2fE2t {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .TokenFeed_mt-sm-0__1ZtbE {
    margin-top: 0 !important;
  }

  .TokenFeed_mt-sm-1__2IEv- {
    margin-top: 0.25rem !important;
  }

  .TokenFeed_mt-sm-2__3YoQv {
    margin-top: 0.5rem !important;
  }

  .TokenFeed_mt-sm-3__3Z8Z8 {
    margin-top: 1rem !important;
  }

  .TokenFeed_mt-sm-4__TE5RM {
    margin-top: 1.5rem !important;
  }

  .TokenFeed_mt-sm-5__n1Ldf {
    margin-top: 3rem !important;
  }

  .TokenFeed_mt-sm-auto__1oOgM {
    margin-top: auto !important;
  }

  .TokenFeed_me-sm-0__3H2g8 {
    margin-right: 0 !important;
  }

  .TokenFeed_me-sm-1__24GAA {
    margin-right: 0.25rem !important;
  }

  .TokenFeed_me-sm-2__19ikB {
    margin-right: 0.5rem !important;
  }

  .TokenFeed_me-sm-3__3mVSy {
    margin-right: 1rem !important;
  }

  .TokenFeed_me-sm-4__1ivLM {
    margin-right: 1.5rem !important;
  }

  .TokenFeed_me-sm-5__3wW59 {
    margin-right: 3rem !important;
  }

  .TokenFeed_me-sm-auto__s7PNo {
    margin-right: auto !important;
  }

  .TokenFeed_mb-sm-0__31ohq {
    margin-bottom: 0 !important;
  }

  .TokenFeed_mb-sm-1__TSvZp {
    margin-bottom: 0.25rem !important;
  }

  .TokenFeed_mb-sm-2__73NVo {
    margin-bottom: 0.5rem !important;
  }

  .TokenFeed_mb-sm-3__1Hc_3 {
    margin-bottom: 1rem !important;
  }

  .TokenFeed_mb-sm-4__2Xeps {
    margin-bottom: 1.5rem !important;
  }

  .TokenFeed_mb-sm-5__lqzPz {
    margin-bottom: 3rem !important;
  }

  .TokenFeed_mb-sm-auto__4qjKy {
    margin-bottom: auto !important;
  }

  .TokenFeed_ms-sm-0__1e1m_ {
    margin-left: 0 !important;
  }

  .TokenFeed_ms-sm-1__Ht4ob {
    margin-left: 0.25rem !important;
  }

  .TokenFeed_ms-sm-2__1upk0 {
    margin-left: 0.5rem !important;
  }

  .TokenFeed_ms-sm-3__2R2-B {
    margin-left: 1rem !important;
  }

  .TokenFeed_ms-sm-4__XdWH0 {
    margin-left: 1.5rem !important;
  }

  .TokenFeed_ms-sm-5__2fbyF {
    margin-left: 3rem !important;
  }

  .TokenFeed_ms-sm-auto__yW9Zx {
    margin-left: auto !important;
  }

  .TokenFeed_p-sm-0__3yuBU {
    padding: 0 !important;
  }

  .TokenFeed_p-sm-1__3wBkS {
    padding: 0.25rem !important;
  }

  .TokenFeed_p-sm-2__YPu4k {
    padding: 0.5rem !important;
  }

  .TokenFeed_p-sm-3__3-qZq {
    padding: 1rem !important;
  }

  .TokenFeed_p-sm-4__1JGZB {
    padding: 1.5rem !important;
  }

  .TokenFeed_p-sm-5__1I4IA {
    padding: 3rem !important;
  }

  .TokenFeed_px-sm-0__26OVy {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .TokenFeed_px-sm-1__1eqo8 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .TokenFeed_px-sm-2__1uvZb {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .TokenFeed_px-sm-3__3Di1g {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .TokenFeed_px-sm-4__1X3cY {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TokenFeed_px-sm-5__2GXg- {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .TokenFeed_py-sm-0__sq4IM {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .TokenFeed_py-sm-1__-rRz9 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .TokenFeed_py-sm-2__1HbOn {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .TokenFeed_py-sm-3___lg2g {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .TokenFeed_py-sm-4__3m4pD {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .TokenFeed_py-sm-5__3OHJj {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .TokenFeed_pt-sm-0__Hkj5P {
    padding-top: 0 !important;
  }

  .TokenFeed_pt-sm-1__2X8KB {
    padding-top: 0.25rem !important;
  }

  .TokenFeed_pt-sm-2__2ZH_- {
    padding-top: 0.5rem !important;
  }

  .TokenFeed_pt-sm-3__wDYxj {
    padding-top: 1rem !important;
  }

  .TokenFeed_pt-sm-4__3VNtu {
    padding-top: 1.5rem !important;
  }

  .TokenFeed_pt-sm-5__3afqM {
    padding-top: 3rem !important;
  }

  .TokenFeed_pe-sm-0__dbJRD {
    padding-right: 0 !important;
  }

  .TokenFeed_pe-sm-1__3QIgV {
    padding-right: 0.25rem !important;
  }

  .TokenFeed_pe-sm-2__3d0tx {
    padding-right: 0.5rem !important;
  }

  .TokenFeed_pe-sm-3__1eWj4 {
    padding-right: 1rem !important;
  }

  .TokenFeed_pe-sm-4__1JNgI {
    padding-right: 1.5rem !important;
  }

  .TokenFeed_pe-sm-5__peHII {
    padding-right: 3rem !important;
  }

  .TokenFeed_pb-sm-0__3hIl3 {
    padding-bottom: 0 !important;
  }

  .TokenFeed_pb-sm-1__14W1P {
    padding-bottom: 0.25rem !important;
  }

  .TokenFeed_pb-sm-2__3e3u9 {
    padding-bottom: 0.5rem !important;
  }

  .TokenFeed_pb-sm-3__28ok3 {
    padding-bottom: 1rem !important;
  }

  .TokenFeed_pb-sm-4__3yzmc {
    padding-bottom: 1.5rem !important;
  }

  .TokenFeed_pb-sm-5__dl5YN {
    padding-bottom: 3rem !important;
  }

  .TokenFeed_ps-sm-0__3bsOQ {
    padding-left: 0 !important;
  }

  .TokenFeed_ps-sm-1__9bjdE {
    padding-left: 0.25rem !important;
  }

  .TokenFeed_ps-sm-2__1rDXe {
    padding-left: 0.5rem !important;
  }

  .TokenFeed_ps-sm-3__1LEej {
    padding-left: 1rem !important;
  }

  .TokenFeed_ps-sm-4__27BrS {
    padding-left: 1.5rem !important;
  }

  .TokenFeed_ps-sm-5__4hvR8 {
    padding-left: 3rem !important;
  }

  .TokenFeed_text-sm-start__3LOqr {
    text-align: left !important;
  }

  .TokenFeed_text-sm-end__3DCfh {
    text-align: right !important;
  }

  .TokenFeed_text-sm-center__3G6jy {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .TokenFeed_float-md-start__1ojGv {
    float: left !important;
  }

  .TokenFeed_float-md-end__19KEn {
    float: right !important;
  }

  .TokenFeed_float-md-none__2-8ir {
    float: none !important;
  }

  .TokenFeed_d-md-inline__1PU3x {
    display: inline !important;
  }

  .TokenFeed_d-md-inline-block__NFJWe {
    display: inline-block !important;
  }

  .TokenFeed_d-md-block__2k3QJ {
    display: block !important;
  }

  .TokenFeed_d-md-grid__2Z8zo {
    display: grid !important;
  }

  .TokenFeed_d-md-table__2oSJl {
    display: table !important;
  }

  .TokenFeed_d-md-table-row__8PqCH {
    display: table-row !important;
  }

  .TokenFeed_d-md-table-cell__184eL {
    display: table-cell !important;
  }

  .TokenFeed_d-md-flex__1jCUj {
    display: flex !important;
  }

  .TokenFeed_d-md-inline-flex__2NQyl {
    display: inline-flex !important;
  }

  .TokenFeed_d-md-none__2xRtV {
    display: none !important;
  }

  .TokenFeed_flex-md-fill__2uI5b {
    flex: 1 1 auto !important;
  }

  .TokenFeed_flex-md-row__3pEJz {
    flex-direction: row !important;
  }

  .TokenFeed_flex-md-column__2f34v {
    flex-direction: column !important;
  }

  .TokenFeed_flex-md-row-reverse__1m-2o {
    flex-direction: row-reverse !important;
  }

  .TokenFeed_flex-md-column-reverse__2e7FP {
    flex-direction: column-reverse !important;
  }

  .TokenFeed_flex-md-grow-0__3s9Ox {
    flex-grow: 0 !important;
  }

  .TokenFeed_flex-md-grow-1__3LI2- {
    flex-grow: 1 !important;
  }

  .TokenFeed_flex-md-shrink-0__2Ykft {
    flex-shrink: 0 !important;
  }

  .TokenFeed_flex-md-shrink-1__2QB93 {
    flex-shrink: 1 !important;
  }

  .TokenFeed_flex-md-wrap__CS6Ue {
    flex-wrap: wrap !important;
  }

  .TokenFeed_flex-md-nowrap__374p1 {
    flex-wrap: nowrap !important;
  }

  .TokenFeed_flex-md-wrap-reverse__2jXJk {
    flex-wrap: wrap-reverse !important;
  }

  .TokenFeed_gap-md-0__16RmZ {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .TokenFeed_gap-md-1__2dJGP {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .TokenFeed_gap-md-2__2lTI8 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .TokenFeed_gap-md-3__2V2uB {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .TokenFeed_gap-md-4__2bqJD {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .TokenFeed_gap-md-5__2n0wa {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .TokenFeed_justify-content-md-start__JWNpk {
    justify-content: flex-start !important;
  }

  .TokenFeed_justify-content-md-end__2DIOL {
    justify-content: flex-end !important;
  }

  .TokenFeed_justify-content-md-center__1AACv {
    justify-content: center !important;
  }

  .TokenFeed_justify-content-md-between__2IqPI {
    justify-content: space-between !important;
  }

  .TokenFeed_justify-content-md-around__3_pN6 {
    justify-content: space-around !important;
  }

  .TokenFeed_justify-content-md-evenly__2028K {
    justify-content: space-evenly !important;
  }

  .TokenFeed_align-items-md-start__2Ld9q {
    align-items: flex-start !important;
  }

  .TokenFeed_align-items-md-end__30PA7 {
    align-items: flex-end !important;
  }

  .TokenFeed_align-items-md-center__owM-s {
    align-items: center !important;
  }

  .TokenFeed_align-items-md-baseline__3eVXg {
    align-items: baseline !important;
  }

  .TokenFeed_align-items-md-stretch__35krm {
    align-items: stretch !important;
  }

  .TokenFeed_align-content-md-start__2rHBR {
    align-content: flex-start !important;
  }

  .TokenFeed_align-content-md-end__3Z6U- {
    align-content: flex-end !important;
  }

  .TokenFeed_align-content-md-center__2LoNH {
    align-content: center !important;
  }

  .TokenFeed_align-content-md-between__1NsbO {
    align-content: space-between !important;
  }

  .TokenFeed_align-content-md-around__HHOsH {
    align-content: space-around !important;
  }

  .TokenFeed_align-content-md-stretch__Smrlt {
    align-content: stretch !important;
  }

  .TokenFeed_align-self-md-auto__2fc3G {
    align-self: auto !important;
  }

  .TokenFeed_align-self-md-start__4NiLd {
    align-self: flex-start !important;
  }

  .TokenFeed_align-self-md-end__2l-ww {
    align-self: flex-end !important;
  }

  .TokenFeed_align-self-md-center__1akCe {
    align-self: center !important;
  }

  .TokenFeed_align-self-md-baseline__sMEqh {
    align-self: baseline !important;
  }

  .TokenFeed_align-self-md-stretch__3m7Go {
    align-self: stretch !important;
  }

  .TokenFeed_order-md-first__2_SaZ {
    order: -1 !important;
  }

  .TokenFeed_order-md-0__uzQ5_ {
    order: 0 !important;
  }

  .TokenFeed_order-md-1__1yA1d {
    order: 1 !important;
  }

  .TokenFeed_order-md-2__3B_yI {
    order: 2 !important;
  }

  .TokenFeed_order-md-3__kDsQ3 {
    order: 3 !important;
  }

  .TokenFeed_order-md-4__1RRiU {
    order: 4 !important;
  }

  .TokenFeed_order-md-5__3b5cj {
    order: 5 !important;
  }

  .TokenFeed_order-md-last__3XP2j {
    order: 6 !important;
  }

  .TokenFeed_m-md-0__3JSzE {
    margin: 0 !important;
  }

  .TokenFeed_m-md-1__1uFzk {
    margin: 0.25rem !important;
  }

  .TokenFeed_m-md-2__3ZGUG {
    margin: 0.5rem !important;
  }

  .TokenFeed_m-md-3__3hFsj {
    margin: 1rem !important;
  }

  .TokenFeed_m-md-4__1dHC4 {
    margin: 1.5rem !important;
  }

  .TokenFeed_m-md-5__i5BQl {
    margin: 3rem !important;
  }

  .TokenFeed_m-md-auto__2Ccnl {
    margin: auto !important;
  }

  .TokenFeed_mx-md-0__1Twvx {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .TokenFeed_mx-md-1__3HLkA {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .TokenFeed_mx-md-2__3Re7W {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .TokenFeed_mx-md-3__13KSy {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .TokenFeed_mx-md-4__3S1mM {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .TokenFeed_mx-md-5__sw_jn {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .TokenFeed_mx-md-auto__7FubB {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .TokenFeed_my-md-0__13Hvk {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .TokenFeed_my-md-1__29A3F {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .TokenFeed_my-md-2__3oZAL {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .TokenFeed_my-md-3__3-L3K {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .TokenFeed_my-md-4__2INWG {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .TokenFeed_my-md-5__3v_1B {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .TokenFeed_my-md-auto__dzb6q {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .TokenFeed_mt-md-0__3gbax {
    margin-top: 0 !important;
  }

  .TokenFeed_mt-md-1__itvGR {
    margin-top: 0.25rem !important;
  }

  .TokenFeed_mt-md-2__3Z4Zf {
    margin-top: 0.5rem !important;
  }

  .TokenFeed_mt-md-3__187ZT {
    margin-top: 1rem !important;
  }

  .TokenFeed_mt-md-4__1L0mL {
    margin-top: 1.5rem !important;
  }

  .TokenFeed_mt-md-5__-6XFs {
    margin-top: 3rem !important;
  }

  .TokenFeed_mt-md-auto__2F5Dn {
    margin-top: auto !important;
  }

  .TokenFeed_me-md-0__2O6RP {
    margin-right: 0 !important;
  }

  .TokenFeed_me-md-1__yH4tX {
    margin-right: 0.25rem !important;
  }

  .TokenFeed_me-md-2__X5RrF {
    margin-right: 0.5rem !important;
  }

  .TokenFeed_me-md-3__2llQl {
    margin-right: 1rem !important;
  }

  .TokenFeed_me-md-4__2bBk2 {
    margin-right: 1.5rem !important;
  }

  .TokenFeed_me-md-5__MKfej {
    margin-right: 3rem !important;
  }

  .TokenFeed_me-md-auto__3SDlx {
    margin-right: auto !important;
  }

  .TokenFeed_mb-md-0__2CDjZ {
    margin-bottom: 0 !important;
  }

  .TokenFeed_mb-md-1__GcUUW {
    margin-bottom: 0.25rem !important;
  }

  .TokenFeed_mb-md-2__2f1JN {
    margin-bottom: 0.5rem !important;
  }

  .TokenFeed_mb-md-3__2QgtR {
    margin-bottom: 1rem !important;
  }

  .TokenFeed_mb-md-4__J6OaD {
    margin-bottom: 1.5rem !important;
  }

  .TokenFeed_mb-md-5__2IgUq {
    margin-bottom: 3rem !important;
  }

  .TokenFeed_mb-md-auto__13Czm {
    margin-bottom: auto !important;
  }

  .TokenFeed_ms-md-0__RcxmV {
    margin-left: 0 !important;
  }

  .TokenFeed_ms-md-1__g2YUM {
    margin-left: 0.25rem !important;
  }

  .TokenFeed_ms-md-2__KErmv {
    margin-left: 0.5rem !important;
  }

  .TokenFeed_ms-md-3__wsnub {
    margin-left: 1rem !important;
  }

  .TokenFeed_ms-md-4__Or1Dw {
    margin-left: 1.5rem !important;
  }

  .TokenFeed_ms-md-5__2I42X {
    margin-left: 3rem !important;
  }

  .TokenFeed_ms-md-auto__FZw-k {
    margin-left: auto !important;
  }

  .TokenFeed_p-md-0__1rZzo {
    padding: 0 !important;
  }

  .TokenFeed_p-md-1__4FN4y {
    padding: 0.25rem !important;
  }

  .TokenFeed_p-md-2__1ljW3 {
    padding: 0.5rem !important;
  }

  .TokenFeed_p-md-3__3Fqiq {
    padding: 1rem !important;
  }

  .TokenFeed_p-md-4__3fUjT {
    padding: 1.5rem !important;
  }

  .TokenFeed_p-md-5__17GjF {
    padding: 3rem !important;
  }

  .TokenFeed_px-md-0__37M5o {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .TokenFeed_px-md-1__1U6o2 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .TokenFeed_px-md-2__1LFea {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .TokenFeed_px-md-3__3hT8h {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .TokenFeed_px-md-4__8TcBF {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TokenFeed_px-md-5__hfjSM {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .TokenFeed_py-md-0__btN1j {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .TokenFeed_py-md-1__3ow1G {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .TokenFeed_py-md-2__Hbrs4 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .TokenFeed_py-md-3__2-ffT {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .TokenFeed_py-md-4__cKQc6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .TokenFeed_py-md-5__tB07P {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .TokenFeed_pt-md-0__3WW0W {
    padding-top: 0 !important;
  }

  .TokenFeed_pt-md-1__3WT0l {
    padding-top: 0.25rem !important;
  }

  .TokenFeed_pt-md-2__1LvEk {
    padding-top: 0.5rem !important;
  }

  .TokenFeed_pt-md-3__EWuX- {
    padding-top: 1rem !important;
  }

  .TokenFeed_pt-md-4__2t-QP {
    padding-top: 1.5rem !important;
  }

  .TokenFeed_pt-md-5__38Lik {
    padding-top: 3rem !important;
  }

  .TokenFeed_pe-md-0__dzgwl {
    padding-right: 0 !important;
  }

  .TokenFeed_pe-md-1__3i7KS {
    padding-right: 0.25rem !important;
  }

  .TokenFeed_pe-md-2__2ZJ2H {
    padding-right: 0.5rem !important;
  }

  .TokenFeed_pe-md-3__3Virq {
    padding-right: 1rem !important;
  }

  .TokenFeed_pe-md-4__ERobn {
    padding-right: 1.5rem !important;
  }

  .TokenFeed_pe-md-5__1VIWi {
    padding-right: 3rem !important;
  }

  .TokenFeed_pb-md-0__15dv3 {
    padding-bottom: 0 !important;
  }

  .TokenFeed_pb-md-1__1OoRS {
    padding-bottom: 0.25rem !important;
  }

  .TokenFeed_pb-md-2__3DyPN {
    padding-bottom: 0.5rem !important;
  }

  .TokenFeed_pb-md-3__xg6g0 {
    padding-bottom: 1rem !important;
  }

  .TokenFeed_pb-md-4__37VBT {
    padding-bottom: 1.5rem !important;
  }

  .TokenFeed_pb-md-5__DWy6W {
    padding-bottom: 3rem !important;
  }

  .TokenFeed_ps-md-0__2Is7g {
    padding-left: 0 !important;
  }

  .TokenFeed_ps-md-1__3CoXp {
    padding-left: 0.25rem !important;
  }

  .TokenFeed_ps-md-2__1SdQB {
    padding-left: 0.5rem !important;
  }

  .TokenFeed_ps-md-3__V2zO1 {
    padding-left: 1rem !important;
  }

  .TokenFeed_ps-md-4__-r7xa {
    padding-left: 1.5rem !important;
  }

  .TokenFeed_ps-md-5__3bFVD {
    padding-left: 3rem !important;
  }

  .TokenFeed_text-md-start__3nsPZ {
    text-align: left !important;
  }

  .TokenFeed_text-md-end__YdV2Y {
    text-align: right !important;
  }

  .TokenFeed_text-md-center__3RDBi {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .TokenFeed_float-lg-start__3iRel {
    float: left !important;
  }

  .TokenFeed_float-lg-end__1LfJ- {
    float: right !important;
  }

  .TokenFeed_float-lg-none__MG6KB {
    float: none !important;
  }

  .TokenFeed_d-lg-inline__TIlnD {
    display: inline !important;
  }

  .TokenFeed_d-lg-inline-block__3OoA_ {
    display: inline-block !important;
  }

  .TokenFeed_d-lg-block__1TFpt {
    display: block !important;
  }

  .TokenFeed_d-lg-grid__1Bhq8 {
    display: grid !important;
  }

  .TokenFeed_d-lg-table__1A2rj {
    display: table !important;
  }

  .TokenFeed_d-lg-table-row__2TPi3 {
    display: table-row !important;
  }

  .TokenFeed_d-lg-table-cell__2iea4 {
    display: table-cell !important;
  }

  .TokenFeed_d-lg-flex__4h0U_ {
    display: flex !important;
  }

  .TokenFeed_d-lg-inline-flex__1qBnh {
    display: inline-flex !important;
  }

  .TokenFeed_d-lg-none__1MXJp {
    display: none !important;
  }

  .TokenFeed_flex-lg-fill__3nu5m {
    flex: 1 1 auto !important;
  }

  .TokenFeed_flex-lg-row__1D-IV {
    flex-direction: row !important;
  }

  .TokenFeed_flex-lg-column__PC0c9 {
    flex-direction: column !important;
  }

  .TokenFeed_flex-lg-row-reverse__1ZjvQ {
    flex-direction: row-reverse !important;
  }

  .TokenFeed_flex-lg-column-reverse__1lNoM {
    flex-direction: column-reverse !important;
  }

  .TokenFeed_flex-lg-grow-0__HoMYO {
    flex-grow: 0 !important;
  }

  .TokenFeed_flex-lg-grow-1__2BTQP {
    flex-grow: 1 !important;
  }

  .TokenFeed_flex-lg-shrink-0__1hZRl {
    flex-shrink: 0 !important;
  }

  .TokenFeed_flex-lg-shrink-1__2Ai-c {
    flex-shrink: 1 !important;
  }

  .TokenFeed_flex-lg-wrap__1uIsW {
    flex-wrap: wrap !important;
  }

  .TokenFeed_flex-lg-nowrap__3Hs7i {
    flex-wrap: nowrap !important;
  }

  .TokenFeed_flex-lg-wrap-reverse__3KV7x {
    flex-wrap: wrap-reverse !important;
  }

  .TokenFeed_gap-lg-0__1hNhW {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .TokenFeed_gap-lg-1__2ZLs2 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .TokenFeed_gap-lg-2__3EF6w {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .TokenFeed_gap-lg-3__18kb0 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .TokenFeed_gap-lg-4__1iocX {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .TokenFeed_gap-lg-5__fUu_4 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .TokenFeed_justify-content-lg-start__ul5Qc {
    justify-content: flex-start !important;
  }

  .TokenFeed_justify-content-lg-end__1wsJS {
    justify-content: flex-end !important;
  }

  .TokenFeed_justify-content-lg-center__cuP5L {
    justify-content: center !important;
  }

  .TokenFeed_justify-content-lg-between__2PwVv {
    justify-content: space-between !important;
  }

  .TokenFeed_justify-content-lg-around__2s85j {
    justify-content: space-around !important;
  }

  .TokenFeed_justify-content-lg-evenly__3tbqJ {
    justify-content: space-evenly !important;
  }

  .TokenFeed_align-items-lg-start__2QTek {
    align-items: flex-start !important;
  }

  .TokenFeed_align-items-lg-end__2OFWa {
    align-items: flex-end !important;
  }

  .TokenFeed_align-items-lg-center__1EgDW {
    align-items: center !important;
  }

  .TokenFeed_align-items-lg-baseline__B_vFR {
    align-items: baseline !important;
  }

  .TokenFeed_align-items-lg-stretch__2B2_A {
    align-items: stretch !important;
  }

  .TokenFeed_align-content-lg-start__1EFxj {
    align-content: flex-start !important;
  }

  .TokenFeed_align-content-lg-end__2pvwz {
    align-content: flex-end !important;
  }

  .TokenFeed_align-content-lg-center__2O65h {
    align-content: center !important;
  }

  .TokenFeed_align-content-lg-between__1-ogT {
    align-content: space-between !important;
  }

  .TokenFeed_align-content-lg-around__2UwLB {
    align-content: space-around !important;
  }

  .TokenFeed_align-content-lg-stretch__2wBqV {
    align-content: stretch !important;
  }

  .TokenFeed_align-self-lg-auto__RjYZK {
    align-self: auto !important;
  }

  .TokenFeed_align-self-lg-start__3fwOX {
    align-self: flex-start !important;
  }

  .TokenFeed_align-self-lg-end__1bmic {
    align-self: flex-end !important;
  }

  .TokenFeed_align-self-lg-center__1SdKM {
    align-self: center !important;
  }

  .TokenFeed_align-self-lg-baseline__38PIA {
    align-self: baseline !important;
  }

  .TokenFeed_align-self-lg-stretch__3qdWh {
    align-self: stretch !important;
  }

  .TokenFeed_order-lg-first__3E-B_ {
    order: -1 !important;
  }

  .TokenFeed_order-lg-0__VG1cF {
    order: 0 !important;
  }

  .TokenFeed_order-lg-1__1oH8k {
    order: 1 !important;
  }

  .TokenFeed_order-lg-2__3wl7b {
    order: 2 !important;
  }

  .TokenFeed_order-lg-3__1COVg {
    order: 3 !important;
  }

  .TokenFeed_order-lg-4__2wM8C {
    order: 4 !important;
  }

  .TokenFeed_order-lg-5__3QXa1 {
    order: 5 !important;
  }

  .TokenFeed_order-lg-last__1KFXM {
    order: 6 !important;
  }

  .TokenFeed_m-lg-0__2H_bf {
    margin: 0 !important;
  }

  .TokenFeed_m-lg-1__2fCAm {
    margin: 0.25rem !important;
  }

  .TokenFeed_m-lg-2__3ENur {
    margin: 0.5rem !important;
  }

  .TokenFeed_m-lg-3__2PTkO {
    margin: 1rem !important;
  }

  .TokenFeed_m-lg-4__2mkTu {
    margin: 1.5rem !important;
  }

  .TokenFeed_m-lg-5__eDQzW {
    margin: 3rem !important;
  }

  .TokenFeed_m-lg-auto__3SZ2t {
    margin: auto !important;
  }

  .TokenFeed_mx-lg-0__wazoz {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .TokenFeed_mx-lg-1__2y4i2 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .TokenFeed_mx-lg-2__ER9xx {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .TokenFeed_mx-lg-3__3WM2J {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .TokenFeed_mx-lg-4__27Tep {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .TokenFeed_mx-lg-5__26ksR {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .TokenFeed_mx-lg-auto__2wPiJ {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .TokenFeed_my-lg-0__3G1rG {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .TokenFeed_my-lg-1__13KdO {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .TokenFeed_my-lg-2__3aGgg {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .TokenFeed_my-lg-3__3tS6s {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .TokenFeed_my-lg-4__2_dbV {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .TokenFeed_my-lg-5__2VOHX {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .TokenFeed_my-lg-auto__36BWk {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .TokenFeed_mt-lg-0__2x3qp {
    margin-top: 0 !important;
  }

  .TokenFeed_mt-lg-1__3JF3J {
    margin-top: 0.25rem !important;
  }

  .TokenFeed_mt-lg-2__1jdcp {
    margin-top: 0.5rem !important;
  }

  .TokenFeed_mt-lg-3__XQf2T {
    margin-top: 1rem !important;
  }

  .TokenFeed_mt-lg-4__cwV-h {
    margin-top: 1.5rem !important;
  }

  .TokenFeed_mt-lg-5__3WMmc {
    margin-top: 3rem !important;
  }

  .TokenFeed_mt-lg-auto__1_H9J {
    margin-top: auto !important;
  }

  .TokenFeed_me-lg-0__16ATm {
    margin-right: 0 !important;
  }

  .TokenFeed_me-lg-1__4BhO7 {
    margin-right: 0.25rem !important;
  }

  .TokenFeed_me-lg-2__4zSlc {
    margin-right: 0.5rem !important;
  }

  .TokenFeed_me-lg-3__2A29B {
    margin-right: 1rem !important;
  }

  .TokenFeed_me-lg-4__1sOLF {
    margin-right: 1.5rem !important;
  }

  .TokenFeed_me-lg-5__1fseF {
    margin-right: 3rem !important;
  }

  .TokenFeed_me-lg-auto__1oyWr {
    margin-right: auto !important;
  }

  .TokenFeed_mb-lg-0__1mS6E {
    margin-bottom: 0 !important;
  }

  .TokenFeed_mb-lg-1__2roS0 {
    margin-bottom: 0.25rem !important;
  }

  .TokenFeed_mb-lg-2__3bw8H {
    margin-bottom: 0.5rem !important;
  }

  .TokenFeed_mb-lg-3__27SuK {
    margin-bottom: 1rem !important;
  }

  .TokenFeed_mb-lg-4__2EITE {
    margin-bottom: 1.5rem !important;
  }

  .TokenFeed_mb-lg-5__3eQQF {
    margin-bottom: 3rem !important;
  }

  .TokenFeed_mb-lg-auto__iK3gK {
    margin-bottom: auto !important;
  }

  .TokenFeed_ms-lg-0__37KnQ {
    margin-left: 0 !important;
  }

  .TokenFeed_ms-lg-1__1jC33 {
    margin-left: 0.25rem !important;
  }

  .TokenFeed_ms-lg-2__1BENH {
    margin-left: 0.5rem !important;
  }

  .TokenFeed_ms-lg-3__37wxy {
    margin-left: 1rem !important;
  }

  .TokenFeed_ms-lg-4__1FPgQ {
    margin-left: 1.5rem !important;
  }

  .TokenFeed_ms-lg-5__sCjim {
    margin-left: 3rem !important;
  }

  .TokenFeed_ms-lg-auto__1Ow2A {
    margin-left: auto !important;
  }

  .TokenFeed_p-lg-0__Lf0SW {
    padding: 0 !important;
  }

  .TokenFeed_p-lg-1__BEDG8 {
    padding: 0.25rem !important;
  }

  .TokenFeed_p-lg-2__2K9iP {
    padding: 0.5rem !important;
  }

  .TokenFeed_p-lg-3__2rTh7 {
    padding: 1rem !important;
  }

  .TokenFeed_p-lg-4__MRXDl {
    padding: 1.5rem !important;
  }

  .TokenFeed_p-lg-5__3qtzQ {
    padding: 3rem !important;
  }

  .TokenFeed_px-lg-0__1Hkvs {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .TokenFeed_px-lg-1__Vlv8B {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .TokenFeed_px-lg-2__3HyIv {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .TokenFeed_px-lg-3__1R90d {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .TokenFeed_px-lg-4__1L4xz {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TokenFeed_px-lg-5__2unwq {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .TokenFeed_py-lg-0__2r93n {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .TokenFeed_py-lg-1__3BWKw {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .TokenFeed_py-lg-2__9Hxx_ {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .TokenFeed_py-lg-3__3pTJI {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .TokenFeed_py-lg-4__3d8_F {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .TokenFeed_py-lg-5__1BUU0 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .TokenFeed_pt-lg-0__3wque {
    padding-top: 0 !important;
  }

  .TokenFeed_pt-lg-1__2yNrI {
    padding-top: 0.25rem !important;
  }

  .TokenFeed_pt-lg-2__1XTGA {
    padding-top: 0.5rem !important;
  }

  .TokenFeed_pt-lg-3__LCh-I {
    padding-top: 1rem !important;
  }

  .TokenFeed_pt-lg-4__1qP-2 {
    padding-top: 1.5rem !important;
  }

  .TokenFeed_pt-lg-5__6J7oj {
    padding-top: 3rem !important;
  }

  .TokenFeed_pe-lg-0__3xFfx {
    padding-right: 0 !important;
  }

  .TokenFeed_pe-lg-1__28Kyl {
    padding-right: 0.25rem !important;
  }

  .TokenFeed_pe-lg-2__14M92 {
    padding-right: 0.5rem !important;
  }

  .TokenFeed_pe-lg-3__3hXwY {
    padding-right: 1rem !important;
  }

  .TokenFeed_pe-lg-4__UTjgo {
    padding-right: 1.5rem !important;
  }

  .TokenFeed_pe-lg-5__3O5c_ {
    padding-right: 3rem !important;
  }

  .TokenFeed_pb-lg-0__1H3nk {
    padding-bottom: 0 !important;
  }

  .TokenFeed_pb-lg-1__eh2UM {
    padding-bottom: 0.25rem !important;
  }

  .TokenFeed_pb-lg-2__1uRs1 {
    padding-bottom: 0.5rem !important;
  }

  .TokenFeed_pb-lg-3__2rxxW {
    padding-bottom: 1rem !important;
  }

  .TokenFeed_pb-lg-4__UDpxo {
    padding-bottom: 1.5rem !important;
  }

  .TokenFeed_pb-lg-5__2wmYg {
    padding-bottom: 3rem !important;
  }

  .TokenFeed_ps-lg-0__3lNUm {
    padding-left: 0 !important;
  }

  .TokenFeed_ps-lg-1__e2ou_ {
    padding-left: 0.25rem !important;
  }

  .TokenFeed_ps-lg-2__2YfTY {
    padding-left: 0.5rem !important;
  }

  .TokenFeed_ps-lg-3__fZfwZ {
    padding-left: 1rem !important;
  }

  .TokenFeed_ps-lg-4__31sC9 {
    padding-left: 1.5rem !important;
  }

  .TokenFeed_ps-lg-5__3Fnak {
    padding-left: 3rem !important;
  }

  .TokenFeed_text-lg-start__JrN-E {
    text-align: left !important;
  }

  .TokenFeed_text-lg-end__BqvQs {
    text-align: right !important;
  }

  .TokenFeed_text-lg-center__35HIK {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .TokenFeed_float-xl-start__u-HDU {
    float: left !important;
  }

  .TokenFeed_float-xl-end__1cC1y {
    float: right !important;
  }

  .TokenFeed_float-xl-none__2FzmI {
    float: none !important;
  }

  .TokenFeed_d-xl-inline__1soxW {
    display: inline !important;
  }

  .TokenFeed_d-xl-inline-block__20ZKo {
    display: inline-block !important;
  }

  .TokenFeed_d-xl-block__DIrxN {
    display: block !important;
  }

  .TokenFeed_d-xl-grid__1fiSN {
    display: grid !important;
  }

  .TokenFeed_d-xl-table__26IH5 {
    display: table !important;
  }

  .TokenFeed_d-xl-table-row__1N3fP {
    display: table-row !important;
  }

  .TokenFeed_d-xl-table-cell__O3gEt {
    display: table-cell !important;
  }

  .TokenFeed_d-xl-flex__Ug5yV {
    display: flex !important;
  }

  .TokenFeed_d-xl-inline-flex__1APbe {
    display: inline-flex !important;
  }

  .TokenFeed_d-xl-none__6fcTE {
    display: none !important;
  }

  .TokenFeed_flex-xl-fill__N96R3 {
    flex: 1 1 auto !important;
  }

  .TokenFeed_flex-xl-row__pORUl {
    flex-direction: row !important;
  }

  .TokenFeed_flex-xl-column__3Ga_m {
    flex-direction: column !important;
  }

  .TokenFeed_flex-xl-row-reverse__322kv {
    flex-direction: row-reverse !important;
  }

  .TokenFeed_flex-xl-column-reverse__9k5h7 {
    flex-direction: column-reverse !important;
  }

  .TokenFeed_flex-xl-grow-0__3S9xc {
    flex-grow: 0 !important;
  }

  .TokenFeed_flex-xl-grow-1__12gcS {
    flex-grow: 1 !important;
  }

  .TokenFeed_flex-xl-shrink-0__a9BJb {
    flex-shrink: 0 !important;
  }

  .TokenFeed_flex-xl-shrink-1__3zwxI {
    flex-shrink: 1 !important;
  }

  .TokenFeed_flex-xl-wrap__2TTv8 {
    flex-wrap: wrap !important;
  }

  .TokenFeed_flex-xl-nowrap__2HVHk {
    flex-wrap: nowrap !important;
  }

  .TokenFeed_flex-xl-wrap-reverse__2N_MN {
    flex-wrap: wrap-reverse !important;
  }

  .TokenFeed_gap-xl-0__1Trfe {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .TokenFeed_gap-xl-1__2Hf1v {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .TokenFeed_gap-xl-2__1H7lD {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .TokenFeed_gap-xl-3__3yYrp {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .TokenFeed_gap-xl-4__2K0mt {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .TokenFeed_gap-xl-5__6ZCfa {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .TokenFeed_justify-content-xl-start__1xMKd {
    justify-content: flex-start !important;
  }

  .TokenFeed_justify-content-xl-end__bSgey {
    justify-content: flex-end !important;
  }

  .TokenFeed_justify-content-xl-center__2Skh- {
    justify-content: center !important;
  }

  .TokenFeed_justify-content-xl-between__38cDJ {
    justify-content: space-between !important;
  }

  .TokenFeed_justify-content-xl-around__1fLd9 {
    justify-content: space-around !important;
  }

  .TokenFeed_justify-content-xl-evenly__1tQyS {
    justify-content: space-evenly !important;
  }

  .TokenFeed_align-items-xl-start___1LeF {
    align-items: flex-start !important;
  }

  .TokenFeed_align-items-xl-end__1GE2E {
    align-items: flex-end !important;
  }

  .TokenFeed_align-items-xl-center__JfNXE {
    align-items: center !important;
  }

  .TokenFeed_align-items-xl-baseline__PSBDl {
    align-items: baseline !important;
  }

  .TokenFeed_align-items-xl-stretch__2BiHG {
    align-items: stretch !important;
  }

  .TokenFeed_align-content-xl-start__twlTH {
    align-content: flex-start !important;
  }

  .TokenFeed_align-content-xl-end__3c7Gw {
    align-content: flex-end !important;
  }

  .TokenFeed_align-content-xl-center__Pa1o8 {
    align-content: center !important;
  }

  .TokenFeed_align-content-xl-between__DDc1y {
    align-content: space-between !important;
  }

  .TokenFeed_align-content-xl-around__2BvYd {
    align-content: space-around !important;
  }

  .TokenFeed_align-content-xl-stretch__fC0qM {
    align-content: stretch !important;
  }

  .TokenFeed_align-self-xl-auto__2Vvth {
    align-self: auto !important;
  }

  .TokenFeed_align-self-xl-start__3253Y {
    align-self: flex-start !important;
  }

  .TokenFeed_align-self-xl-end__1pUdT {
    align-self: flex-end !important;
  }

  .TokenFeed_align-self-xl-center__2tumB {
    align-self: center !important;
  }

  .TokenFeed_align-self-xl-baseline__1G699 {
    align-self: baseline !important;
  }

  .TokenFeed_align-self-xl-stretch__We1wl {
    align-self: stretch !important;
  }

  .TokenFeed_order-xl-first__1dJhj {
    order: -1 !important;
  }

  .TokenFeed_order-xl-0__Aum2z {
    order: 0 !important;
  }

  .TokenFeed_order-xl-1__389Yl {
    order: 1 !important;
  }

  .TokenFeed_order-xl-2__2IJtv {
    order: 2 !important;
  }

  .TokenFeed_order-xl-3__20HYR {
    order: 3 !important;
  }

  .TokenFeed_order-xl-4__3byK0 {
    order: 4 !important;
  }

  .TokenFeed_order-xl-5__-qD-1 {
    order: 5 !important;
  }

  .TokenFeed_order-xl-last__12w0h {
    order: 6 !important;
  }

  .TokenFeed_m-xl-0__1spLG {
    margin: 0 !important;
  }

  .TokenFeed_m-xl-1__3e0qP {
    margin: 0.25rem !important;
  }

  .TokenFeed_m-xl-2__2dqZA {
    margin: 0.5rem !important;
  }

  .TokenFeed_m-xl-3__3EsT- {
    margin: 1rem !important;
  }

  .TokenFeed_m-xl-4__89wne {
    margin: 1.5rem !important;
  }

  .TokenFeed_m-xl-5__3tG5D {
    margin: 3rem !important;
  }

  .TokenFeed_m-xl-auto__2nmDb {
    margin: auto !important;
  }

  .TokenFeed_mx-xl-0__3GsFn {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .TokenFeed_mx-xl-1__3AMpV {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .TokenFeed_mx-xl-2__1eUL- {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .TokenFeed_mx-xl-3__1b3gl {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .TokenFeed_mx-xl-4__38nkD {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .TokenFeed_mx-xl-5__1xd0W {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .TokenFeed_mx-xl-auto__smh3L {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .TokenFeed_my-xl-0__3SsU3 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .TokenFeed_my-xl-1__2NBqM {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .TokenFeed_my-xl-2__3L96W {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .TokenFeed_my-xl-3__3tdFE {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .TokenFeed_my-xl-4__2x6gF {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .TokenFeed_my-xl-5__24H-C {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .TokenFeed_my-xl-auto__1HyOc {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .TokenFeed_mt-xl-0__1FLXg {
    margin-top: 0 !important;
  }

  .TokenFeed_mt-xl-1__1Vby0 {
    margin-top: 0.25rem !important;
  }

  .TokenFeed_mt-xl-2__3argf {
    margin-top: 0.5rem !important;
  }

  .TokenFeed_mt-xl-3__1ouqg {
    margin-top: 1rem !important;
  }

  .TokenFeed_mt-xl-4__20KkC {
    margin-top: 1.5rem !important;
  }

  .TokenFeed_mt-xl-5__3hruX {
    margin-top: 3rem !important;
  }

  .TokenFeed_mt-xl-auto__3Phhz {
    margin-top: auto !important;
  }

  .TokenFeed_me-xl-0__1Wf4K {
    margin-right: 0 !important;
  }

  .TokenFeed_me-xl-1__3jU2T {
    margin-right: 0.25rem !important;
  }

  .TokenFeed_me-xl-2__2zv5V {
    margin-right: 0.5rem !important;
  }

  .TokenFeed_me-xl-3__3Nlwx {
    margin-right: 1rem !important;
  }

  .TokenFeed_me-xl-4__lTUan {
    margin-right: 1.5rem !important;
  }

  .TokenFeed_me-xl-5__6HPan {
    margin-right: 3rem !important;
  }

  .TokenFeed_me-xl-auto__73YwJ {
    margin-right: auto !important;
  }

  .TokenFeed_mb-xl-0__2jCDP {
    margin-bottom: 0 !important;
  }

  .TokenFeed_mb-xl-1__2Sr8H {
    margin-bottom: 0.25rem !important;
  }

  .TokenFeed_mb-xl-2__h4w_3 {
    margin-bottom: 0.5rem !important;
  }

  .TokenFeed_mb-xl-3__15yXL {
    margin-bottom: 1rem !important;
  }

  .TokenFeed_mb-xl-4__3GqnC {
    margin-bottom: 1.5rem !important;
  }

  .TokenFeed_mb-xl-5__3Cit8 {
    margin-bottom: 3rem !important;
  }

  .TokenFeed_mb-xl-auto__1pw5W {
    margin-bottom: auto !important;
  }

  .TokenFeed_ms-xl-0__13111 {
    margin-left: 0 !important;
  }

  .TokenFeed_ms-xl-1__1Dm3s {
    margin-left: 0.25rem !important;
  }

  .TokenFeed_ms-xl-2__1jFGk {
    margin-left: 0.5rem !important;
  }

  .TokenFeed_ms-xl-3__2QWvh {
    margin-left: 1rem !important;
  }

  .TokenFeed_ms-xl-4__1FsOs {
    margin-left: 1.5rem !important;
  }

  .TokenFeed_ms-xl-5__1xwqe {
    margin-left: 3rem !important;
  }

  .TokenFeed_ms-xl-auto__355xS {
    margin-left: auto !important;
  }

  .TokenFeed_p-xl-0__BSOuZ {
    padding: 0 !important;
  }

  .TokenFeed_p-xl-1__PAXTA {
    padding: 0.25rem !important;
  }

  .TokenFeed_p-xl-2__2vTpE {
    padding: 0.5rem !important;
  }

  .TokenFeed_p-xl-3__1eA3Z {
    padding: 1rem !important;
  }

  .TokenFeed_p-xl-4__2Yo4l {
    padding: 1.5rem !important;
  }

  .TokenFeed_p-xl-5__1PDlf {
    padding: 3rem !important;
  }

  .TokenFeed_px-xl-0__1i5qq {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .TokenFeed_px-xl-1__2lYP8 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .TokenFeed_px-xl-2__3mkKF {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .TokenFeed_px-xl-3__3_uCZ {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .TokenFeed_px-xl-4__Wu0g5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TokenFeed_px-xl-5__3LYda {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .TokenFeed_py-xl-0__2o0Ig {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .TokenFeed_py-xl-1__bw40A {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .TokenFeed_py-xl-2__ZTKqP {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .TokenFeed_py-xl-3__WP97N {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .TokenFeed_py-xl-4__3dHZS {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .TokenFeed_py-xl-5__17a_w {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .TokenFeed_pt-xl-0__2lK7R {
    padding-top: 0 !important;
  }

  .TokenFeed_pt-xl-1__1reFi {
    padding-top: 0.25rem !important;
  }

  .TokenFeed_pt-xl-2__rScCx {
    padding-top: 0.5rem !important;
  }

  .TokenFeed_pt-xl-3__2G-dC {
    padding-top: 1rem !important;
  }

  .TokenFeed_pt-xl-4__h55BY {
    padding-top: 1.5rem !important;
  }

  .TokenFeed_pt-xl-5__TcB70 {
    padding-top: 3rem !important;
  }

  .TokenFeed_pe-xl-0__1mXcV {
    padding-right: 0 !important;
  }

  .TokenFeed_pe-xl-1__MyrWO {
    padding-right: 0.25rem !important;
  }

  .TokenFeed_pe-xl-2__12TC7 {
    padding-right: 0.5rem !important;
  }

  .TokenFeed_pe-xl-3__3Njo4 {
    padding-right: 1rem !important;
  }

  .TokenFeed_pe-xl-4__zC_pu {
    padding-right: 1.5rem !important;
  }

  .TokenFeed_pe-xl-5__ScVi_ {
    padding-right: 3rem !important;
  }

  .TokenFeed_pb-xl-0__275tR {
    padding-bottom: 0 !important;
  }

  .TokenFeed_pb-xl-1__3uAwD {
    padding-bottom: 0.25rem !important;
  }

  .TokenFeed_pb-xl-2__128Oo {
    padding-bottom: 0.5rem !important;
  }

  .TokenFeed_pb-xl-3__I3xro {
    padding-bottom: 1rem !important;
  }

  .TokenFeed_pb-xl-4__1Topo {
    padding-bottom: 1.5rem !important;
  }

  .TokenFeed_pb-xl-5__WjRSk {
    padding-bottom: 3rem !important;
  }

  .TokenFeed_ps-xl-0__1kP2b {
    padding-left: 0 !important;
  }

  .TokenFeed_ps-xl-1__MdKax {
    padding-left: 0.25rem !important;
  }

  .TokenFeed_ps-xl-2__iyoGc {
    padding-left: 0.5rem !important;
  }

  .TokenFeed_ps-xl-3__3u_PA {
    padding-left: 1rem !important;
  }

  .TokenFeed_ps-xl-4__vQd69 {
    padding-left: 1.5rem !important;
  }

  .TokenFeed_ps-xl-5__xlLwL {
    padding-left: 3rem !important;
  }

  .TokenFeed_text-xl-start__3nwmV {
    text-align: left !important;
  }

  .TokenFeed_text-xl-end__5XpiW {
    text-align: right !important;
  }

  .TokenFeed_text-xl-center__3wdGk {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .TokenFeed_float-xxl-start__RCoUA {
    float: left !important;
  }

  .TokenFeed_float-xxl-end__31rqq {
    float: right !important;
  }

  .TokenFeed_float-xxl-none__JVaFg {
    float: none !important;
  }

  .TokenFeed_d-xxl-inline__S_ja_ {
    display: inline !important;
  }

  .TokenFeed_d-xxl-inline-block__aPYUw {
    display: inline-block !important;
  }

  .TokenFeed_d-xxl-block__Fqfww {
    display: block !important;
  }

  .TokenFeed_d-xxl-grid__1728X {
    display: grid !important;
  }

  .TokenFeed_d-xxl-table__2UmQV {
    display: table !important;
  }

  .TokenFeed_d-xxl-table-row__3djD5 {
    display: table-row !important;
  }

  .TokenFeed_d-xxl-table-cell__JCIEb {
    display: table-cell !important;
  }

  .TokenFeed_d-xxl-flex__3tbRj {
    display: flex !important;
  }

  .TokenFeed_d-xxl-inline-flex__wyvJm {
    display: inline-flex !important;
  }

  .TokenFeed_d-xxl-none__3BMPT {
    display: none !important;
  }

  .TokenFeed_flex-xxl-fill__2dDWh {
    flex: 1 1 auto !important;
  }

  .TokenFeed_flex-xxl-row__1weoJ {
    flex-direction: row !important;
  }

  .TokenFeed_flex-xxl-column__r4rT6 {
    flex-direction: column !important;
  }

  .TokenFeed_flex-xxl-row-reverse__1AOti {
    flex-direction: row-reverse !important;
  }

  .TokenFeed_flex-xxl-column-reverse__FRRcu {
    flex-direction: column-reverse !important;
  }

  .TokenFeed_flex-xxl-grow-0__2k0nf {
    flex-grow: 0 !important;
  }

  .TokenFeed_flex-xxl-grow-1__Ou16w {
    flex-grow: 1 !important;
  }

  .TokenFeed_flex-xxl-shrink-0__PTLyu {
    flex-shrink: 0 !important;
  }

  .TokenFeed_flex-xxl-shrink-1__12j22 {
    flex-shrink: 1 !important;
  }

  .TokenFeed_flex-xxl-wrap__3_MTx {
    flex-wrap: wrap !important;
  }

  .TokenFeed_flex-xxl-nowrap__3vBVv {
    flex-wrap: nowrap !important;
  }

  .TokenFeed_flex-xxl-wrap-reverse__eINQ0 {
    flex-wrap: wrap-reverse !important;
  }

  .TokenFeed_gap-xxl-0__34jcB {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .TokenFeed_gap-xxl-1__2UqpW {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .TokenFeed_gap-xxl-2__3PlHa {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .TokenFeed_gap-xxl-3__2JL9e {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .TokenFeed_gap-xxl-4__14JOu {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .TokenFeed_gap-xxl-5__TZdPs {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .TokenFeed_justify-content-xxl-start__3av96 {
    justify-content: flex-start !important;
  }

  .TokenFeed_justify-content-xxl-end__oS-33 {
    justify-content: flex-end !important;
  }

  .TokenFeed_justify-content-xxl-center__10pV_ {
    justify-content: center !important;
  }

  .TokenFeed_justify-content-xxl-between__1veur {
    justify-content: space-between !important;
  }

  .TokenFeed_justify-content-xxl-around__3Nf40 {
    justify-content: space-around !important;
  }

  .TokenFeed_justify-content-xxl-evenly__1vlxV {
    justify-content: space-evenly !important;
  }

  .TokenFeed_align-items-xxl-start__2aXOX {
    align-items: flex-start !important;
  }

  .TokenFeed_align-items-xxl-end__39n-F {
    align-items: flex-end !important;
  }

  .TokenFeed_align-items-xxl-center__2Nb9p {
    align-items: center !important;
  }

  .TokenFeed_align-items-xxl-baseline__3d-YF {
    align-items: baseline !important;
  }

  .TokenFeed_align-items-xxl-stretch__v_BQS {
    align-items: stretch !important;
  }

  .TokenFeed_align-content-xxl-start__1GMk8 {
    align-content: flex-start !important;
  }

  .TokenFeed_align-content-xxl-end__1vCqX {
    align-content: flex-end !important;
  }

  .TokenFeed_align-content-xxl-center__3srDR {
    align-content: center !important;
  }

  .TokenFeed_align-content-xxl-between__3tXgT {
    align-content: space-between !important;
  }

  .TokenFeed_align-content-xxl-around__2YA9H {
    align-content: space-around !important;
  }

  .TokenFeed_align-content-xxl-stretch__usSsG {
    align-content: stretch !important;
  }

  .TokenFeed_align-self-xxl-auto__TcSis {
    align-self: auto !important;
  }

  .TokenFeed_align-self-xxl-start__x_Oh0 {
    align-self: flex-start !important;
  }

  .TokenFeed_align-self-xxl-end__1YgWk {
    align-self: flex-end !important;
  }

  .TokenFeed_align-self-xxl-center__18q8B {
    align-self: center !important;
  }

  .TokenFeed_align-self-xxl-baseline__2jbrf {
    align-self: baseline !important;
  }

  .TokenFeed_align-self-xxl-stretch__19SCx {
    align-self: stretch !important;
  }

  .TokenFeed_order-xxl-first__2G7u1 {
    order: -1 !important;
  }

  .TokenFeed_order-xxl-0__2BF4k {
    order: 0 !important;
  }

  .TokenFeed_order-xxl-1__t6roB {
    order: 1 !important;
  }

  .TokenFeed_order-xxl-2__RP0YE {
    order: 2 !important;
  }

  .TokenFeed_order-xxl-3__1giCf {
    order: 3 !important;
  }

  .TokenFeed_order-xxl-4__1URkE {
    order: 4 !important;
  }

  .TokenFeed_order-xxl-5__1NXh2 {
    order: 5 !important;
  }

  .TokenFeed_order-xxl-last__3Gdav {
    order: 6 !important;
  }

  .TokenFeed_m-xxl-0__Qh3Aa {
    margin: 0 !important;
  }

  .TokenFeed_m-xxl-1__3BdSt {
    margin: 0.25rem !important;
  }

  .TokenFeed_m-xxl-2__cZoMj {
    margin: 0.5rem !important;
  }

  .TokenFeed_m-xxl-3__2K6od {
    margin: 1rem !important;
  }

  .TokenFeed_m-xxl-4__1pPa1 {
    margin: 1.5rem !important;
  }

  .TokenFeed_m-xxl-5__27V4L {
    margin: 3rem !important;
  }

  .TokenFeed_m-xxl-auto__2YKG8 {
    margin: auto !important;
  }

  .TokenFeed_mx-xxl-0__2CVpm {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .TokenFeed_mx-xxl-1__3tJcf {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .TokenFeed_mx-xxl-2__1_8pt {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .TokenFeed_mx-xxl-3__Mqiyu {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .TokenFeed_mx-xxl-4__3mYiH {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .TokenFeed_mx-xxl-5__3D1br {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .TokenFeed_mx-xxl-auto__3d5A7 {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .TokenFeed_my-xxl-0__3AcaU {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .TokenFeed_my-xxl-1__imuXJ {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .TokenFeed_my-xxl-2__1kl9h {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .TokenFeed_my-xxl-3__2v2oQ {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .TokenFeed_my-xxl-4__3q5mb {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .TokenFeed_my-xxl-5__3vl_Z {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .TokenFeed_my-xxl-auto__2QuCw {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .TokenFeed_mt-xxl-0__1gk0D {
    margin-top: 0 !important;
  }

  .TokenFeed_mt-xxl-1__2QORj {
    margin-top: 0.25rem !important;
  }

  .TokenFeed_mt-xxl-2__14pzk {
    margin-top: 0.5rem !important;
  }

  .TokenFeed_mt-xxl-3__2_DM- {
    margin-top: 1rem !important;
  }

  .TokenFeed_mt-xxl-4__18JJq {
    margin-top: 1.5rem !important;
  }

  .TokenFeed_mt-xxl-5__1V80L {
    margin-top: 3rem !important;
  }

  .TokenFeed_mt-xxl-auto__1bl99 {
    margin-top: auto !important;
  }

  .TokenFeed_me-xxl-0__xI8aT {
    margin-right: 0 !important;
  }

  .TokenFeed_me-xxl-1__18x5M {
    margin-right: 0.25rem !important;
  }

  .TokenFeed_me-xxl-2__1hpAv {
    margin-right: 0.5rem !important;
  }

  .TokenFeed_me-xxl-3__1K8ix {
    margin-right: 1rem !important;
  }

  .TokenFeed_me-xxl-4__3Yj69 {
    margin-right: 1.5rem !important;
  }

  .TokenFeed_me-xxl-5__2Hhp1 {
    margin-right: 3rem !important;
  }

  .TokenFeed_me-xxl-auto__2DZ7J {
    margin-right: auto !important;
  }

  .TokenFeed_mb-xxl-0__3cGOM {
    margin-bottom: 0 !important;
  }

  .TokenFeed_mb-xxl-1__3I10O {
    margin-bottom: 0.25rem !important;
  }

  .TokenFeed_mb-xxl-2__3CVMj {
    margin-bottom: 0.5rem !important;
  }

  .TokenFeed_mb-xxl-3__10ktB {
    margin-bottom: 1rem !important;
  }

  .TokenFeed_mb-xxl-4__1vp6D {
    margin-bottom: 1.5rem !important;
  }

  .TokenFeed_mb-xxl-5__bN4RA {
    margin-bottom: 3rem !important;
  }

  .TokenFeed_mb-xxl-auto__1JoVK {
    margin-bottom: auto !important;
  }

  .TokenFeed_ms-xxl-0__BT3np {
    margin-left: 0 !important;
  }

  .TokenFeed_ms-xxl-1__2FH5h {
    margin-left: 0.25rem !important;
  }

  .TokenFeed_ms-xxl-2__20Jzm {
    margin-left: 0.5rem !important;
  }

  .TokenFeed_ms-xxl-3__1B77M {
    margin-left: 1rem !important;
  }

  .TokenFeed_ms-xxl-4__3ZmaM {
    margin-left: 1.5rem !important;
  }

  .TokenFeed_ms-xxl-5__2pCPR {
    margin-left: 3rem !important;
  }

  .TokenFeed_ms-xxl-auto__2Jdse {
    margin-left: auto !important;
  }

  .TokenFeed_p-xxl-0__8x_i7 {
    padding: 0 !important;
  }

  .TokenFeed_p-xxl-1__1HjXt {
    padding: 0.25rem !important;
  }

  .TokenFeed_p-xxl-2__2EpvS {
    padding: 0.5rem !important;
  }

  .TokenFeed_p-xxl-3__214ct {
    padding: 1rem !important;
  }

  .TokenFeed_p-xxl-4__1eLoO {
    padding: 1.5rem !important;
  }

  .TokenFeed_p-xxl-5__1Mpii {
    padding: 3rem !important;
  }

  .TokenFeed_px-xxl-0__1hHZ1 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .TokenFeed_px-xxl-1__4QOAu {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .TokenFeed_px-xxl-2__-JmZ5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .TokenFeed_px-xxl-3__1iox_ {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .TokenFeed_px-xxl-4__1ezaZ {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TokenFeed_px-xxl-5__24KWR {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .TokenFeed_py-xxl-0__3x4SH {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .TokenFeed_py-xxl-1__3Cmxs {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .TokenFeed_py-xxl-2__1bF4x {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .TokenFeed_py-xxl-3__1Ntxf {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .TokenFeed_py-xxl-4__3_gZr {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .TokenFeed_py-xxl-5__2uGMs {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .TokenFeed_pt-xxl-0__337W5 {
    padding-top: 0 !important;
  }

  .TokenFeed_pt-xxl-1__2SKZ- {
    padding-top: 0.25rem !important;
  }

  .TokenFeed_pt-xxl-2__2PRCL {
    padding-top: 0.5rem !important;
  }

  .TokenFeed_pt-xxl-3__38oa3 {
    padding-top: 1rem !important;
  }

  .TokenFeed_pt-xxl-4__uD6L8 {
    padding-top: 1.5rem !important;
  }

  .TokenFeed_pt-xxl-5__2hAgS {
    padding-top: 3rem !important;
  }

  .TokenFeed_pe-xxl-0__10vz8 {
    padding-right: 0 !important;
  }

  .TokenFeed_pe-xxl-1__2OgDC {
    padding-right: 0.25rem !important;
  }

  .TokenFeed_pe-xxl-2__2nmRc {
    padding-right: 0.5rem !important;
  }

  .TokenFeed_pe-xxl-3__SVtMi {
    padding-right: 1rem !important;
  }

  .TokenFeed_pe-xxl-4__2v2iN {
    padding-right: 1.5rem !important;
  }

  .TokenFeed_pe-xxl-5__sqMH4 {
    padding-right: 3rem !important;
  }

  .TokenFeed_pb-xxl-0__2STy3 {
    padding-bottom: 0 !important;
  }

  .TokenFeed_pb-xxl-1__2iPsb {
    padding-bottom: 0.25rem !important;
  }

  .TokenFeed_pb-xxl-2__2YjGu {
    padding-bottom: 0.5rem !important;
  }

  .TokenFeed_pb-xxl-3__1YtIl {
    padding-bottom: 1rem !important;
  }

  .TokenFeed_pb-xxl-4__3Ox-U {
    padding-bottom: 1.5rem !important;
  }

  .TokenFeed_pb-xxl-5__3Wm2q {
    padding-bottom: 3rem !important;
  }

  .TokenFeed_ps-xxl-0__16M08 {
    padding-left: 0 !important;
  }

  .TokenFeed_ps-xxl-1__3M6PQ {
    padding-left: 0.25rem !important;
  }

  .TokenFeed_ps-xxl-2__Bby6z {
    padding-left: 0.5rem !important;
  }

  .TokenFeed_ps-xxl-3__38LdL {
    padding-left: 1rem !important;
  }

  .TokenFeed_ps-xxl-4__2n7AW {
    padding-left: 1.5rem !important;
  }

  .TokenFeed_ps-xxl-5__17YVc {
    padding-left: 3rem !important;
  }

  .TokenFeed_text-xxl-start__5D75q {
    text-align: left !important;
  }

  .TokenFeed_text-xxl-end__1A952 {
    text-align: right !important;
  }

  .TokenFeed_text-xxl-center__2BjjB {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .TokenFeed_fs-1__2osQa {
    font-size: 2.5rem !important;
  }

  .TokenFeed_fs-2__1FKPB {
    font-size: 2rem !important;
  }

  .TokenFeed_fs-3__JaeeJ {
    font-size: 1.75rem !important;
  }

  .TokenFeed_fs-4__1zJnz {
    font-size: 1.5rem !important;
  }
}

@media print {
  .TokenFeed_d-print-inline__2323y {
    display: inline !important;
  }

  .TokenFeed_d-print-inline-block__2sDsm {
    display: inline-block !important;
  }

  .TokenFeed_d-print-block__2lawR {
    display: block !important;
  }

  .TokenFeed_d-print-grid__3gmOO {
    display: grid !important;
  }

  .TokenFeed_d-print-table__2bQ4O {
    display: table !important;
  }

  .TokenFeed_d-print-table-row__qqHt8 {
    display: table-row !important;
  }

  .TokenFeed_d-print-table-cell__3cG5y {
    display: table-cell !important;
  }

  .TokenFeed_d-print-flex__26h-7 {
    display: flex !important;
  }

  .TokenFeed_d-print-inline-flex__DFl7n {
    display: inline-flex !important;
  }

  .TokenFeed_d-print-none__1nzCi {
    display: none !important;
  }
}

.TokenFeed_tokenFeed__10Pbr .ReactVirtualized__Table__rowColumn {
  border: none;
  line-height: normal;
}

.TokenFeed_tokenFeed__10Pbr .text-danger .text-muted {
  color: rgba(220, 53, 69, 0.8) !important;
}

.TokenFeed_tokenFeed__10Pbr .text-success .text-muted {
  color: rgba(40, 167, 69, 0.8) !important;
}

/*!
 * Bootstrap Utilities v5.0.0-beta2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.TokenChart_clearfix__1FBb5:after {
  display: block;
  clear: both;
  content: "";
}

.TokenChart_link-primary__3YlKH {
  color: #a4ff66;
}

.TokenChart_link-primary__3YlKH:focus,
.TokenChart_link-primary__3YlKH:hover {
  color: #65c6ff;
}

.TokenChart_link-secondary__2llc1 {
  color: #6c757d;
}

.TokenChart_link-secondary__2llc1:focus,
.TokenChart_link-secondary__2llc1:hover {
  color: #565e64;
}

.TokenChart_link-success__2er7v {
  color: #28a745;
}

.TokenChart_link-success__2er7v:focus,
.TokenChart_link-success__2er7v:hover {
  color: #53b96a;
}

.TokenChart_link-info__1SXPK {
  color: #17a2b8;
}

.TokenChart_link-info__1SXPK:focus,
.TokenChart_link-info__1SXPK:hover {
  color: #45b5c6;
}

.TokenChart_link-warning__15fCG {
  color: #f7b500;
}

.TokenChart_link-warning__15fCG:focus,
.TokenChart_link-warning__15fCG:hover {
  color: #f9c433;
}

.TokenChart_link-danger__3o4-t {
  color: #dc3545;
}

.TokenChart_link-danger__3o4-t:focus,
.TokenChart_link-danger__3o4-t:hover {
  color: #b02a37;
}

.TokenChart_link-light__unsoa {
  color: #f8f9fa;
}

.TokenChart_link-light__unsoa:focus,
.TokenChart_link-light__unsoa:hover {
  color: #f9fafb;
}

.TokenChart_link-dark__2S4kc {
  color: #0f1a1c;
}

.TokenChart_link-dark__2S4kc:focus,
.TokenChart_link-dark__2S4kc:hover {
  color: #1e1e1e;
}

.TokenChart_link-dark-1__kWY3N {
  color: #303032;
}

.TokenChart_link-dark-1__kWY3N:focus,
.TokenChart_link-dark-1__kWY3N:hover {
  color: #262628;
}

.TokenChart_link-dark-2__2I8zI {
  color: #383838;
}

.TokenChart_link-dark-2__2I8zI:focus,
.TokenChart_link-dark-2__2I8zI:hover {
  color: #2d2d2d;
}

.TokenChart_link-blue-dark__3Z4Ig {
  color: #141722;
}

.TokenChart_link-blue-dark__3Z4Ig:focus,
.TokenChart_link-blue-dark__3Z4Ig:hover {
  color: #10121b;
}

.TokenChart_ratio__4N1Zi {
  position: relative;
  width: 100%;
}

.TokenChart_ratio__4N1Zi:before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.TokenChart_ratio__4N1Zi > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.TokenChart_ratio-1x1__3e23O {
  --bs-aspect-ratio: 100%;
}

.TokenChart_ratio-4x3__14YcZ {
  --bs-aspect-ratio: 75%;
}

.TokenChart_ratio-16x9__2cdXq {
  --bs-aspect-ratio: 56.25%;
}

.TokenChart_ratio-21x9__2VXvZ {
  --bs-aspect-ratio: 42.85714%;
}

.TokenChart_fixed-top__3xQAh {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.TokenChart_fixed-bottom__3k6i_ {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.TokenChart_sticky-top__1Kml6 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .TokenChart_sticky-sm-top__FmrzS {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .TokenChart_sticky-md-top__2q4wm {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .TokenChart_sticky-lg-top__3tgLM {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .TokenChart_sticky-xl-top__32sWv {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .TokenChart_sticky-xxl-top__2TgGW {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.TokenChart_visually-hidden-focusable__fk601:not(:focus):not(:focus-within),
.TokenChart_visually-hidden__3zCV8 {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.TokenChart_stretched-link__1kfqq:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.TokenChart_text-truncate__z-4wS {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TokenChart_align-baseline__2adAg {
  vertical-align: baseline !important;
}

.TokenChart_align-top__BLJGy {
  vertical-align: top !important;
}

.TokenChart_align-middle__2EmCL {
  vertical-align: middle !important;
}

.TokenChart_align-bottom__FizCY {
  vertical-align: bottom !important;
}

.TokenChart_align-text-bottom__khSyh {
  vertical-align: text-bottom !important;
}

.TokenChart_align-text-top__C7RXY {
  vertical-align: text-top !important;
}

.TokenChart_float-start__467Hd {
  float: left !important;
}

.TokenChart_float-end__I9T_s {
  float: right !important;
}

.TokenChart_float-none__2v2ZB {
  float: none !important;
}

.TokenChart_overflow-auto__10x5P {
  overflow: auto !important;
}

.TokenChart_overflow-hidden__3g2fj {
  overflow: hidden !important;
}

.TokenChart_overflow-visible__ii7A7 {
  overflow: visible !important;
}

.TokenChart_overflow-scroll__3wKZ3 {
  overflow: scroll !important;
}

.TokenChart_d-inline__1rlc0 {
  display: inline !important;
}

.TokenChart_d-inline-block__1rVA- {
  display: inline-block !important;
}

.TokenChart_d-block__3EH2r {
  display: block !important;
}

.TokenChart_d-grid__3e2Ig {
  display: grid !important;
}

.TokenChart_d-table__2z4VH {
  display: table !important;
}

.TokenChart_d-table-row__yK2Y2 {
  display: table-row !important;
}

.TokenChart_d-table-cell__aAWjK {
  display: table-cell !important;
}

.TokenChart_d-flex__2c2rc {
  display: flex !important;
}

.TokenChart_d-inline-flex__1vqGR {
  display: inline-flex !important;
}

.TokenChart_d-none__20Aqg {
  display: none !important;
}

.TokenChart_shadow__3SBKF {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.TokenChart_shadow-sm__3d-K4 {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.TokenChart_shadow-lg__lm5jy {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.TokenChart_shadow-none__1Lp98 {
  box-shadow: none !important;
}

.TokenChart_position-static__3ar4z {
  position: static !important;
}

.TokenChart_position-relative__1CtJN {
  position: relative !important;
}

.TokenChart_position-absolute__j1Dcy {
  position: absolute !important;
}

.TokenChart_position-fixed__QWP-2 {
  position: fixed !important;
}

.TokenChart_position-sticky__1IXaV {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.TokenChart_top-0__1v77- {
  top: 0 !important;
}

.TokenChart_top-50__24Ppj {
  top: 50% !important;
}

.TokenChart_top-100__34TLC {
  top: 100% !important;
}

.TokenChart_bottom-0__3yXQl {
  bottom: 0 !important;
}

.TokenChart_bottom-50__1IhF0 {
  bottom: 50% !important;
}

.TokenChart_bottom-100__1fwGR {
  bottom: 100% !important;
}

.TokenChart_start-0__3fpLO {
  left: 0 !important;
}

.TokenChart_start-50__1GKYF {
  left: 50% !important;
}

.TokenChart_start-100__1tZZl {
  left: 100% !important;
}

.TokenChart_end-0__2Re_L {
  right: 0 !important;
}

.TokenChart_end-50__2kre8 {
  right: 50% !important;
}

.TokenChart_end-100__2EchQ {
  right: 100% !important;
}

.TokenChart_translate-middle__34ogH {
  transform: translate(-50%, -50%) !important;
}

.TokenChart_translate-middle-x__2LgAT {
  transform: translateX(-50%) !important;
}

.TokenChart_translate-middle-y__3Gs8Z {
  transform: translateY(-50%) !important;
}

.TokenChart_border__2erQB {
  border: 1px solid #0f1a1c !important;
}

.TokenChart_border-0__1mOKN {
  border: 0 !important;
}

.TokenChart_border-top__3KbLW {
  border-top: 1px solid #0f1a1c !important;
}

.TokenChart_border-top-0__2FYSt {
  border-top: 0 !important;
}

.TokenChart_border-end__3W5wJ {
  border-right: 1px solid #0f1a1c !important;
}

.TokenChart_border-end-0__3X6sL {
  border-right: 0 !important;
}

.TokenChart_border-bottom__JF-dU {
  border-bottom: 1px solid #0f1a1c !important;
}

.TokenChart_border-bottom-0__1eiAB {
  border-bottom: 0 !important;
}

.TokenChart_border-start__1Sg-k {
  border-left: 1px solid #0f1a1c !important;
}

.TokenChart_border-start-0__10aly {
  border-left: 0 !important;
}

.TokenChart_border-primary__cmi80 {
  border-color: #a4ff66 !important;
}

.TokenChart_border-secondary__3TKHD {
  border-color: #6c757d !important;
}

.TokenChart_border-success__3q3Rv {
  border-color: #28a745 !important;
}

.TokenChart_border-info__1QI_s {
  border-color: #17a2b8 !important;
}

.TokenChart_border-warning__31DiG {
  border-color: #f7b500 !important;
}

.TokenChart_border-danger__3LB5L {
  border-color: #dc3545 !important;
}

.TokenChart_border-light__1XzSw {
  border-color: #f8f9fa !important;
}

.TokenChart_border-dark__2giBK {
  border-color: #0f1a1c !important;
}

.TokenChart_border-dark-1__2CA9l {
  border-color: #303032 !important;
}

.TokenChart_border-dark-2__3cn8C {
  border-color: #383838 !important;
}

.TokenChart_border-blue-dark__2Ylv9 {
  border-color: #141722 !important;
}

.TokenChart_border-white__2Uu7z {
  border-color: #fff !important;
}

.TokenChart_border-0__1mOKN {
  border-width: 0 !important;
}

.TokenChart_border-1__2E_zG {
  border-width: 1px !important;
}

.TokenChart_border-2__3sB3z {
  border-width: 2px !important;
}

.TokenChart_border-3__2hXD- {
  border-width: 3px !important;
}

.TokenChart_border-4__26h66 {
  border-width: 4px !important;
}

.TokenChart_border-5__HPU5R {
  border-width: 5px !important;
}

.TokenChart_w-25__10dUP {
  width: 25% !important;
}

.TokenChart_w-50__IJLjg {
  width: 50% !important;
}

.TokenChart_w-75__24prJ {
  width: 75% !important;
}

.TokenChart_w-100__1ujPt {
  width: 100% !important;
}

.TokenChart_w-auto__1KOCi {
  width: auto !important;
}

.TokenChart_mw-100__iVXgb {
  max-width: 100% !important;
}

.TokenChart_vw-100__2Hooe {
  width: 100vw !important;
}

.TokenChart_min-vw-100__1QzaD {
  min-width: 100vw !important;
}

.TokenChart_h-25__1dj_y {
  height: 25% !important;
}

.TokenChart_h-50__nzpA6 {
  height: 50% !important;
}

.TokenChart_h-75___SSJw {
  height: 75% !important;
}

.TokenChart_h-100__2Q-vM {
  height: 100% !important;
}

.TokenChart_h-auto__3DR35 {
  height: auto !important;
}

.TokenChart_mh-100__26DfA {
  max-height: 100% !important;
}

.TokenChart_vh-100__3EnkQ {
  height: 100vh !important;
}

.TokenChart_min-vh-100__arFQ_ {
  min-height: 100vh !important;
}

.TokenChart_flex-fill__2m7Mk {
  flex: 1 1 auto !important;
}

.TokenChart_flex-row__1ZoEY {
  flex-direction: row !important;
}

.TokenChart_flex-column__IkwoY {
  flex-direction: column !important;
}

.TokenChart_flex-row-reverse__1CaF0 {
  flex-direction: row-reverse !important;
}

.TokenChart_flex-column-reverse__WJYPr {
  flex-direction: column-reverse !important;
}

.TokenChart_flex-grow-0__3niEG {
  flex-grow: 0 !important;
}

.TokenChart_flex-grow-1__3U8S7 {
  flex-grow: 1 !important;
}

.TokenChart_flex-shrink-0__2VKLp {
  flex-shrink: 0 !important;
}

.TokenChart_flex-shrink-1__UHknQ {
  flex-shrink: 1 !important;
}

.TokenChart_flex-wrap__2Ru4Z {
  flex-wrap: wrap !important;
}

.TokenChart_flex-nowrap__1_Vfk {
  flex-wrap: nowrap !important;
}

.TokenChart_flex-wrap-reverse__1Z-Ak {
  flex-wrap: wrap-reverse !important;
}

.TokenChart_gap-0__2meyy {
  grid-gap: 0 !important;
  gap: 0 !important;
}

.TokenChart_gap-1__1dAiw {
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important;
}

.TokenChart_gap-2__1svLu {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.TokenChart_gap-3__3DLKb {
  grid-gap: 1rem !important;
  gap: 1rem !important;
}

.TokenChart_gap-4__2gkuu {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
}

.TokenChart_gap-5__Ee5tE {
  grid-gap: 3rem !important;
  gap: 3rem !important;
}

.TokenChart_justify-content-start__OKnwh {
  justify-content: flex-start !important;
}

.TokenChart_justify-content-end__tin9t {
  justify-content: flex-end !important;
}

.TokenChart_justify-content-center__Rsto- {
  justify-content: center !important;
}

.TokenChart_justify-content-between__3JeMn {
  justify-content: space-between !important;
}

.TokenChart_justify-content-around__Xdnzq {
  justify-content: space-around !important;
}

.TokenChart_justify-content-evenly__3ytJ2 {
  justify-content: space-evenly !important;
}

.TokenChart_align-items-start__73Qet {
  align-items: flex-start !important;
}

.TokenChart_align-items-end__2ow2E {
  align-items: flex-end !important;
}

.TokenChart_align-items-center__2ukgJ {
  align-items: center !important;
}

.TokenChart_align-items-baseline__dWKp4 {
  align-items: baseline !important;
}

.TokenChart_align-items-stretch__1-aU6 {
  align-items: stretch !important;
}

.TokenChart_align-content-start__3u06J {
  align-content: flex-start !important;
}

.TokenChart_align-content-end__3uKCd {
  align-content: flex-end !important;
}

.TokenChart_align-content-center__3BDRd {
  align-content: center !important;
}

.TokenChart_align-content-between__2pSEz {
  align-content: space-between !important;
}

.TokenChart_align-content-around__2ZYO- {
  align-content: space-around !important;
}

.TokenChart_align-content-stretch__dVytD {
  align-content: stretch !important;
}

.TokenChart_align-self-auto__2VDGO {
  align-self: auto !important;
}

.TokenChart_align-self-start__apyGC {
  align-self: flex-start !important;
}

.TokenChart_align-self-end__LSwzn {
  align-self: flex-end !important;
}

.TokenChart_align-self-center__rTtu1 {
  align-self: center !important;
}

.TokenChart_align-self-baseline__2MyU9 {
  align-self: baseline !important;
}

.TokenChart_align-self-stretch__2jqum {
  align-self: stretch !important;
}

.TokenChart_order-first__1MUT7 {
  order: -1 !important;
}

.TokenChart_order-0__v9wB0 {
  order: 0 !important;
}

.TokenChart_order-1__1mQ2C {
  order: 1 !important;
}

.TokenChart_order-2__1dcKH {
  order: 2 !important;
}

.TokenChart_order-3__2jYPH {
  order: 3 !important;
}

.TokenChart_order-4__2JF9p {
  order: 4 !important;
}

.TokenChart_order-5__2peJq {
  order: 5 !important;
}

.TokenChart_order-last__4fTcq {
  order: 6 !important;
}

.TokenChart_m-0__3qjx_ {
  margin: 0 !important;
}

.TokenChart_m-1__BTbIP {
  margin: 0.25rem !important;
}

.TokenChart_m-2__3P0T7 {
  margin: 0.5rem !important;
}

.TokenChart_m-3__3ewFb {
  margin: 1rem !important;
}

.TokenChart_m-4__P-Hq7 {
  margin: 1.5rem !important;
}

.TokenChart_m-5__3vnHJ {
  margin: 3rem !important;
}

.TokenChart_m-auto__3dD05 {
  margin: auto !important;
}

.TokenChart_mx-0__E7oIQ {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.TokenChart_mx-1__Y9HQw {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.TokenChart_mx-2__1HBLs {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.TokenChart_mx-3__RnrYo {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.TokenChart_mx-4__25ltM {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.TokenChart_mx-5__vqzLf {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.TokenChart_mx-auto__2nAyO {
  margin-right: auto !important;
  margin-left: auto !important;
}

.TokenChart_my-0__n_jo7 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.TokenChart_my-1__4fo8k {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.TokenChart_my-2__2sG9h {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.TokenChart_my-3__1hdWf {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.TokenChart_my-4___XN27 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.TokenChart_my-5__2PJhl {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.TokenChart_my-auto__1ZPku {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.TokenChart_mt-0__lLXmH {
  margin-top: 0 !important;
}

.TokenChart_mt-1__3VwdI {
  margin-top: 0.25rem !important;
}

.TokenChart_mt-2__2FVdX {
  margin-top: 0.5rem !important;
}

.TokenChart_mt-3__IxqLi {
  margin-top: 1rem !important;
}

.TokenChart_mt-4__dWjL3 {
  margin-top: 1.5rem !important;
}

.TokenChart_mt-5__1-SXm {
  margin-top: 3rem !important;
}

.TokenChart_mt-auto__2m2re {
  margin-top: auto !important;
}

.TokenChart_me-0__Y1a-R {
  margin-right: 0 !important;
}

.TokenChart_me-1__Su1AR {
  margin-right: 0.25rem !important;
}

.TokenChart_me-2__24WyH {
  margin-right: 0.5rem !important;
}

.TokenChart_me-3__FWLlU {
  margin-right: 1rem !important;
}

.TokenChart_me-4__1av4V {
  margin-right: 1.5rem !important;
}

.TokenChart_me-5__2-3sY {
  margin-right: 3rem !important;
}

.TokenChart_me-auto__36TtH {
  margin-right: auto !important;
}

.TokenChart_mb-0__2h59P {
  margin-bottom: 0 !important;
}

.TokenChart_mb-1__F33gH {
  margin-bottom: 0.25rem !important;
}

.TokenChart_mb-2__2hl9u {
  margin-bottom: 0.5rem !important;
}

.TokenChart_mb-3__3lRhe {
  margin-bottom: 1rem !important;
}

.TokenChart_mb-4__xSyJr {
  margin-bottom: 1.5rem !important;
}

.TokenChart_mb-5__FfiZz {
  margin-bottom: 3rem !important;
}

.TokenChart_mb-auto__3Iihz {
  margin-bottom: auto !important;
}

.TokenChart_ms-0__5TOuB {
  margin-left: 0 !important;
}

.TokenChart_ms-1__3xVwY {
  margin-left: 0.25rem !important;
}

.TokenChart_ms-2__3mp5t {
  margin-left: 0.5rem !important;
}

.TokenChart_ms-3__r8EL_ {
  margin-left: 1rem !important;
}

.TokenChart_ms-4__2rfGb {
  margin-left: 1.5rem !important;
}

.TokenChart_ms-5__htxC_ {
  margin-left: 3rem !important;
}

.TokenChart_ms-auto__3PLgI {
  margin-left: auto !important;
}

.TokenChart_p-0__C6LLc {
  padding: 0 !important;
}

.TokenChart_p-1__OiucF {
  padding: 0.25rem !important;
}

.TokenChart_p-2__1Tjz_ {
  padding: 0.5rem !important;
}

.TokenChart_p-3__2nBP7 {
  padding: 1rem !important;
}

.TokenChart_p-4__2Cplz {
  padding: 1.5rem !important;
}

.TokenChart_p-5__3IBDt {
  padding: 3rem !important;
}

.TokenChart_px-0__1hn_Z {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.TokenChart_px-1__1N8tj {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.TokenChart_px-2__2BokX {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.TokenChart_px-3__1tulN {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.TokenChart_px-4__1p2tP {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.TokenChart_px-5__BQ8Qc {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.TokenChart_py-0__2zYcW {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.TokenChart_py-1__I65jz {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.TokenChart_py-2__1acEN {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.TokenChart_py-3__1vFIp {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.TokenChart_py-4__2dkCQ {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.TokenChart_py-5__2PBEV {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.TokenChart_pt-0__3IGXX {
  padding-top: 0 !important;
}

.TokenChart_pt-1__YlcJD {
  padding-top: 0.25rem !important;
}

.TokenChart_pt-2__1gXkr {
  padding-top: 0.5rem !important;
}

.TokenChart_pt-3__2qvYl {
  padding-top: 1rem !important;
}

.TokenChart_pt-4__5soLd {
  padding-top: 1.5rem !important;
}

.TokenChart_pt-5__UkPOq {
  padding-top: 3rem !important;
}

.TokenChart_pe-0__SPq-j {
  padding-right: 0 !important;
}

.TokenChart_pe-1__1QWiE {
  padding-right: 0.25rem !important;
}

.TokenChart_pe-2__dLgxx {
  padding-right: 0.5rem !important;
}

.TokenChart_pe-3__UbL_s {
  padding-right: 1rem !important;
}

.TokenChart_pe-4__2YRLR {
  padding-right: 1.5rem !important;
}

.TokenChart_pe-5__3k7fP {
  padding-right: 3rem !important;
}

.TokenChart_pb-0__11pma {
  padding-bottom: 0 !important;
}

.TokenChart_pb-1__3qgnE {
  padding-bottom: 0.25rem !important;
}

.TokenChart_pb-2__2p3KY {
  padding-bottom: 0.5rem !important;
}

.TokenChart_pb-3__2dQxE {
  padding-bottom: 1rem !important;
}

.TokenChart_pb-4__jooho {
  padding-bottom: 1.5rem !important;
}

.TokenChart_pb-5__3FawR {
  padding-bottom: 3rem !important;
}

.TokenChart_ps-0__1sEgw {
  padding-left: 0 !important;
}

.TokenChart_ps-1__2-XpQ {
  padding-left: 0.25rem !important;
}

.TokenChart_ps-2__3zPAD {
  padding-left: 0.5rem !important;
}

.TokenChart_ps-3__19eZS {
  padding-left: 1rem !important;
}

.TokenChart_ps-4__3pKhQ {
  padding-left: 1.5rem !important;
}

.TokenChart_ps-5__1RaiJ {
  padding-left: 3rem !important;
}

.TokenChart_fs-1__1a47b {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.TokenChart_fs-2__XG-wf {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.TokenChart_fs-3__3w5kV {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.TokenChart_fs-4__3owKk {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.TokenChart_fs-5__3Jh6c {
  font-size: 1.25rem !important;
}

.TokenChart_fs-6__1jZGL {
  font-size: 1rem !important;
}

.TokenChart_fst-italic__3jku1 {
  font-style: italic !important;
}

.TokenChart_fst-normal__3Rol7 {
  font-style: normal !important;
}

.TokenChart_fw-light__aXLMc {
  font-weight: 300 !important;
}

.TokenChart_fw-lighter__2Ywsa {
  font-weight: lighter !important;
}

.TokenChart_fw-normal__2z9EQ {
  font-weight: 400 !important;
}

.TokenChart_fw-bold__27F4v {
  font-weight: 700 !important;
}

.TokenChart_fw-bolder__1e3qD {
  font-weight: bolder !important;
}

.TokenChart_text-lowercase__14hMd {
  text-transform: lowercase !important;
}

.TokenChart_text-uppercase__2oklj {
  text-transform: uppercase !important;
}

.TokenChart_text-capitalize__1bFC7 {
  text-transform: capitalize !important;
}

.TokenChart_text-start__1Xzuw {
  text-align: left !important;
}

.TokenChart_text-end__3uMAF {
  text-align: right !important;
}

.TokenChart_text-center__rssqe {
  text-align: center !important;
}

.TokenChart_text-primary__3e8-3 {
  color: #a4ff66 !important;
}

.TokenChart_text-secondary__1WT5u {
  color: #6c757d !important;
}

.TokenChart_text-success__1oRTb {
  color: #28a745 !important;
}

.TokenChart_text-info__1r6IO {
  color: #17a2b8 !important;
}

.TokenChart_text-warning__CLFVx {
  color: #f7b500 !important;
}

.TokenChart_text-danger__zSUNt {
  color: #dc3545 !important;
}

.TokenChart_text-light__205yr {
  color: #f8f9fa !important;
}

.TokenChart_text-dark__2RBXU {
  color: #0f1a1c !important;
}

.TokenChart_text-dark-1__1e1Tn {
  color: #303032 !important;
}

.TokenChart_text-dark-2__3P_ZK {
  color: #383838 !important;
}

.TokenChart_text-blue-dark__2EzJC {
  color: #141722 !important;
}

.TokenChart_text-white__2o11R {
  color: #fff !important;
}

.TokenChart_text-body__3XQFp {
  color: #f8f9fa !important;
}

.TokenChart_text-muted__DiEdw {
  color: #adb5bd !important;
}

.TokenChart_text-black-50__2JpX7 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.TokenChart_text-white-50__3Q9gM {
  color: hsla(0, 0%, 100%, 0.5) !important;
}

.TokenChart_text-reset__1xReq {
  color: inherit !important;
}

.TokenChart_lh-1__3LieI {
  line-height: 1 !important;
}

.TokenChart_lh-sm__1Ioas {
  line-height: 1.25 !important;
}

.TokenChart_lh-base__O4fGc {
  line-height: 1.5 !important;
}

.TokenChart_lh-lg__Nse2z {
  line-height: 2 !important;
}

.TokenChart_bg-primary__36aen {
  background-color: #a4ff66 !important;
}

.TokenChart_bg-secondary__1_X1C {
  background-color: #6c757d !important;
}

.TokenChart_bg-success__Xi2Dj {
  background-color: #28a745 !important;
}

.TokenChart_bg-info__1_9g2 {
  background-color: #17a2b8 !important;
}

.TokenChart_bg-warning__31mmu {
  background-color: #f7b500 !important;
}

.TokenChart_bg-danger__2PEcb {
  background-color: #dc3545 !important;
}

.TokenChart_bg-light__w1SPQ {
  background-color: #f8f9fa !important;
}

.TokenChart_bg-dark__2ocel {
  background-color: #0f1a1c !important;
}

.TokenChart_bg-dark-1__T4Tp4 {
  background-color: #303032 !important;
}

.TokenChart_bg-dark-2__1YHvs {
  background-color: #383838 !important;
}

.TokenChart_bg-blue-dark__1d33k {
  background-color: #141722 !important;
}

.TokenChart_bg-body__2E0Q4 {
  background-color: #141414 !important;
}

.TokenChart_bg-white__pHI2- {
  background-color: #fff !important;
}

.TokenChart_bg-transparent__2t5Xr {
  background-color: transparent !important;
}

.TokenChart_bg-gradient__ZuTI9 {
  background-image: var(--bs-gradient) !important;
}

.TokenChart_text-wrap__24p4_ {
  white-space: normal !important;
}

.TokenChart_text-nowrap__30Rv4 {
  white-space: nowrap !important;
}

.TokenChart_text-decoration-none__2hoCQ {
  text-decoration: none !important;
}

.TokenChart_text-decoration-underline__3oVy7 {
  text-decoration: underline !important;
}

.TokenChart_text-decoration-line-through__2suVW {
  text-decoration: line-through !important;
}

.TokenChart_text-break__2jojn {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.TokenChart_font-monospace__28lwP {
  /* font-family: var(--bs-font-monospace) !important */
}

.TokenChart_user-select-all__2J5Uf {
  -webkit-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.TokenChart_user-select-auto__UNg7f {
  -webkit-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.TokenChart_user-select-none__3AoTm {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.TokenChart_pe-none__10tHv {
  pointer-events: none !important;
}

.TokenChart_pe-auto__h6-SN {
  pointer-events: auto !important;
}

.TokenChart_rounded__1Bd19 {
  border-radius: 0.25rem !important;
}

.TokenChart_rounded-0__1-PEF {
  border-radius: 0 !important;
}

.TokenChart_rounded-1__3L6c4 {
  border-radius: 0.2rem !important;
}

.TokenChart_rounded-2__3395H {
  border-radius: 0.25rem !important;
}

.TokenChart_rounded-3__1iCLu {
  border-radius: 0.3rem !important;
}

.TokenChart_rounded-circle__3TIH7 {
  border-radius: 50% !important;
}

.TokenChart_rounded-pill__2j4kl {
  border-radius: 50rem !important;
}

.TokenChart_rounded-top__3VoUG {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.TokenChart_rounded-end__2mEFW {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.TokenChart_rounded-bottom__3Nloa {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.TokenChart_rounded-start__1YPbz {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.TokenChart_visible__2ktUV {
  visibility: visible !important;
}

.TokenChart_invisible__3l5jd {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .TokenChart_float-sm-start__3GEph {
    float: left !important;
  }

  .TokenChart_float-sm-end__3LzN0 {
    float: right !important;
  }

  .TokenChart_float-sm-none__3hzGk {
    float: none !important;
  }

  .TokenChart_d-sm-inline__2UgFC {
    display: inline !important;
  }

  .TokenChart_d-sm-inline-block__243fb {
    display: inline-block !important;
  }

  .TokenChart_d-sm-block__3WNnl {
    display: block !important;
  }

  .TokenChart_d-sm-grid__2BFVw {
    display: grid !important;
  }

  .TokenChart_d-sm-table__3QxA4 {
    display: table !important;
  }

  .TokenChart_d-sm-table-row__2w-dF {
    display: table-row !important;
  }

  .TokenChart_d-sm-table-cell__2SaPV {
    display: table-cell !important;
  }

  .TokenChart_d-sm-flex__3bn1k {
    display: flex !important;
  }

  .TokenChart_d-sm-inline-flex__3xBsh {
    display: inline-flex !important;
  }

  .TokenChart_d-sm-none__2sT8O {
    display: none !important;
  }

  .TokenChart_flex-sm-fill__1dGy6 {
    flex: 1 1 auto !important;
  }

  .TokenChart_flex-sm-row__1Iyl5 {
    flex-direction: row !important;
  }

  .TokenChart_flex-sm-column__jp97t {
    flex-direction: column !important;
  }

  .TokenChart_flex-sm-row-reverse__2_96o {
    flex-direction: row-reverse !important;
  }

  .TokenChart_flex-sm-column-reverse__1dPQ3 {
    flex-direction: column-reverse !important;
  }

  .TokenChart_flex-sm-grow-0__2ABJ7 {
    flex-grow: 0 !important;
  }

  .TokenChart_flex-sm-grow-1__2KTO5 {
    flex-grow: 1 !important;
  }

  .TokenChart_flex-sm-shrink-0__2pzgY {
    flex-shrink: 0 !important;
  }

  .TokenChart_flex-sm-shrink-1__1ZigP {
    flex-shrink: 1 !important;
  }

  .TokenChart_flex-sm-wrap__eMx29 {
    flex-wrap: wrap !important;
  }

  .TokenChart_flex-sm-nowrap__3u8SC {
    flex-wrap: nowrap !important;
  }

  .TokenChart_flex-sm-wrap-reverse__1D3DU {
    flex-wrap: wrap-reverse !important;
  }

  .TokenChart_gap-sm-0__PB4Ys {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .TokenChart_gap-sm-1__2CrZY {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .TokenChart_gap-sm-2__RpmNt {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .TokenChart_gap-sm-3__2nkhK {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .TokenChart_gap-sm-4__1wr-w {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .TokenChart_gap-sm-5__1PeF0 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .TokenChart_justify-content-sm-start__1Zm9l {
    justify-content: flex-start !important;
  }

  .TokenChart_justify-content-sm-end__eA1dA {
    justify-content: flex-end !important;
  }

  .TokenChart_justify-content-sm-center__28UIa {
    justify-content: center !important;
  }

  .TokenChart_justify-content-sm-between__hWaiJ {
    justify-content: space-between !important;
  }

  .TokenChart_justify-content-sm-around__1Zvvp {
    justify-content: space-around !important;
  }

  .TokenChart_justify-content-sm-evenly__252GY {
    justify-content: space-evenly !important;
  }

  .TokenChart_align-items-sm-start__3YZCk {
    align-items: flex-start !important;
  }

  .TokenChart_align-items-sm-end__QPf0l {
    align-items: flex-end !important;
  }

  .TokenChart_align-items-sm-center__ncORi {
    align-items: center !important;
  }

  .TokenChart_align-items-sm-baseline__1IIcM {
    align-items: baseline !important;
  }

  .TokenChart_align-items-sm-stretch__LG95A {
    align-items: stretch !important;
  }

  .TokenChart_align-content-sm-start__2Ikp1 {
    align-content: flex-start !important;
  }

  .TokenChart_align-content-sm-end__CJiNh {
    align-content: flex-end !important;
  }

  .TokenChart_align-content-sm-center__1TpWL {
    align-content: center !important;
  }

  .TokenChart_align-content-sm-between__2t1-G {
    align-content: space-between !important;
  }

  .TokenChart_align-content-sm-around__cohzk {
    align-content: space-around !important;
  }

  .TokenChart_align-content-sm-stretch__uBwIl {
    align-content: stretch !important;
  }

  .TokenChart_align-self-sm-auto__15F9B {
    align-self: auto !important;
  }

  .TokenChart_align-self-sm-start__gs8xO {
    align-self: flex-start !important;
  }

  .TokenChart_align-self-sm-end__sCA4H {
    align-self: flex-end !important;
  }

  .TokenChart_align-self-sm-center__1848U {
    align-self: center !important;
  }

  .TokenChart_align-self-sm-baseline__1utT2 {
    align-self: baseline !important;
  }

  .TokenChart_align-self-sm-stretch__1Dqo4 {
    align-self: stretch !important;
  }

  .TokenChart_order-sm-first__3__l8 {
    order: -1 !important;
  }

  .TokenChart_order-sm-0__3qDcC {
    order: 0 !important;
  }

  .TokenChart_order-sm-1__2GEtv {
    order: 1 !important;
  }

  .TokenChart_order-sm-2__1OsEj {
    order: 2 !important;
  }

  .TokenChart_order-sm-3__3FuO8 {
    order: 3 !important;
  }

  .TokenChart_order-sm-4__2-pyq {
    order: 4 !important;
  }

  .TokenChart_order-sm-5__3CDlA {
    order: 5 !important;
  }

  .TokenChart_order-sm-last__anEHm {
    order: 6 !important;
  }

  .TokenChart_m-sm-0__20VlM {
    margin: 0 !important;
  }

  .TokenChart_m-sm-1__26WKm {
    margin: 0.25rem !important;
  }

  .TokenChart_m-sm-2__3Jacx {
    margin: 0.5rem !important;
  }

  .TokenChart_m-sm-3__2WDkt {
    margin: 1rem !important;
  }

  .TokenChart_m-sm-4__3NNEH {
    margin: 1.5rem !important;
  }

  .TokenChart_m-sm-5__1Pl0I {
    margin: 3rem !important;
  }

  .TokenChart_m-sm-auto__1WDoI {
    margin: auto !important;
  }

  .TokenChart_mx-sm-0__16A8Y {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .TokenChart_mx-sm-1__3GPhJ {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .TokenChart_mx-sm-2__2ElOt {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .TokenChart_mx-sm-3__1H-Lm {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .TokenChart_mx-sm-4__2RLix {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .TokenChart_mx-sm-5__2t1Lo {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .TokenChart_mx-sm-auto__22TrE {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .TokenChart_my-sm-0__35Cyo {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .TokenChart_my-sm-1__1uXiA {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .TokenChart_my-sm-2__uuru1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .TokenChart_my-sm-3__3iMgp {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .TokenChart_my-sm-4__3DjLV {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .TokenChart_my-sm-5__307Ov {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .TokenChart_my-sm-auto__Rnl5h {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .TokenChart_mt-sm-0__3A5kV {
    margin-top: 0 !important;
  }

  .TokenChart_mt-sm-1__28dJ6 {
    margin-top: 0.25rem !important;
  }

  .TokenChart_mt-sm-2__2Fsdi {
    margin-top: 0.5rem !important;
  }

  .TokenChart_mt-sm-3__2oRFt {
    margin-top: 1rem !important;
  }

  .TokenChart_mt-sm-4__1UuUp {
    margin-top: 1.5rem !important;
  }

  .TokenChart_mt-sm-5___7NfT {
    margin-top: 3rem !important;
  }

  .TokenChart_mt-sm-auto__3YWV8 {
    margin-top: auto !important;
  }

  .TokenChart_me-sm-0__37EdS {
    margin-right: 0 !important;
  }

  .TokenChart_me-sm-1__1AawC {
    margin-right: 0.25rem !important;
  }

  .TokenChart_me-sm-2__iHL-L {
    margin-right: 0.5rem !important;
  }

  .TokenChart_me-sm-3__1AtaI {
    margin-right: 1rem !important;
  }

  .TokenChart_me-sm-4__1ds5n {
    margin-right: 1.5rem !important;
  }

  .TokenChart_me-sm-5__1QgNp {
    margin-right: 3rem !important;
  }

  .TokenChart_me-sm-auto__2GodC {
    margin-right: auto !important;
  }

  .TokenChart_mb-sm-0__8bsOo {
    margin-bottom: 0 !important;
  }

  .TokenChart_mb-sm-1__slwdn {
    margin-bottom: 0.25rem !important;
  }

  .TokenChart_mb-sm-2__2Rx6c {
    margin-bottom: 0.5rem !important;
  }

  .TokenChart_mb-sm-3__1SvKe {
    margin-bottom: 1rem !important;
  }

  .TokenChart_mb-sm-4__RUOAF {
    margin-bottom: 1.5rem !important;
  }

  .TokenChart_mb-sm-5__3FTp0 {
    margin-bottom: 3rem !important;
  }

  .TokenChart_mb-sm-auto__T9uUB {
    margin-bottom: auto !important;
  }

  .TokenChart_ms-sm-0__194I0 {
    margin-left: 0 !important;
  }

  .TokenChart_ms-sm-1__2JdyD {
    margin-left: 0.25rem !important;
  }

  .TokenChart_ms-sm-2__2TPXa {
    margin-left: 0.5rem !important;
  }

  .TokenChart_ms-sm-3__2UVkF {
    margin-left: 1rem !important;
  }

  .TokenChart_ms-sm-4__3ZoIo {
    margin-left: 1.5rem !important;
  }

  .TokenChart_ms-sm-5__39enX {
    margin-left: 3rem !important;
  }

  .TokenChart_ms-sm-auto__1RrQM {
    margin-left: auto !important;
  }

  .TokenChart_p-sm-0__1LZx3 {
    padding: 0 !important;
  }

  .TokenChart_p-sm-1__1j6rc {
    padding: 0.25rem !important;
  }

  .TokenChart_p-sm-2__3mGtg {
    padding: 0.5rem !important;
  }

  .TokenChart_p-sm-3__32kIZ {
    padding: 1rem !important;
  }

  .TokenChart_p-sm-4__1RmGh {
    padding: 1.5rem !important;
  }

  .TokenChart_p-sm-5__HJ3_A {
    padding: 3rem !important;
  }

  .TokenChart_px-sm-0__25qDC {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .TokenChart_px-sm-1__34bl1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .TokenChart_px-sm-2__23Txv {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .TokenChart_px-sm-3__1A0Wx {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .TokenChart_px-sm-4__26iHd {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TokenChart_px-sm-5__2XT8q {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .TokenChart_py-sm-0__PSeHD {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .TokenChart_py-sm-1__2VxKN {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .TokenChart_py-sm-2__I6zdb {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .TokenChart_py-sm-3__3fpkY {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .TokenChart_py-sm-4__1fGov {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .TokenChart_py-sm-5__2dsPo {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .TokenChart_pt-sm-0__Ffom0 {
    padding-top: 0 !important;
  }

  .TokenChart_pt-sm-1__1HEEZ {
    padding-top: 0.25rem !important;
  }

  .TokenChart_pt-sm-2__3WasL {
    padding-top: 0.5rem !important;
  }

  .TokenChart_pt-sm-3__sJ00w {
    padding-top: 1rem !important;
  }

  .TokenChart_pt-sm-4__3RXcJ {
    padding-top: 1.5rem !important;
  }

  .TokenChart_pt-sm-5__2fCsZ {
    padding-top: 3rem !important;
  }

  .TokenChart_pe-sm-0__30KmE {
    padding-right: 0 !important;
  }

  .TokenChart_pe-sm-1__1nrNL {
    padding-right: 0.25rem !important;
  }

  .TokenChart_pe-sm-2__32MFc {
    padding-right: 0.5rem !important;
  }

  .TokenChart_pe-sm-3__2US2q {
    padding-right: 1rem !important;
  }

  .TokenChart_pe-sm-4__1ySC3 {
    padding-right: 1.5rem !important;
  }

  .TokenChart_pe-sm-5__4x1vo {
    padding-right: 3rem !important;
  }

  .TokenChart_pb-sm-0__2Vpxk {
    padding-bottom: 0 !important;
  }

  .TokenChart_pb-sm-1__16KWe {
    padding-bottom: 0.25rem !important;
  }

  .TokenChart_pb-sm-2__zyz2G {
    padding-bottom: 0.5rem !important;
  }

  .TokenChart_pb-sm-3__1ECqN {
    padding-bottom: 1rem !important;
  }

  .TokenChart_pb-sm-4__1Bw4u {
    padding-bottom: 1.5rem !important;
  }

  .TokenChart_pb-sm-5__5jp-M {
    padding-bottom: 3rem !important;
  }

  .TokenChart_ps-sm-0__qOaRo {
    padding-left: 0 !important;
  }

  .TokenChart_ps-sm-1__13K53 {
    padding-left: 0.25rem !important;
  }

  .TokenChart_ps-sm-2__3PjVZ {
    padding-left: 0.5rem !important;
  }

  .TokenChart_ps-sm-3__2FcVr {
    padding-left: 1rem !important;
  }

  .TokenChart_ps-sm-4__3zdJJ {
    padding-left: 1.5rem !important;
  }

  .TokenChart_ps-sm-5__1RuC2 {
    padding-left: 3rem !important;
  }

  .TokenChart_text-sm-start__3AXOS {
    text-align: left !important;
  }

  .TokenChart_text-sm-end__3SSLy {
    text-align: right !important;
  }

  .TokenChart_text-sm-center__G6CXq {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .TokenChart_float-md-start__Eqo5q {
    float: left !important;
  }

  .TokenChart_float-md-end__2mDkT {
    float: right !important;
  }

  .TokenChart_float-md-none__3s6xV {
    float: none !important;
  }

  .TokenChart_d-md-inline__2T48n {
    display: inline !important;
  }

  .TokenChart_d-md-inline-block__f_tp5 {
    display: inline-block !important;
  }

  .TokenChart_d-md-block__2Vmzl {
    display: block !important;
  }

  .TokenChart_d-md-grid__2Ao5Q {
    display: grid !important;
  }

  .TokenChart_d-md-table__1Cmc_ {
    display: table !important;
  }

  .TokenChart_d-md-table-row__2rmsl {
    display: table-row !important;
  }

  .TokenChart_d-md-table-cell__1NZcw {
    display: table-cell !important;
  }

  .TokenChart_d-md-flex__1vsTy {
    display: flex !important;
  }

  .TokenChart_d-md-inline-flex__1lsni {
    display: inline-flex !important;
  }

  .TokenChart_d-md-none__Ise2Y {
    display: none !important;
  }

  .TokenChart_flex-md-fill__XMpOK {
    flex: 1 1 auto !important;
  }

  .TokenChart_flex-md-row__3UZHo {
    flex-direction: row !important;
  }

  .TokenChart_flex-md-column__Qv5lU {
    flex-direction: column !important;
  }

  .TokenChart_flex-md-row-reverse__1xEt7 {
    flex-direction: row-reverse !important;
  }

  .TokenChart_flex-md-column-reverse__8Vguk {
    flex-direction: column-reverse !important;
  }

  .TokenChart_flex-md-grow-0__AsDbi {
    flex-grow: 0 !important;
  }

  .TokenChart_flex-md-grow-1__qZ5za {
    flex-grow: 1 !important;
  }

  .TokenChart_flex-md-shrink-0__1K_JC {
    flex-shrink: 0 !important;
  }

  .TokenChart_flex-md-shrink-1__1Cf90 {
    flex-shrink: 1 !important;
  }

  .TokenChart_flex-md-wrap__1yn_T {
    flex-wrap: wrap !important;
  }

  .TokenChart_flex-md-nowrap__2MuvX {
    flex-wrap: nowrap !important;
  }

  .TokenChart_flex-md-wrap-reverse__1-Naa {
    flex-wrap: wrap-reverse !important;
  }

  .TokenChart_gap-md-0__2BVwZ {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .TokenChart_gap-md-1__2Y8Nl {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .TokenChart_gap-md-2__3d2oe {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .TokenChart_gap-md-3__2jLib {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .TokenChart_gap-md-4__16jqM {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .TokenChart_gap-md-5__1KK6P {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .TokenChart_justify-content-md-start__2dVoo {
    justify-content: flex-start !important;
  }

  .TokenChart_justify-content-md-end__2No96 {
    justify-content: flex-end !important;
  }

  .TokenChart_justify-content-md-center__24lLd {
    justify-content: center !important;
  }

  .TokenChart_justify-content-md-between__2Wzfy {
    justify-content: space-between !important;
  }

  .TokenChart_justify-content-md-around__FYDf1 {
    justify-content: space-around !important;
  }

  .TokenChart_justify-content-md-evenly__3_E6Q {
    justify-content: space-evenly !important;
  }

  .TokenChart_align-items-md-start__2r9XN {
    align-items: flex-start !important;
  }

  .TokenChart_align-items-md-end__8kgh- {
    align-items: flex-end !important;
  }

  .TokenChart_align-items-md-center__EfyaJ {
    align-items: center !important;
  }

  .TokenChart_align-items-md-baseline__1-TJK {
    align-items: baseline !important;
  }

  .TokenChart_align-items-md-stretch__1cMRp {
    align-items: stretch !important;
  }

  .TokenChart_align-content-md-start__2_LlR {
    align-content: flex-start !important;
  }

  .TokenChart_align-content-md-end__1nM3N {
    align-content: flex-end !important;
  }

  .TokenChart_align-content-md-center__29lSG {
    align-content: center !important;
  }

  .TokenChart_align-content-md-between__3viBy {
    align-content: space-between !important;
  }

  .TokenChart_align-content-md-around__vf75I {
    align-content: space-around !important;
  }

  .TokenChart_align-content-md-stretch__1ti4U {
    align-content: stretch !important;
  }

  .TokenChart_align-self-md-auto__33bJI {
    align-self: auto !important;
  }

  .TokenChart_align-self-md-start__2LQT6 {
    align-self: flex-start !important;
  }

  .TokenChart_align-self-md-end__3Hd06 {
    align-self: flex-end !important;
  }

  .TokenChart_align-self-md-center__ra21O {
    align-self: center !important;
  }

  .TokenChart_align-self-md-baseline__58Zqb {
    align-self: baseline !important;
  }

  .TokenChart_align-self-md-stretch__3cuj4 {
    align-self: stretch !important;
  }

  .TokenChart_order-md-first__3COoD {
    order: -1 !important;
  }

  .TokenChart_order-md-0__2Jofh {
    order: 0 !important;
  }

  .TokenChart_order-md-1__1CKbg {
    order: 1 !important;
  }

  .TokenChart_order-md-2__TPlR_ {
    order: 2 !important;
  }

  .TokenChart_order-md-3__3NrNI {
    order: 3 !important;
  }

  .TokenChart_order-md-4__1E9bK {
    order: 4 !important;
  }

  .TokenChart_order-md-5__2T_k9 {
    order: 5 !important;
  }

  .TokenChart_order-md-last__3VQCM {
    order: 6 !important;
  }

  .TokenChart_m-md-0__3vA4M {
    margin: 0 !important;
  }

  .TokenChart_m-md-1__37avz {
    margin: 0.25rem !important;
  }

  .TokenChart_m-md-2__1X1Ce {
    margin: 0.5rem !important;
  }

  .TokenChart_m-md-3__3kSH2 {
    margin: 1rem !important;
  }

  .TokenChart_m-md-4__2dmN0 {
    margin: 1.5rem !important;
  }

  .TokenChart_m-md-5__8c_16 {
    margin: 3rem !important;
  }

  .TokenChart_m-md-auto__ON03U {
    margin: auto !important;
  }

  .TokenChart_mx-md-0__18k1D {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .TokenChart_mx-md-1__1L5jw {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .TokenChart_mx-md-2__3DVv8 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .TokenChart_mx-md-3__3WFuR {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .TokenChart_mx-md-4__1BWX7 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .TokenChart_mx-md-5__1lLsQ {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .TokenChart_mx-md-auto__iNjpB {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .TokenChart_my-md-0__2nEMR {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .TokenChart_my-md-1__32TNS {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .TokenChart_my-md-2__3bVVC {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .TokenChart_my-md-3__FCph0 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .TokenChart_my-md-4__2Ibx9 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .TokenChart_my-md-5__1pcgH {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .TokenChart_my-md-auto__1zpGG {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .TokenChart_mt-md-0__2i8EJ {
    margin-top: 0 !important;
  }

  .TokenChart_mt-md-1__2JNpW {
    margin-top: 0.25rem !important;
  }

  .TokenChart_mt-md-2__3NveB {
    margin-top: 0.5rem !important;
  }

  .TokenChart_mt-md-3__25wLP {
    margin-top: 1rem !important;
  }

  .TokenChart_mt-md-4__3ZTZc {
    margin-top: 1.5rem !important;
  }

  .TokenChart_mt-md-5__3_EDc {
    margin-top: 3rem !important;
  }

  .TokenChart_mt-md-auto__12esT {
    margin-top: auto !important;
  }

  .TokenChart_me-md-0__Hx3OH {
    margin-right: 0 !important;
  }

  .TokenChart_me-md-1__1imdK {
    margin-right: 0.25rem !important;
  }

  .TokenChart_me-md-2__sMfop {
    margin-right: 0.5rem !important;
  }

  .TokenChart_me-md-3__2O3Ze {
    margin-right: 1rem !important;
  }

  .TokenChart_me-md-4__ljULo {
    margin-right: 1.5rem !important;
  }

  .TokenChart_me-md-5__34Pmw {
    margin-right: 3rem !important;
  }

  .TokenChart_me-md-auto__3BUh6 {
    margin-right: auto !important;
  }

  .TokenChart_mb-md-0__3Txtt {
    margin-bottom: 0 !important;
  }

  .TokenChart_mb-md-1__8xKw8 {
    margin-bottom: 0.25rem !important;
  }

  .TokenChart_mb-md-2__QqeuB {
    margin-bottom: 0.5rem !important;
  }

  .TokenChart_mb-md-3__3fQKx {
    margin-bottom: 1rem !important;
  }

  .TokenChart_mb-md-4__1Mz7l {
    margin-bottom: 1.5rem !important;
  }

  .TokenChart_mb-md-5__1Zi7Y {
    margin-bottom: 3rem !important;
  }

  .TokenChart_mb-md-auto__2Od3v {
    margin-bottom: auto !important;
  }

  .TokenChart_ms-md-0__1TK5c {
    margin-left: 0 !important;
  }

  .TokenChart_ms-md-1__1gQU_ {
    margin-left: 0.25rem !important;
  }

  .TokenChart_ms-md-2__HNLQQ {
    margin-left: 0.5rem !important;
  }

  .TokenChart_ms-md-3__326Zo {
    margin-left: 1rem !important;
  }

  .TokenChart_ms-md-4__aHfFQ {
    margin-left: 1.5rem !important;
  }

  .TokenChart_ms-md-5__yAnqy {
    margin-left: 3rem !important;
  }

  .TokenChart_ms-md-auto__18pAT {
    margin-left: auto !important;
  }

  .TokenChart_p-md-0__2rs9d {
    padding: 0 !important;
  }

  .TokenChart_p-md-1__1WkLs {
    padding: 0.25rem !important;
  }

  .TokenChart_p-md-2__3zGU_ {
    padding: 0.5rem !important;
  }

  .TokenChart_p-md-3__1u73G {
    padding: 1rem !important;
  }

  .TokenChart_p-md-4__3lYoM {
    padding: 1.5rem !important;
  }

  .TokenChart_p-md-5__34JYO {
    padding: 3rem !important;
  }

  .TokenChart_px-md-0__1l_WY {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .TokenChart_px-md-1__3771M {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .TokenChart_px-md-2__1w33V {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .TokenChart_px-md-3__2ohqW {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .TokenChart_px-md-4__pDrmd {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TokenChart_px-md-5__1-GjA {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .TokenChart_py-md-0__1-ErF {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .TokenChart_py-md-1__1XlS7 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .TokenChart_py-md-2__3eDZ- {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .TokenChart_py-md-3__38zhD {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .TokenChart_py-md-4__2dZtQ {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .TokenChart_py-md-5__23ACA {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .TokenChart_pt-md-0__3Fh7Q {
    padding-top: 0 !important;
  }

  .TokenChart_pt-md-1__3zvKn {
    padding-top: 0.25rem !important;
  }

  .TokenChart_pt-md-2__2lm7a {
    padding-top: 0.5rem !important;
  }

  .TokenChart_pt-md-3__1nXb1 {
    padding-top: 1rem !important;
  }

  .TokenChart_pt-md-4__VBLEP {
    padding-top: 1.5rem !important;
  }

  .TokenChart_pt-md-5__2_Tbg {
    padding-top: 3rem !important;
  }

  .TokenChart_pe-md-0__2igeJ {
    padding-right: 0 !important;
  }

  .TokenChart_pe-md-1__1dreo {
    padding-right: 0.25rem !important;
  }

  .TokenChart_pe-md-2__13LFa {
    padding-right: 0.5rem !important;
  }

  .TokenChart_pe-md-3__1wUK3 {
    padding-right: 1rem !important;
  }

  .TokenChart_pe-md-4__brYbb {
    padding-right: 1.5rem !important;
  }

  .TokenChart_pe-md-5__1mzhC {
    padding-right: 3rem !important;
  }

  .TokenChart_pb-md-0__2KqKb {
    padding-bottom: 0 !important;
  }

  .TokenChart_pb-md-1__2lvQF {
    padding-bottom: 0.25rem !important;
  }

  .TokenChart_pb-md-2__FG8bp {
    padding-bottom: 0.5rem !important;
  }

  .TokenChart_pb-md-3__KK5ty {
    padding-bottom: 1rem !important;
  }

  .TokenChart_pb-md-4__3__4P {
    padding-bottom: 1.5rem !important;
  }

  .TokenChart_pb-md-5__Q6ZdB {
    padding-bottom: 3rem !important;
  }

  .TokenChart_ps-md-0__3aAW- {
    padding-left: 0 !important;
  }

  .TokenChart_ps-md-1__2-jRk {
    padding-left: 0.25rem !important;
  }

  .TokenChart_ps-md-2__1936R {
    padding-left: 0.5rem !important;
  }

  .TokenChart_ps-md-3__3RDcC {
    padding-left: 1rem !important;
  }

  .TokenChart_ps-md-4__3_f8s {
    padding-left: 1.5rem !important;
  }

  .TokenChart_ps-md-5__2j2oR {
    padding-left: 3rem !important;
  }

  .TokenChart_text-md-start__2-KM5 {
    text-align: left !important;
  }

  .TokenChart_text-md-end__3zYPG {
    text-align: right !important;
  }

  .TokenChart_text-md-center__2Cv_A {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .TokenChart_float-lg-start__1YmiE {
    float: left !important;
  }

  .TokenChart_float-lg-end__1cjpz {
    float: right !important;
  }

  .TokenChart_float-lg-none__9Lx4T {
    float: none !important;
  }

  .TokenChart_d-lg-inline__3cFmD {
    display: inline !important;
  }

  .TokenChart_d-lg-inline-block__1TRzR {
    display: inline-block !important;
  }

  .TokenChart_d-lg-block__3FyFs {
    display: block !important;
  }

  .TokenChart_d-lg-grid__2fGw_ {
    display: grid !important;
  }

  .TokenChart_d-lg-table__EYkxa {
    display: table !important;
  }

  .TokenChart_d-lg-table-row__kXxAf {
    display: table-row !important;
  }

  .TokenChart_d-lg-table-cell__2QKa- {
    display: table-cell !important;
  }

  .TokenChart_d-lg-flex__1Jjx2 {
    display: flex !important;
  }

  .TokenChart_d-lg-inline-flex__1pPkw {
    display: inline-flex !important;
  }

  .TokenChart_d-lg-none__2G-wf {
    display: none !important;
  }

  .TokenChart_flex-lg-fill__1HyDh {
    flex: 1 1 auto !important;
  }

  .TokenChart_flex-lg-row__2fyvw {
    flex-direction: row !important;
  }

  .TokenChart_flex-lg-column__3tDVf {
    flex-direction: column !important;
  }

  .TokenChart_flex-lg-row-reverse__3qXIh {
    flex-direction: row-reverse !important;
  }

  .TokenChart_flex-lg-column-reverse__18Ctz {
    flex-direction: column-reverse !important;
  }

  .TokenChart_flex-lg-grow-0__BMSwW {
    flex-grow: 0 !important;
  }

  .TokenChart_flex-lg-grow-1__275Or {
    flex-grow: 1 !important;
  }

  .TokenChart_flex-lg-shrink-0__2Zk1o {
    flex-shrink: 0 !important;
  }

  .TokenChart_flex-lg-shrink-1__3VE0m {
    flex-shrink: 1 !important;
  }

  .TokenChart_flex-lg-wrap__2bHbJ {
    flex-wrap: wrap !important;
  }

  .TokenChart_flex-lg-nowrap__352Bb {
    flex-wrap: nowrap !important;
  }

  .TokenChart_flex-lg-wrap-reverse__gZytV {
    flex-wrap: wrap-reverse !important;
  }

  .TokenChart_gap-lg-0__2eplP {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .TokenChart_gap-lg-1__18Ila {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .TokenChart_gap-lg-2__JdMVJ {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .TokenChart_gap-lg-3__30VS- {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .TokenChart_gap-lg-4__3-QdX {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .TokenChart_gap-lg-5__3OX37 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .TokenChart_justify-content-lg-start__3W7Cx {
    justify-content: flex-start !important;
  }

  .TokenChart_justify-content-lg-end__A38GI {
    justify-content: flex-end !important;
  }

  .TokenChart_justify-content-lg-center__3PVxQ {
    justify-content: center !important;
  }

  .TokenChart_justify-content-lg-between___Sdcc {
    justify-content: space-between !important;
  }

  .TokenChart_justify-content-lg-around__2v98i {
    justify-content: space-around !important;
  }

  .TokenChart_justify-content-lg-evenly__1mfzG {
    justify-content: space-evenly !important;
  }

  .TokenChart_align-items-lg-start__2ixer {
    align-items: flex-start !important;
  }

  .TokenChart_align-items-lg-end__16_Lg {
    align-items: flex-end !important;
  }

  .TokenChart_align-items-lg-center__286xS {
    align-items: center !important;
  }

  .TokenChart_align-items-lg-baseline__3wiYw {
    align-items: baseline !important;
  }

  .TokenChart_align-items-lg-stretch__dSmVt {
    align-items: stretch !important;
  }

  .TokenChart_align-content-lg-start__2ZLNu {
    align-content: flex-start !important;
  }

  .TokenChart_align-content-lg-end__3GWVy {
    align-content: flex-end !important;
  }

  .TokenChart_align-content-lg-center__1ivUF {
    align-content: center !important;
  }

  .TokenChart_align-content-lg-between__30QcV {
    align-content: space-between !important;
  }

  .TokenChart_align-content-lg-around__wbLwJ {
    align-content: space-around !important;
  }

  .TokenChart_align-content-lg-stretch__H5Ybz {
    align-content: stretch !important;
  }

  .TokenChart_align-self-lg-auto__1S1X1 {
    align-self: auto !important;
  }

  .TokenChart_align-self-lg-start__3q54j {
    align-self: flex-start !important;
  }

  .TokenChart_align-self-lg-end__1q1It {
    align-self: flex-end !important;
  }

  .TokenChart_align-self-lg-center__3rpnS {
    align-self: center !important;
  }

  .TokenChart_align-self-lg-baseline__1vDbw {
    align-self: baseline !important;
  }

  .TokenChart_align-self-lg-stretch__2rJQu {
    align-self: stretch !important;
  }

  .TokenChart_order-lg-first__1hIVr {
    order: -1 !important;
  }

  .TokenChart_order-lg-0__2TU5A {
    order: 0 !important;
  }

  .TokenChart_order-lg-1__nwKNq {
    order: 1 !important;
  }

  .TokenChart_order-lg-2__1ExW7 {
    order: 2 !important;
  }

  .TokenChart_order-lg-3__i6afb {
    order: 3 !important;
  }

  .TokenChart_order-lg-4__1bi_h {
    order: 4 !important;
  }

  .TokenChart_order-lg-5__1rvWz {
    order: 5 !important;
  }

  .TokenChart_order-lg-last__2W-X6 {
    order: 6 !important;
  }

  .TokenChart_m-lg-0__12Ow7 {
    margin: 0 !important;
  }

  .TokenChart_m-lg-1__T1h5P {
    margin: 0.25rem !important;
  }

  .TokenChart_m-lg-2__oQtbL {
    margin: 0.5rem !important;
  }

  .TokenChart_m-lg-3__2QfP9 {
    margin: 1rem !important;
  }

  .TokenChart_m-lg-4__WAxcK {
    margin: 1.5rem !important;
  }

  .TokenChart_m-lg-5__2L8TD {
    margin: 3rem !important;
  }

  .TokenChart_m-lg-auto__3jz8U {
    margin: auto !important;
  }

  .TokenChart_mx-lg-0__1nlFz {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .TokenChart_mx-lg-1__31Q8g {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .TokenChart_mx-lg-2__3efHG {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .TokenChart_mx-lg-3__OTxD9 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .TokenChart_mx-lg-4__xk_kg {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .TokenChart_mx-lg-5__1SIph {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .TokenChart_mx-lg-auto__1EthC {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .TokenChart_my-lg-0__U1TXv {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .TokenChart_my-lg-1__30oTZ {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .TokenChart_my-lg-2__7MPuU {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .TokenChart_my-lg-3__1B2__ {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .TokenChart_my-lg-4__2D1zv {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .TokenChart_my-lg-5__hACWR {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .TokenChart_my-lg-auto__2rquw {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .TokenChart_mt-lg-0__3s8cr {
    margin-top: 0 !important;
  }

  .TokenChart_mt-lg-1__11eXU {
    margin-top: 0.25rem !important;
  }

  .TokenChart_mt-lg-2__1uvun {
    margin-top: 0.5rem !important;
  }

  .TokenChart_mt-lg-3__2dgO1 {
    margin-top: 1rem !important;
  }

  .TokenChart_mt-lg-4__1X2an {
    margin-top: 1.5rem !important;
  }

  .TokenChart_mt-lg-5__2fIwU {
    margin-top: 3rem !important;
  }

  .TokenChart_mt-lg-auto__3Sl86 {
    margin-top: auto !important;
  }

  .TokenChart_me-lg-0__2uwRu {
    margin-right: 0 !important;
  }

  .TokenChart_me-lg-1__3M3UT {
    margin-right: 0.25rem !important;
  }

  .TokenChart_me-lg-2__1VoUb {
    margin-right: 0.5rem !important;
  }

  .TokenChart_me-lg-3__30u-k {
    margin-right: 1rem !important;
  }

  .TokenChart_me-lg-4__3xSpq {
    margin-right: 1.5rem !important;
  }

  .TokenChart_me-lg-5__pWO7Z {
    margin-right: 3rem !important;
  }

  .TokenChart_me-lg-auto__bSdNO {
    margin-right: auto !important;
  }

  .TokenChart_mb-lg-0__19gIO {
    margin-bottom: 0 !important;
  }

  .TokenChart_mb-lg-1__3vfn2 {
    margin-bottom: 0.25rem !important;
  }

  .TokenChart_mb-lg-2__3dKcc {
    margin-bottom: 0.5rem !important;
  }

  .TokenChart_mb-lg-3__3A6-8 {
    margin-bottom: 1rem !important;
  }

  .TokenChart_mb-lg-4__6yOT9 {
    margin-bottom: 1.5rem !important;
  }

  .TokenChart_mb-lg-5__3YIBI {
    margin-bottom: 3rem !important;
  }

  .TokenChart_mb-lg-auto__7mQgW {
    margin-bottom: auto !important;
  }

  .TokenChart_ms-lg-0__VVrD5 {
    margin-left: 0 !important;
  }

  .TokenChart_ms-lg-1__2BaNQ {
    margin-left: 0.25rem !important;
  }

  .TokenChart_ms-lg-2__1nGiz {
    margin-left: 0.5rem !important;
  }

  .TokenChart_ms-lg-3__3m0F7 {
    margin-left: 1rem !important;
  }

  .TokenChart_ms-lg-4__14-qc {
    margin-left: 1.5rem !important;
  }

  .TokenChart_ms-lg-5__39xql {
    margin-left: 3rem !important;
  }

  .TokenChart_ms-lg-auto__Eyvq6 {
    margin-left: auto !important;
  }

  .TokenChart_p-lg-0__NXyP1 {
    padding: 0 !important;
  }

  .TokenChart_p-lg-1__Kh_fU {
    padding: 0.25rem !important;
  }

  .TokenChart_p-lg-2__1DTG1 {
    padding: 0.5rem !important;
  }

  .TokenChart_p-lg-3__GRqge {
    padding: 1rem !important;
  }

  .TokenChart_p-lg-4__16QJ1 {
    padding: 1.5rem !important;
  }

  .TokenChart_p-lg-5__1X-IA {
    padding: 3rem !important;
  }

  .TokenChart_px-lg-0__3uHPS {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .TokenChart_px-lg-1__3LnYJ {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .TokenChart_px-lg-2__2nV9s {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .TokenChart_px-lg-3__EBp8f {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .TokenChart_px-lg-4__3R_BL {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TokenChart_px-lg-5__1P899 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .TokenChart_py-lg-0__2fj7H {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .TokenChart_py-lg-1__2S6JD {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .TokenChart_py-lg-2__1oNKg {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .TokenChart_py-lg-3__3vqH- {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .TokenChart_py-lg-4__2rDgf {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .TokenChart_py-lg-5__FO1gx {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .TokenChart_pt-lg-0__3hbOl {
    padding-top: 0 !important;
  }

  .TokenChart_pt-lg-1__14fVz {
    padding-top: 0.25rem !important;
  }

  .TokenChart_pt-lg-2__wPfIS {
    padding-top: 0.5rem !important;
  }

  .TokenChart_pt-lg-3__1kYND {
    padding-top: 1rem !important;
  }

  .TokenChart_pt-lg-4__zu4hL {
    padding-top: 1.5rem !important;
  }

  .TokenChart_pt-lg-5__3ckjl {
    padding-top: 3rem !important;
  }

  .TokenChart_pe-lg-0__2yvK_ {
    padding-right: 0 !important;
  }

  .TokenChart_pe-lg-1__WAjE9 {
    padding-right: 0.25rem !important;
  }

  .TokenChart_pe-lg-2__3lyZE {
    padding-right: 0.5rem !important;
  }

  .TokenChart_pe-lg-3__1-r1x {
    padding-right: 1rem !important;
  }

  .TokenChart_pe-lg-4__2fHrY {
    padding-right: 1.5rem !important;
  }

  .TokenChart_pe-lg-5__3v0ER {
    padding-right: 3rem !important;
  }

  .TokenChart_pb-lg-0__3UhyS {
    padding-bottom: 0 !important;
  }

  .TokenChart_pb-lg-1__1GyKB {
    padding-bottom: 0.25rem !important;
  }

  .TokenChart_pb-lg-2__1hHs- {
    padding-bottom: 0.5rem !important;
  }

  .TokenChart_pb-lg-3__Oxz4g {
    padding-bottom: 1rem !important;
  }

  .TokenChart_pb-lg-4__3BCOn {
    padding-bottom: 1.5rem !important;
  }

  .TokenChart_pb-lg-5__1ksjS {
    padding-bottom: 3rem !important;
  }

  .TokenChart_ps-lg-0__xTvjQ {
    padding-left: 0 !important;
  }

  .TokenChart_ps-lg-1__V5Rs4 {
    padding-left: 0.25rem !important;
  }

  .TokenChart_ps-lg-2__2YJF_ {
    padding-left: 0.5rem !important;
  }

  .TokenChart_ps-lg-3__1vPsr {
    padding-left: 1rem !important;
  }

  .TokenChart_ps-lg-4__2Gbsc {
    padding-left: 1.5rem !important;
  }

  .TokenChart_ps-lg-5__19zKh {
    padding-left: 3rem !important;
  }

  .TokenChart_text-lg-start__2qI3t {
    text-align: left !important;
  }

  .TokenChart_text-lg-end__E517u {
    text-align: right !important;
  }

  .TokenChart_text-lg-center__1__SK {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .TokenChart_float-xl-start__15V17 {
    float: left !important;
  }

  .TokenChart_float-xl-end__3O0PC {
    float: right !important;
  }

  .TokenChart_float-xl-none__2Fp6R {
    float: none !important;
  }

  .TokenChart_d-xl-inline__1jnhY {
    display: inline !important;
  }

  .TokenChart_d-xl-inline-block__3JXNt {
    display: inline-block !important;
  }

  .TokenChart_d-xl-block__2z3rp {
    display: block !important;
  }

  .TokenChart_d-xl-grid__2MB50 {
    display: grid !important;
  }

  .TokenChart_d-xl-table__3S-W5 {
    display: table !important;
  }

  .TokenChart_d-xl-table-row__Yckxd {
    display: table-row !important;
  }

  .TokenChart_d-xl-table-cell___2OyQ {
    display: table-cell !important;
  }

  .TokenChart_d-xl-flex__52liH {
    display: flex !important;
  }

  .TokenChart_d-xl-inline-flex__1-AB1 {
    display: inline-flex !important;
  }

  .TokenChart_d-xl-none__2Et_D {
    display: none !important;
  }

  .TokenChart_flex-xl-fill__26w1g {
    flex: 1 1 auto !important;
  }

  .TokenChart_flex-xl-row__3l-Oi {
    flex-direction: row !important;
  }

  .TokenChart_flex-xl-column__3cER6 {
    flex-direction: column !important;
  }

  .TokenChart_flex-xl-row-reverse__2vgYZ {
    flex-direction: row-reverse !important;
  }

  .TokenChart_flex-xl-column-reverse__3hF1p {
    flex-direction: column-reverse !important;
  }

  .TokenChart_flex-xl-grow-0__3VtUN {
    flex-grow: 0 !important;
  }

  .TokenChart_flex-xl-grow-1__1ql25 {
    flex-grow: 1 !important;
  }

  .TokenChart_flex-xl-shrink-0__1AO9a {
    flex-shrink: 0 !important;
  }

  .TokenChart_flex-xl-shrink-1__2oQJm {
    flex-shrink: 1 !important;
  }

  .TokenChart_flex-xl-wrap__3b5ay {
    flex-wrap: wrap !important;
  }

  .TokenChart_flex-xl-nowrap__11Pus {
    flex-wrap: nowrap !important;
  }

  .TokenChart_flex-xl-wrap-reverse__bVY3Z {
    flex-wrap: wrap-reverse !important;
  }

  .TokenChart_gap-xl-0__3xnKZ {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .TokenChart_gap-xl-1__9CPjt {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .TokenChart_gap-xl-2__2miuI {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .TokenChart_gap-xl-3__1-amL {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .TokenChart_gap-xl-4__2JC9W {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .TokenChart_gap-xl-5__1fqgc {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .TokenChart_justify-content-xl-start__GEtFi {
    justify-content: flex-start !important;
  }

  .TokenChart_justify-content-xl-end__ZHhJA {
    justify-content: flex-end !important;
  }

  .TokenChart_justify-content-xl-center__eXkZQ {
    justify-content: center !important;
  }

  .TokenChart_justify-content-xl-between__2efWc {
    justify-content: space-between !important;
  }

  .TokenChart_justify-content-xl-around__GoV6M {
    justify-content: space-around !important;
  }

  .TokenChart_justify-content-xl-evenly__1lBjU {
    justify-content: space-evenly !important;
  }

  .TokenChart_align-items-xl-start__3kIT0 {
    align-items: flex-start !important;
  }

  .TokenChart_align-items-xl-end__15RBe {
    align-items: flex-end !important;
  }

  .TokenChart_align-items-xl-center__148cw {
    align-items: center !important;
  }

  .TokenChart_align-items-xl-baseline__1A6qj {
    align-items: baseline !important;
  }

  .TokenChart_align-items-xl-stretch__3-c6Z {
    align-items: stretch !important;
  }

  .TokenChart_align-content-xl-start__3ubrT {
    align-content: flex-start !important;
  }

  .TokenChart_align-content-xl-end__2CRMf {
    align-content: flex-end !important;
  }

  .TokenChart_align-content-xl-center__vtGCO {
    align-content: center !important;
  }

  .TokenChart_align-content-xl-between__2VyHK {
    align-content: space-between !important;
  }

  .TokenChart_align-content-xl-around__3QC4k {
    align-content: space-around !important;
  }

  .TokenChart_align-content-xl-stretch__172b4 {
    align-content: stretch !important;
  }

  .TokenChart_align-self-xl-auto__1aRma {
    align-self: auto !important;
  }

  .TokenChart_align-self-xl-start__3M973 {
    align-self: flex-start !important;
  }

  .TokenChart_align-self-xl-end__3txty {
    align-self: flex-end !important;
  }

  .TokenChart_align-self-xl-center__2Pv8H {
    align-self: center !important;
  }

  .TokenChart_align-self-xl-baseline__KA2jm {
    align-self: baseline !important;
  }

  .TokenChart_align-self-xl-stretch__2o_DF {
    align-self: stretch !important;
  }

  .TokenChart_order-xl-first__15Y7T {
    order: -1 !important;
  }

  .TokenChart_order-xl-0__1-a3h {
    order: 0 !important;
  }

  .TokenChart_order-xl-1__YtHPF {
    order: 1 !important;
  }

  .TokenChart_order-xl-2__1EDgo {
    order: 2 !important;
  }

  .TokenChart_order-xl-3__2ETfc {
    order: 3 !important;
  }

  .TokenChart_order-xl-4__1moLf {
    order: 4 !important;
  }

  .TokenChart_order-xl-5__2m9_K {
    order: 5 !important;
  }

  .TokenChart_order-xl-last__18XwI {
    order: 6 !important;
  }

  .TokenChart_m-xl-0__2XYNd {
    margin: 0 !important;
  }

  .TokenChart_m-xl-1__2GU_r {
    margin: 0.25rem !important;
  }

  .TokenChart_m-xl-2__2d0X9 {
    margin: 0.5rem !important;
  }

  .TokenChart_m-xl-3__2sUKv {
    margin: 1rem !important;
  }

  .TokenChart_m-xl-4__3AhPZ {
    margin: 1.5rem !important;
  }

  .TokenChart_m-xl-5__3nY2E {
    margin: 3rem !important;
  }

  .TokenChart_m-xl-auto__CMxaQ {
    margin: auto !important;
  }

  .TokenChart_mx-xl-0__9p2hN {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .TokenChart_mx-xl-1__S3jvD {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .TokenChart_mx-xl-2__192ew {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .TokenChart_mx-xl-3__3c8Hi {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .TokenChart_mx-xl-4__Gq1UT {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .TokenChart_mx-xl-5__3vrfO {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .TokenChart_mx-xl-auto__139Z2 {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .TokenChart_my-xl-0__9NNt0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .TokenChart_my-xl-1__2ci0d {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .TokenChart_my-xl-2__3ABq6 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .TokenChart_my-xl-3__CpYsw {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .TokenChart_my-xl-4__ahdMm {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .TokenChart_my-xl-5__2eGdZ {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .TokenChart_my-xl-auto__3aclS {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .TokenChart_mt-xl-0__3QJ10 {
    margin-top: 0 !important;
  }

  .TokenChart_mt-xl-1__3mMtE {
    margin-top: 0.25rem !important;
  }

  .TokenChart_mt-xl-2__3d2Fi {
    margin-top: 0.5rem !important;
  }

  .TokenChart_mt-xl-3__hgLsX {
    margin-top: 1rem !important;
  }

  .TokenChart_mt-xl-4__3gFpl {
    margin-top: 1.5rem !important;
  }

  .TokenChart_mt-xl-5__euhet {
    margin-top: 3rem !important;
  }

  .TokenChart_mt-xl-auto__3WFPq {
    margin-top: auto !important;
  }

  .TokenChart_me-xl-0__3oqml {
    margin-right: 0 !important;
  }

  .TokenChart_me-xl-1__gvVny {
    margin-right: 0.25rem !important;
  }

  .TokenChart_me-xl-2__AR3El {
    margin-right: 0.5rem !important;
  }

  .TokenChart_me-xl-3__24Bxc {
    margin-right: 1rem !important;
  }

  .TokenChart_me-xl-4__1TMeu {
    margin-right: 1.5rem !important;
  }

  .TokenChart_me-xl-5__2lUU9 {
    margin-right: 3rem !important;
  }

  .TokenChart_me-xl-auto__W-NDk {
    margin-right: auto !important;
  }

  .TokenChart_mb-xl-0__YQ0eI {
    margin-bottom: 0 !important;
  }

  .TokenChart_mb-xl-1__15qKR {
    margin-bottom: 0.25rem !important;
  }

  .TokenChart_mb-xl-2__1pGhE {
    margin-bottom: 0.5rem !important;
  }

  .TokenChart_mb-xl-3__28FDu {
    margin-bottom: 1rem !important;
  }

  .TokenChart_mb-xl-4__1qyhe {
    margin-bottom: 1.5rem !important;
  }

  .TokenChart_mb-xl-5__2Dw6v {
    margin-bottom: 3rem !important;
  }

  .TokenChart_mb-xl-auto__3QxuD {
    margin-bottom: auto !important;
  }

  .TokenChart_ms-xl-0__1WWub {
    margin-left: 0 !important;
  }

  .TokenChart_ms-xl-1__2ui23 {
    margin-left: 0.25rem !important;
  }

  .TokenChart_ms-xl-2__GS2sJ {
    margin-left: 0.5rem !important;
  }

  .TokenChart_ms-xl-3__BdTWF {
    margin-left: 1rem !important;
  }

  .TokenChart_ms-xl-4__cZNl2 {
    margin-left: 1.5rem !important;
  }

  .TokenChart_ms-xl-5__3eOxv {
    margin-left: 3rem !important;
  }

  .TokenChart_ms-xl-auto__2FbSo {
    margin-left: auto !important;
  }

  .TokenChart_p-xl-0__3YI-z {
    padding: 0 !important;
  }

  .TokenChart_p-xl-1__Xjlu1 {
    padding: 0.25rem !important;
  }

  .TokenChart_p-xl-2__30H1K {
    padding: 0.5rem !important;
  }

  .TokenChart_p-xl-3__1h4KA {
    padding: 1rem !important;
  }

  .TokenChart_p-xl-4__2ReK5 {
    padding: 1.5rem !important;
  }

  .TokenChart_p-xl-5__3aVCV {
    padding: 3rem !important;
  }

  .TokenChart_px-xl-0__3l66S {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .TokenChart_px-xl-1__24NIt {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .TokenChart_px-xl-2__2AVTt {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .TokenChart_px-xl-3__1g_Cg {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .TokenChart_px-xl-4__3EarR {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TokenChart_px-xl-5__11YL9 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .TokenChart_py-xl-0__3WzT5 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .TokenChart_py-xl-1__1g5iH {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .TokenChart_py-xl-2__1BozT {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .TokenChart_py-xl-3__Yvtzt {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .TokenChart_py-xl-4__Qp6UK {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .TokenChart_py-xl-5__3HoaK {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .TokenChart_pt-xl-0__rIvlY {
    padding-top: 0 !important;
  }

  .TokenChart_pt-xl-1__1fneI {
    padding-top: 0.25rem !important;
  }

  .TokenChart_pt-xl-2__3sPSz {
    padding-top: 0.5rem !important;
  }

  .TokenChart_pt-xl-3__2F7i5 {
    padding-top: 1rem !important;
  }

  .TokenChart_pt-xl-4__1mUP6 {
    padding-top: 1.5rem !important;
  }

  .TokenChart_pt-xl-5__14H_j {
    padding-top: 3rem !important;
  }

  .TokenChart_pe-xl-0__1BIPU {
    padding-right: 0 !important;
  }

  .TokenChart_pe-xl-1__3dFnM {
    padding-right: 0.25rem !important;
  }

  .TokenChart_pe-xl-2__Ft-Wa {
    padding-right: 0.5rem !important;
  }

  .TokenChart_pe-xl-3__joHLK {
    padding-right: 1rem !important;
  }

  .TokenChart_pe-xl-4__kCB1f {
    padding-right: 1.5rem !important;
  }

  .TokenChart_pe-xl-5__7P4KL {
    padding-right: 3rem !important;
  }

  .TokenChart_pb-xl-0__1_jK2 {
    padding-bottom: 0 !important;
  }

  .TokenChart_pb-xl-1__1StTv {
    padding-bottom: 0.25rem !important;
  }

  .TokenChart_pb-xl-2__ye6YD {
    padding-bottom: 0.5rem !important;
  }

  .TokenChart_pb-xl-3__1iGbN {
    padding-bottom: 1rem !important;
  }

  .TokenChart_pb-xl-4__25xp3 {
    padding-bottom: 1.5rem !important;
  }

  .TokenChart_pb-xl-5__2HAya {
    padding-bottom: 3rem !important;
  }

  .TokenChart_ps-xl-0__31c9P {
    padding-left: 0 !important;
  }

  .TokenChart_ps-xl-1__2bGzs {
    padding-left: 0.25rem !important;
  }

  .TokenChart_ps-xl-2__2k2JG {
    padding-left: 0.5rem !important;
  }

  .TokenChart_ps-xl-3__1RKPu {
    padding-left: 1rem !important;
  }

  .TokenChart_ps-xl-4__1at_E {
    padding-left: 1.5rem !important;
  }

  .TokenChart_ps-xl-5__QzeUT {
    padding-left: 3rem !important;
  }

  .TokenChart_text-xl-start__PQYBA {
    text-align: left !important;
  }

  .TokenChart_text-xl-end__r6iBp {
    text-align: right !important;
  }

  .TokenChart_text-xl-center__3aYHj {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .TokenChart_float-xxl-start__27-em {
    float: left !important;
  }

  .TokenChart_float-xxl-end__2cz8S {
    float: right !important;
  }

  .TokenChart_float-xxl-none__34l8v {
    float: none !important;
  }

  .TokenChart_d-xxl-inline__2e5g0 {
    display: inline !important;
  }

  .TokenChart_d-xxl-inline-block__3d4bW {
    display: inline-block !important;
  }

  .TokenChart_d-xxl-block__o2wdq {
    display: block !important;
  }

  .TokenChart_d-xxl-grid__2aaXF {
    display: grid !important;
  }

  .TokenChart_d-xxl-table__24KVe {
    display: table !important;
  }

  .TokenChart_d-xxl-table-row__3kuL3 {
    display: table-row !important;
  }

  .TokenChart_d-xxl-table-cell__3wd-M {
    display: table-cell !important;
  }

  .TokenChart_d-xxl-flex__2ozhp {
    display: flex !important;
  }

  .TokenChart_d-xxl-inline-flex__3kAsg {
    display: inline-flex !important;
  }

  .TokenChart_d-xxl-none__1yzzH {
    display: none !important;
  }

  .TokenChart_flex-xxl-fill__3Oo-P {
    flex: 1 1 auto !important;
  }

  .TokenChart_flex-xxl-row__1RKwJ {
    flex-direction: row !important;
  }

  .TokenChart_flex-xxl-column__1x1kH {
    flex-direction: column !important;
  }

  .TokenChart_flex-xxl-row-reverse__1BkLG {
    flex-direction: row-reverse !important;
  }

  .TokenChart_flex-xxl-column-reverse__2KZGZ {
    flex-direction: column-reverse !important;
  }

  .TokenChart_flex-xxl-grow-0__27O3O {
    flex-grow: 0 !important;
  }

  .TokenChart_flex-xxl-grow-1__2uAww {
    flex-grow: 1 !important;
  }

  .TokenChart_flex-xxl-shrink-0__2Hcnh {
    flex-shrink: 0 !important;
  }

  .TokenChart_flex-xxl-shrink-1__2javB {
    flex-shrink: 1 !important;
  }

  .TokenChart_flex-xxl-wrap__8gDjI {
    flex-wrap: wrap !important;
  }

  .TokenChart_flex-xxl-nowrap__1eNYC {
    flex-wrap: nowrap !important;
  }

  .TokenChart_flex-xxl-wrap-reverse__3RqsX {
    flex-wrap: wrap-reverse !important;
  }

  .TokenChart_gap-xxl-0__2nB3v {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .TokenChart_gap-xxl-1__SITfp {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .TokenChart_gap-xxl-2__2aGjx {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .TokenChart_gap-xxl-3__3lkSD {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .TokenChart_gap-xxl-4__3xSl4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .TokenChart_gap-xxl-5__HmFm- {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .TokenChart_justify-content-xxl-start__aAXmC {
    justify-content: flex-start !important;
  }

  .TokenChart_justify-content-xxl-end__2oaRW {
    justify-content: flex-end !important;
  }

  .TokenChart_justify-content-xxl-center__3JDUc {
    justify-content: center !important;
  }

  .TokenChart_justify-content-xxl-between__2Ow1m {
    justify-content: space-between !important;
  }

  .TokenChart_justify-content-xxl-around__3ibAq {
    justify-content: space-around !important;
  }

  .TokenChart_justify-content-xxl-evenly__27vMG {
    justify-content: space-evenly !important;
  }

  .TokenChart_align-items-xxl-start__2BTc9 {
    align-items: flex-start !important;
  }

  .TokenChart_align-items-xxl-end__3QExZ {
    align-items: flex-end !important;
  }

  .TokenChart_align-items-xxl-center__1jjU4 {
    align-items: center !important;
  }

  .TokenChart_align-items-xxl-baseline__1q-tp {
    align-items: baseline !important;
  }

  .TokenChart_align-items-xxl-stretch__1hlQm {
    align-items: stretch !important;
  }

  .TokenChart_align-content-xxl-start__1gbnV {
    align-content: flex-start !important;
  }

  .TokenChart_align-content-xxl-end__YRbq4 {
    align-content: flex-end !important;
  }

  .TokenChart_align-content-xxl-center__3Q2j3 {
    align-content: center !important;
  }

  .TokenChart_align-content-xxl-between__2hnBf {
    align-content: space-between !important;
  }

  .TokenChart_align-content-xxl-around__2Bi0c {
    align-content: space-around !important;
  }

  .TokenChart_align-content-xxl-stretch__X4YSD {
    align-content: stretch !important;
  }

  .TokenChart_align-self-xxl-auto__119En {
    align-self: auto !important;
  }

  .TokenChart_align-self-xxl-start__3wlIV {
    align-self: flex-start !important;
  }

  .TokenChart_align-self-xxl-end__2krQP {
    align-self: flex-end !important;
  }

  .TokenChart_align-self-xxl-center__2CtpX {
    align-self: center !important;
  }

  .TokenChart_align-self-xxl-baseline__3Z-Vn {
    align-self: baseline !important;
  }

  .TokenChart_align-self-xxl-stretch__Hf2YV {
    align-self: stretch !important;
  }

  .TokenChart_order-xxl-first__3kxaF {
    order: -1 !important;
  }

  .TokenChart_order-xxl-0__3Khu0 {
    order: 0 !important;
  }

  .TokenChart_order-xxl-1__K9X46 {
    order: 1 !important;
  }

  .TokenChart_order-xxl-2__31z3C {
    order: 2 !important;
  }

  .TokenChart_order-xxl-3__1akxR {
    order: 3 !important;
  }

  .TokenChart_order-xxl-4__38t-u {
    order: 4 !important;
  }

  .TokenChart_order-xxl-5__2_l9_ {
    order: 5 !important;
  }

  .TokenChart_order-xxl-last__1TOjf {
    order: 6 !important;
  }

  .TokenChart_m-xxl-0__2PAm7 {
    margin: 0 !important;
  }

  .TokenChart_m-xxl-1__1WtyY {
    margin: 0.25rem !important;
  }

  .TokenChart_m-xxl-2__1gSw3 {
    margin: 0.5rem !important;
  }

  .TokenChart_m-xxl-3__2ckTR {
    margin: 1rem !important;
  }

  .TokenChart_m-xxl-4__1am7H {
    margin: 1.5rem !important;
  }

  .TokenChart_m-xxl-5__3yqt3 {
    margin: 3rem !important;
  }

  .TokenChart_m-xxl-auto__2baLl {
    margin: auto !important;
  }

  .TokenChart_mx-xxl-0__37oB0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .TokenChart_mx-xxl-1__x4Zx- {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .TokenChart_mx-xxl-2__1vvNj {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .TokenChart_mx-xxl-3__18oWh {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .TokenChart_mx-xxl-4__3aq_5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .TokenChart_mx-xxl-5__3ejaU {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .TokenChart_mx-xxl-auto__1_Mgo {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .TokenChart_my-xxl-0__3tfsI {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .TokenChart_my-xxl-1__3iF9D {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .TokenChart_my-xxl-2__3bOUF {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .TokenChart_my-xxl-3__2i1hY {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .TokenChart_my-xxl-4__3TM3R {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .TokenChart_my-xxl-5__2cKRe {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .TokenChart_my-xxl-auto__3eVtC {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .TokenChart_mt-xxl-0__yChtx {
    margin-top: 0 !important;
  }

  .TokenChart_mt-xxl-1__3uko0 {
    margin-top: 0.25rem !important;
  }

  .TokenChart_mt-xxl-2__N40P4 {
    margin-top: 0.5rem !important;
  }

  .TokenChart_mt-xxl-3__11PKk {
    margin-top: 1rem !important;
  }

  .TokenChart_mt-xxl-4__32QTd {
    margin-top: 1.5rem !important;
  }

  .TokenChart_mt-xxl-5__RnivJ {
    margin-top: 3rem !important;
  }

  .TokenChart_mt-xxl-auto__26_DM {
    margin-top: auto !important;
  }

  .TokenChart_me-xxl-0__2zq1T {
    margin-right: 0 !important;
  }

  .TokenChart_me-xxl-1__2L4ls {
    margin-right: 0.25rem !important;
  }

  .TokenChart_me-xxl-2__EPrlH {
    margin-right: 0.5rem !important;
  }

  .TokenChart_me-xxl-3__3nWdn {
    margin-right: 1rem !important;
  }

  .TokenChart_me-xxl-4__3x3F3 {
    margin-right: 1.5rem !important;
  }

  .TokenChart_me-xxl-5__lxTnP {
    margin-right: 3rem !important;
  }

  .TokenChart_me-xxl-auto__3hGKp {
    margin-right: auto !important;
  }

  .TokenChart_mb-xxl-0__a3auT {
    margin-bottom: 0 !important;
  }

  .TokenChart_mb-xxl-1__2_0jA {
    margin-bottom: 0.25rem !important;
  }

  .TokenChart_mb-xxl-2__3uXXb {
    margin-bottom: 0.5rem !important;
  }

  .TokenChart_mb-xxl-3__sCl8d {
    margin-bottom: 1rem !important;
  }

  .TokenChart_mb-xxl-4__93FdU {
    margin-bottom: 1.5rem !important;
  }

  .TokenChart_mb-xxl-5__3rnjd {
    margin-bottom: 3rem !important;
  }

  .TokenChart_mb-xxl-auto__2ocJT {
    margin-bottom: auto !important;
  }

  .TokenChart_ms-xxl-0__r5gqU {
    margin-left: 0 !important;
  }

  .TokenChart_ms-xxl-1__NGBdq {
    margin-left: 0.25rem !important;
  }

  .TokenChart_ms-xxl-2__2_jtN {
    margin-left: 0.5rem !important;
  }

  .TokenChart_ms-xxl-3__3RrmD {
    margin-left: 1rem !important;
  }

  .TokenChart_ms-xxl-4__GXhuz {
    margin-left: 1.5rem !important;
  }

  .TokenChart_ms-xxl-5__3rqsD {
    margin-left: 3rem !important;
  }

  .TokenChart_ms-xxl-auto__ulNmz {
    margin-left: auto !important;
  }

  .TokenChart_p-xxl-0__1TjcI {
    padding: 0 !important;
  }

  .TokenChart_p-xxl-1__2Twc1 {
    padding: 0.25rem !important;
  }

  .TokenChart_p-xxl-2__2Ou0_ {
    padding: 0.5rem !important;
  }

  .TokenChart_p-xxl-3__ZJ74e {
    padding: 1rem !important;
  }

  .TokenChart_p-xxl-4__eA_nR {
    padding: 1.5rem !important;
  }

  .TokenChart_p-xxl-5__1TaTA {
    padding: 3rem !important;
  }

  .TokenChart_px-xxl-0__2rM-p {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .TokenChart_px-xxl-1__2hz1A {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .TokenChart_px-xxl-2__3ixfG {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .TokenChart_px-xxl-3__1FHnN {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .TokenChart_px-xxl-4__3Eg7h {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TokenChart_px-xxl-5__1x5_r {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .TokenChart_py-xxl-0__2mZd_ {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .TokenChart_py-xxl-1__1R8ZI {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .TokenChart_py-xxl-2__GOmgY {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .TokenChart_py-xxl-3__feUdv {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .TokenChart_py-xxl-4__2ieFz {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .TokenChart_py-xxl-5__NKPgg {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .TokenChart_pt-xxl-0__38z8F {
    padding-top: 0 !important;
  }

  .TokenChart_pt-xxl-1__16Yga {
    padding-top: 0.25rem !important;
  }

  .TokenChart_pt-xxl-2__2-_am {
    padding-top: 0.5rem !important;
  }

  .TokenChart_pt-xxl-3__Uot3I {
    padding-top: 1rem !important;
  }

  .TokenChart_pt-xxl-4__1Drka {
    padding-top: 1.5rem !important;
  }

  .TokenChart_pt-xxl-5__4KYjt {
    padding-top: 3rem !important;
  }

  .TokenChart_pe-xxl-0__2SBIr {
    padding-right: 0 !important;
  }

  .TokenChart_pe-xxl-1__m_bHq {
    padding-right: 0.25rem !important;
  }

  .TokenChart_pe-xxl-2__9gep1 {
    padding-right: 0.5rem !important;
  }

  .TokenChart_pe-xxl-3__wk-Nd {
    padding-right: 1rem !important;
  }

  .TokenChart_pe-xxl-4__jKwYM {
    padding-right: 1.5rem !important;
  }

  .TokenChart_pe-xxl-5__2n23I {
    padding-right: 3rem !important;
  }

  .TokenChart_pb-xxl-0__3v5vb {
    padding-bottom: 0 !important;
  }

  .TokenChart_pb-xxl-1__SHdjU {
    padding-bottom: 0.25rem !important;
  }

  .TokenChart_pb-xxl-2__1MhPk {
    padding-bottom: 0.5rem !important;
  }

  .TokenChart_pb-xxl-3__1ywVw {
    padding-bottom: 1rem !important;
  }

  .TokenChart_pb-xxl-4__1d1Mf {
    padding-bottom: 1.5rem !important;
  }

  .TokenChart_pb-xxl-5__T7l1K {
    padding-bottom: 3rem !important;
  }

  .TokenChart_ps-xxl-0__2nEAS {
    padding-left: 0 !important;
  }

  .TokenChart_ps-xxl-1__1getq {
    padding-left: 0.25rem !important;
  }

  .TokenChart_ps-xxl-2__1zLWM {
    padding-left: 0.5rem !important;
  }

  .TokenChart_ps-xxl-3__2kzNh {
    padding-left: 1rem !important;
  }

  .TokenChart_ps-xxl-4__1Z3LO {
    padding-left: 1.5rem !important;
  }

  .TokenChart_ps-xxl-5__1DZsP {
    padding-left: 3rem !important;
  }

  .TokenChart_text-xxl-start__kmsOe {
    text-align: left !important;
  }

  .TokenChart_text-xxl-end__2lHrK {
    text-align: right !important;
  }

  .TokenChart_text-xxl-center__1TCqd {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .TokenChart_fs-1__1a47b {
    font-size: 2.5rem !important;
  }

  .TokenChart_fs-2__XG-wf {
    font-size: 2rem !important;
  }

  .TokenChart_fs-3__3w5kV {
    font-size: 1.75rem !important;
  }

  .TokenChart_fs-4__3owKk {
    font-size: 1.5rem !important;
  }
}

@media print {
  .TokenChart_d-print-inline__3JJB_ {
    display: inline !important;
  }

  .TokenChart_d-print-inline-block__3bFC2 {
    display: inline-block !important;
  }

  .TokenChart_d-print-block__3VQZl {
    display: block !important;
  }

  .TokenChart_d-print-grid__3r4Ir {
    display: grid !important;
  }

  .TokenChart_d-print-table__2lyMa {
    display: table !important;
  }

  .TokenChart_d-print-table-row__3sC25 {
    display: table-row !important;
  }

  .TokenChart_d-print-table-cell__LG-ae {
    display: table-cell !important;
  }

  .TokenChart_d-print-flex__3yZi5 {
    display: flex !important;
  }

  .TokenChart_d-print-inline-flex__X_O83 {
    display: inline-flex !important;
  }

  .TokenChart_d-print-none__3hcDK {
    display: none !important;
  }
}

.TokenChart_chart__1LgYj text.highcharts-credits {
  display: none;
}

.TokenChart_chart__1LgYj .mobile-chart-ad {
  max-width: calc(100vw - 60px);
}

@media (min-width: 576px) {
  .TokenChart_walletTokens__H93fw {
    min-width: 350px;
  }
}

.TokenChart_stats__3732U {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #0f1a1c;
  border: 1px solid #0f1a1c;
}

@media (min-width: 576px) {
  .TokenChart_stats__3732U {
    padding-top: 0;
    border: none;
    position: static;
    max-width: 330px;
    width: 100%;
  }
}
