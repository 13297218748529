*,
:after,
:before {
  box-sizing: border-box;
}

@media (min-width: 1200px) {
  .AdvertiseBody {
    /* font-family: 'sans-serif'; */
    width: 1100px;
    display: inline-block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .AdvertiseBody {
    /* font-family: 'sans-serif'; */
    width: 960px;
    display: inline-block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .AdvertiseBody {
    /* font-family: 'sans-serif'; */
    width: 720px;
    display: inline-block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .AdvertiseBody {
    /* font-family: 'sans-serif'; */
    width: 540px;
    display: inline-block;
  }
}

@media only screen and (max-width: 576px) {
  .AdvertiseBody {
    /* font-family: 'sans-serif'; */
    width: 100%;
    display: inline-block;
  }
}

/* header style */
.MuiToggleButtonGroup-root {
  display: block !important;
}

/* Banners page style */

.Card {
  background-color: rgba(0, 0, 0, 0.5) !important;
  padding: 1rem;
  color: white !important;
  text-align: left;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
}

.Card1 {
  background-color: #0f1a1c !important;
  padding: 1rem;
  color: white !important;
  text-align: left;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
}

a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
}

.Mui-selected {
  background-color: #1e1e1e !important;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%),
    0 0 0 0.25rem rgb(71 71 71 / 50%) !important;
}

.MuiCardHeader-root {
  padding: 0px !important;
}

.MuiCardContent-root {
  padding: 0px !important;
}

.MuiTypography-h5 {
  font-size: 1.75rem !important;
  font-weight: 500 !important;
  line-height: 1.334;
  letter-spacing: 0em;
}

hr {
  height: 1px;
  opacity: 1;
  color: #0f1a1c;
  background-color: currentColor;
  border: 0;
  margin: 1rem 0 1rem 0;
}

.fwBold {
  font-weight: 700 !important;
}

.fs5 {
  font-size: 1.25rem !important;
}

.fs2 {
  font-size: 0.875rem !important;
}

.fs1 {
  font-size: 0.75rem !important;
}

.fs6 {
  font-size: 1.5rem !important;
}

.img-fluid {
  min-height: 1px;
}

.rounded {
  border-radius: 0.25rem !important;
}

.border {
  border: 1px solid #0f1a1c !important;
}

.img-fluid,
.img-thumbnail {
  max-width: 100%;
  height: auto;
}

.textMuted {
  color: #adb5bd !important;
}

.textYellow {
  color: #f7b500 !important;
}

.textRed {
  color: #dc3545 !important;
}

.textSuccess {
  color: #28a745 !important;
}

.textBlue {
  color: #a4ff66 !important;
}

p {
  line-height: 1.5;
}

.pt5 {
  padding-top: 3rem !important;
}

.mt5 {
  margin-top: 3rem !important;
}

.mb3 {
  margin-bottom: 1rem;
}

.mt3 {
  margin-top: 1rem;
}

.mb2 {
  margin-bottom: 0.5rem !important;
}

.vettedAlert > .MuiAlert-message {
  background-color: #f8d7da !important;
  text-align: center !important;
  color: #842029;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.5rem !important;
  border-radius: ".25rem!important";
}

.vettedAlert.MuiAlert-root {
  display: block !important;
  background-color: #f8d7da !important;
  padding: 0 !important;
  margin-bottom: 1rem;
}

.AuditsIframe {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background-color: white !important;
  border-radius: ".25rem";
  justify-content: center;
  display: flex;
  height: 1150px;
}

.vettedTable,
.vettedTable tr,
.vettedTable td {
  border-color: #3c3c3c !important;
  vertical-align: top !important;
  width: 100%;
}
table,
th,
td {
  border: 1px solid;
  border-collapse: collapse;
  padding: 0.5rem;
  border-color: #0f1a1c;
}

.linkText:hover {
  cursor: pointer;
  text-decoration: underline;
}

.CInput {
  background-color: white;
  width: 100%;
}
/* .PrivateNotchedOutline-root-13 */
.CInput > .MuiInputBase-input {
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
}

.MuiOutlinedInput-root.CInput.Mui-focused > .MuiOutlinedInput-notchedOutline {
  border: 1px solid #9fdcff !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(62, 184, 255, 0.25);
}

.CSelect {
  width: 100%;
}

.CSelect .MuiInputBase-input {
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: white;
}

.CSelect svg {
  color: white;
}

.CSelect > .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #9fdcff !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(62, 184, 255, 0.25);
}

.CSelect .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

.CSelect option {
  color: black;
}

.transButton {
  color: #f8f9fa !important;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: transparent;
  border: transparent;
  float: right;
}
