/* @font-face {
  font-family: "Super Mario 256";
  src: url(../src/fonts/SuperMario256.ttf);
} */

* {
  font-family: "Roboto Flex" !important;
}

body {
  font-family: "Roboto Flex" !important;
}

body {
  background: #0f1a1c;
  margin: 0;
  font-family: "Roboto Flex" !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: "Roboto Flex" !important;
}

/* dex swap style changes */
.MuiDialogContent-root {
  padding: 0% !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  height: auto !important;
}
.modalItemsScroll {
  overflow-y: scroll;
  height: 290px;
}
.modalItemsScroll::-webkit-scrollbar {
  width: 15px;
}
.modalItemsScroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
  box-shadow: inset 0 0 6px #1d3463;
}
.modalItemsScroll::-webkit-scrollbar-thumb {
  background: #02052c;
  border-radius: 20px;
}
.MuiSvgIcon-root {
  color: #707e9b;
}

.eth_modalItemsScroll {
  overflow-y: scroll;
  height: 290px;
  padding-bottom: 30px;
}
.eth_modalItemsScroll::-webkit-scrollbar {
  width: 8px;
}
.eth_modalItemsScroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
  box-shadow: inset 0 0 6px #d2dfff;
}
.eth_modalItemsScroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.11);
  border-radius: 5px;
}
.pol_modalItemsScroll {
  overflow-y: scroll;
  height: 290px;
}
.pol_modalItemsScroll::-webkit-scrollbar {
  width: 15px;
}
.pol_modalItemsScroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
  box-shadow: inset 0 0 6px #02052c;
}
.pol_modalItemsScroll::-webkit-scrollbar-thumb {
  background: #ed2196;
  border-radius: 20px;
}
.MuiButton-root:hover {
  text-decoration: none;
  /* background-color: #fff !important; */
}

.MuiDialog-paperScrollPaper {
  background: #ed2196;
  width: auto;
  box-shadow: 0px 4px 4px rgba(107, 107, 107, 0.25);
  border-radius: 30px !important;
  /* padding: 4px; */
  z-index: 10000000 !important;
}
.dialoge__content__section {
  background: #13151f !important;
  background-color: #13151f !important;
  padding: 20px !important;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  z-index: 10000000 !important;
}

@media screen and (max-width: 544px) {
  .MuiDialog-paperScrollPaper {
    width: 100%;
  }
}

#token-search-input {
  color: #fff !important;
}
