/* @import url('../src/fonts/SuperMario256.ttf'); */

/* @font-face {
  font-family: "Super Mario 256";
  src: url(../src/fonts/SuperMario256.ttf);
} */

* {
  /* font-family: 'Super Mario 256', sans-serif !important; */
  /* font-family: 'Alegreya Sans', sans-serif !important;  */
  font-family: "Roboto Flex" !important;
}

body {
  /* font-family: 'Super Mario 256', sans-serif !important; */
  /* font-family: 'Alegreya Sans', sans-serif !important; */
  font-family: "Roboto Flex" !important;
  overflow-x: hidden;
}
.info_btn {
  display: none !important;
}
.in_sec input {
  border: 1px solid grey;
  padding: 5px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  margin-bottom: 5px;
}

.in_sec button {
  background: #0aa29a !important;
  color: #ffffff;
  font-weight: 600;
  height: 41px;
  margin-left: 5px;
}

.in_sec .MuiInput-underline:before {
  border-bottom: none !important;
}

.trap {
  color: #f7b500;
  margin-bottom: 20px;
}

.main_class a {
  text-decoration: none !important;
}

.MuiGrid-root.connect_btns {
  text-align: right !important;
  display: block !important;
}

a.ban_link {
  padding: 12px;
  background-color: rgb(164 255 102 / 25%);
  border-radius: 5px;
  font-weight: 700;
  font-size: 17px;
  margin-left: 10px;
}

.twi_sec {
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 5px;
}

.main_class {
  /* background: url(./Images/moon.jpeg) no-repeat !important;
  background-attachment: fixed !important;
  background-position: 50% 50%;
  background-repeat: no-repeat !important;
  background-size: cover !important; */
  background-color: #000 !important;
  position: relative;
  width: 100%;
  z-index: 1;
}

.main_class:after {
  background-image: linear-gradient(to bottom right, #000, #000);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.7;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
}

/* 
.App {
  text-align: center;
  background-color: #e9ecef !important; 
  color: "white";
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media (max-width: 991px) {
  button.conect {
    margin: 20px auto 0;
    display: block;
  }

  video {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .info_btn {
    display: block !important;
    background: #a4ff66 !important;
    color: #000 !important;
    position: absolute !important;
    right: 12px;
    margin-top: 9px !important;
    z-index: 1;
    text-transform: capitalize !important;
    font-size: 16px !important;
    padding: 5px !important;
    font-weight: 700 !important;
  }

  video {
    width: 100% !important;
  }

  .MuiGrid-root.connect_btns {
    text-align: center !important;
    display: inherit !important;
    margin-top: 20px !important;
  }

  a.ban_link {
    padding: 10px;
    border-radius: 5px;
    font-weight: 700;
    font-size: 17px;
    margin-left: 3px;
    display: inline-block;
    margin-bottom: 5px;
  }

  .menu_part {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .starting {
    display: block !important;
    margin: 0 auto !important;
    text-align: center !important;
  }

  .starting select {
    text-align: center !important;
  }

  button.conect {
    margin: 0px auto 0;
  }
}

button.conect {
  background: #a4ff66 !important;
  color: #0f1a1c;
  font-weight: 600;
}

a.ban_link:hover {
  color: #fff;
}

.pictureMove {
  animation: move 24s infinite linear;
  position: absolute;
  z-index: -1;
}

@keyframes move {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
